<template>
  <v-app id="app">
    <router-view />
  </v-app>
</template>

<script>

export default {
  name: 'App',

  data: () => ({
    //
  }),
  mounted() {
    // Call the global function
    this.handleScroll1();
    this.handleScroll2();
  },
  methods: {

  }
};
</script>
<style>
.table-responsive {
  height: 500px;
}

.danger {
  background-color: red;
  color: white;
}

.wrapper1 {
  /* width: 50%; */
  overflow-x: scroll;
  overflow-y: hidden;
}
.wrapper1 {
  height: 20px;
}

.div1 {
  width: 160%;
  height: 20px;
  overflow-x: auto;

}
@media screen and (max-width: 1000px) {
  .wrapper1 {
    width: 100%;
  }
}

@media screen and (min-width: 1000px) and (max-width: 1500px) {
  .wrapper1 {
    width: 100%;
  }

  .div1 {
    width: 211%;
  }
}

@media screen and (min-width: 1500px) {
  .wrapper1 {
    width: 100%;
  }
}


</style>
