<template>
  <!-- partial:../../partials/_sidebar.html -->
  <nav class="sidebar">
    <div class="sidebar-header">
      <a href="https://freezingsolutionsystem.com/" class="sidebar-brand"> <v-img
          :src="require('../../assets/CMS_Logo.png')" contain width="160" height="100" /></a>
      <div class="sidebar-toggler not-active">
        <span></span>
        <span></span>
        <span></span>
      </div>
    </div>
    <div class="sidebar-body">
      <ul class="nav">
        <!-- <li class="nav-item">
          <a
          class="nav-link"
            data-bs-toggle="collapse"
            href="#safety"
            role="button"
            aria-expanded="false"
            aria-controls="safety"
          >
            <i class="link-icon" data-feather="settings"></i>
            <span class="link-title">Module Setup</span>
            <i class="link-arrow" data-feather="chevron-down"></i>
          </a>
          <div class="collapse" id="safety">
            <ul class="nav sub-menu">
              <li class="nav-item">
                <router-link to="/values" class="nav-link">
                  Values
                </router-link>
              </li>
              <li class="nav-item">
                <router-link to="/value-lists" class="nav-link">
                  Value Lists
                </router-link>
              </li>
            </ul>
          </div>
        </li> -->
        <li class="nav-item">
          <router-link to="/profiles" class="nav-link">
            <i class="link-icon fa fa-user-circle"></i>
            <span class="link-title">My Profile</span>
          </router-link>
        </li>
        <!-- <li class="nav-item" v-if="roleName == 'SUPER ADMIN'">
          <router-link to="/companies" class="nav-link">
            <i class="link-icon" data-feather="settings"></i>
            <span class="link-title">Companies</span>
          </router-link>
        </li> -->
        <li class="nav-item">
          <router-link to="/users" class="nav-link">
            <i class="link-icon fa fa-user"></i>
            <span class="link-title">{{
              roleName == 'SUPER ADMIN' || roleName == 'ADMIN' ? "Users" : "Technicians"
            }}</span>
          </router-link>
        </li>
        <li class="nav-item">
          <router-link to="/customers/companies" class="nav-link">
            <i class="link-icon fa fa-users"></i>
            <span class="link-title">Customer Companies</span>
          </router-link>
        </li>
        <li class="nav-item">
          <router-link to="/customers" class="nav-link">
            <i class="link-icon fa fa-users"></i>
            <span class="link-title">Customers</span>
          </router-link>
        </li>
        <li class="nav-item">
          <router-link to="/tickets" class="nav-link">
            <i class="link-icon" data-feather="settings"></i>
            <span class="link-title">Tickets</span>
          </router-link>
        </li>
        <li class="nav-item">
          <router-link to="/products" class="nav-link">
            <i class="link-icon" data-feather="settings"></i>
            <span class="link-title">Products</span>
          </router-link>
        </li>
        <li class="nav-item">
          <router-link to="/values" class="nav-link">
            <i class="link-icon" data-feather="settings"></i>
            <span class="link-title">Value</span>
          </router-link>
        </li>
        <li class="nav-item">
          <router-link to="/value-lists" class="nav-link">
            <i class="link-icon" data-feather="settings"></i>
            <span class="link-title">Value List</span>
          </router-link>
        </li>
        <li class="nav-item">
          <router-link to="/orders" class="nav-link">
            <i class="link-icon" data-feather="settings"></i>
            <span class="link-title">Orders</span>
          </router-link>
        </li>
        <hr style="boder: solid black 2px" />
      </ul>
      <ul style="position: absolute; bottom: 0; list-style: none;">
        <li class="nav-item">
          <v-btn class="primary mx-4" @click="logout" style="padding: 0 58px;">
            <span>Logout</span>
          </v-btn>
        </li>
      </ul>
    </div>
  </nav>
  <!-- partial -->
</template>

<script type="text/javascript">
import { mapActions } from "vuex";

export default {
  data() {
    return {
      roleId: "",
      roleName: "",
    };
  },
  mounted() {
    this.roleId = this.user.roles[0].id;
    this.roleName = this.user.roles[0].name;
  },

  methods: {
    ...mapActions({
      logOut: "auth/logOut",
    }),
    logout() {
      this.logOut();
      location.replace("/");
    },
  },
};
</script>
<style>
.sidebar-brand .v-image__image {
  background-position: center left !important;
}
</style>
