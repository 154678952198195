<template>
    <div class="page-content">
        <div class="row">
            <div class="col-lg-8 col-md-8 col-sm-6 col-xs-12">
                <h5 class="txt-dark">
                    <back-button link="/customers"></back-button>
                    Add Customer
                </h5>
            </div>
            <!-- Breadcrumb -->
            <div class="col-lg-4 col-sm-6 col-md-4 col-xs-12">
                <ol class="breadcrumb breadcrumb-line" style="float: right">
                    <li class="breadcrumb-item">
                        <router-link :to="`/`">Dashboard</router-link>
                    </li>
                    <li class="breadcrumb-item">
                        <router-link :to="`customers`">Customers</router-link>
                    </li>
                    <li class="breadcrumb-item active" aria-current="page">Add</li>
                </ol>
            </div>
        </div>
        <div class="row">
            <div class="col-md-10 grid-margin stretch-card">
                <div class="card">
                    <div class="card-body">
                        <div class="row">
                            <div class="col-md-4">
                                <label class="col-form-label">Company Name</label>
                                <div class="col-sm-12">
                                    <v-text-field v-model="form.company_name" prepend-inner-icon="mdi-office-building"
                                        outlined clearable :error-messages="errors.company_name"></v-text-field>
                                </div>
                            </div>
                            <div class="col-md-4">
                                <label class="col-form-label">Company Address</label>
                                <div class="col-sm-12">
                                    <v-text-field v-model="form.company_address" prepend-inner-icon="mdi-map-marker"
                                        outlined clearable :error-messages="errors.company_address"></v-text-field>
                                </div>
                            </div>
                            <div class="col-md-4">
                                <label class="col-form-label">Company Pincode</label>
                                <div class="col-sm-12">
                                    <v-text-field v-model="form.company_pincode" prepend-inner-icon="mdi-map-marker"
                                        outlined clearable :error-messages="errors.company_pincode"></v-text-field>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-4">
                                <label class="col-form-label">Company Email</label>
                                <div class="col-sm-12">
                                    <v-text-field v-model="form.company_email" prepend-inner-icon="mdi-email" outlined
                                        clearable :error-messages="errors.company_email"></v-text-field>
                                </div>
                            </div>

                            <div class="col-md-4">
                                <label class="col-form-label">Company Phone Number</label>
                                <div class="col-sm-12">
                                    <v-text-field v-model="form.company_phone" prepend-inner-icon="mdi-phone" outlined
                                        clearable :error-messages="errors.company_phone"></v-text-field>
                                </div>
                            </div>
                        </div>
                        <div class="form-group mb-0">
                            <submit-button :isLoading="isLoading" @submit="save" text="Save"
                                style="float: right"></submit-button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script type="text/javascript">
import axios from "axios";

export default {
    name: "CompanyId",
    data() {
        return {
            form: {},
            isLoading: false,
            Commercial: false,
        };
    },
    mounted() {
        this.getData();
    },
    methods: {
        async getData() {
            this.isLoading = true;
            let form = await axios.get(`/customer_companies/${this.$route.params.CompanyId}`);
            this.form = form.data.data;

            this.isLoading = false;
        },
        async save() {
            try {
                this.isLoading = true;
                let CustomerCompany = await axios.post("/customer_companies", this.form);
                this.CustomerCompany = CustomerCompany.data.data;

                this.isLoading = false;
                this.$router.push("/customers/companies");
            } catch (error) {
                this.isLoading = false;
            }

        },
    },
};
</script>
<style scoped></style>
  