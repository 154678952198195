<template>
  <div class="page-content">
    <div class="row">
      <div class="col-lg-8 col-md-8 col-sm-6 col-xs-12">
        <h5 class="txt-dark">
          <back-button link="/tickets"></back-button>
          Upload Ticket
        </h5>
      </div>
      <!-- Breadcrumb -->
      <div class="col-lg-4 col-sm-6 col-md-4 col-xs-12">
        <ol class="breadcrumb breadcrumb-line" style="float: right">
          <li class="breadcrumb-item">
            <router-link :to="`/`">Dashboard</router-link>
          </li>
          <li class="breadcrumb-item">
            <router-link :to="`tickets`">Tickets</router-link>
          </li>
          <li class="breadcrumb-item active" aria-current="page">Add</li>
        </ol>
      </div>
    </div>
    <div class="row">
      <v-card elevation="6">
        <v-btn text>
          <!-- <a :href="`${path}store-vyu/samples/user_upload_v1_0.xlsx`"
            >Download sample file</a
          > -->
        </v-btn>
        <v-card-title>
          <!-- <backbutton></backbutton> -->
          Upload Tickets Data
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col v-if="crude_tickets.length == 0">
              <div v-if="isLoading == true" style="
                  color: green;
                  font-weight: bold;
                  font-size: 16px;
                  margin-top: 10px;
                ">
                Please Wait... File loading.
              </div>
              <input type="file" id="file" name="file" ref="file" accept="application/ms-excel" multiple
                @input="handleFileUpload" />
              <div v-if="isUploading" style="
                  color: green;
                  font-weight: bold;
                  font-size: 16px;
                  margin-top: 10px;
                ">
                Uploading file... Please wait.
              </div>
            </v-col>
            <v-col v-else>
              <v-btn color="success" :loading="isProcessing" :disabled="isProcessing" @click="processFile">
                Process file</v-btn>

              &nbsp;
              <v-btn color="error" @click="truncatePendingDocuments">
                Delete</v-btn>
              <div v-if="isProcess" style="
                  color: #007bff;
                  font-weight: bold;
                  font-size: 16px;
                  margin-top: 10px;
                ">
                Processing file... Please wait.
              </div>
              <div v-if="is_Truncated" style="
                  color: #007bff;
                  font-weight: bold;
                  font-size: 16px;
                  margin-top: 10px;
                ">
                Deleted file... .
              </div>
            </v-col>
          </v-row>
          <v-row>
            <span style="color: red" v-show="isError">{{ error_msg }} </span>
          </v-row>
        </v-card-text>
      </v-card>
    </div>
    <br />
    <br />
    <div class="row">
      <v-card elevation="6">
        <v-simple-table fixed-header>
          <template v-slot:default>
            <thead>
              <tr>
                <th class="text-left">Call Date</th>
                <th class="text-left">Ticket Number</th>
                <th class="text-left">Outlet Name</th>
                <th class="text-left">Department Name</th>
                <th class="text-left">Product</th>
                <th class="text-left">Issue</th>
                <th class="text-left">Call Status</th>
                <th class="text-left">Reporting Person Name</th>
                <th class="text-left">Reporting Person Contact</th>
                <th class="text-left">Current condition</th>
                <th class="text-left">Reporting Person Email</th>
                <th class="text-left">State</th>
                <th class="text-left">City</th>
                <th class="text-left">Description</th>
                <th class="text-left">Sparepart</th>
                <th class="text-left">Assigned Date</th>
                <th class="text-left">Closure Name</th>
                <th class="text-left">Closure Contact</th>
                <th class="text-left">Assign To</th>
                <th class="text-left">Assign To Email</th>
                <th class="text-left">Assign To Number</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(crudproduct, at) in crude_tickets" :key="`crudproduct${at}`">
                <td>{{ crudproduct.call_date }}</td>
                <td>{{ crudproduct.ticket_id }}</td>
                <td>{{ crudproduct.outlet_name }}</td>
                <td>{{ crudproduct.dp_name }}</td>
                <td>{{ crudproduct.cooler }}</td>
                <td>{{ crudproduct.action_remark }}</td>
                <td>{{ crudproduct.call_status }}</td>
                <td>{{ crudproduct.reporting_person_name }}</td>
                <td>{{ crudproduct.reporting_person_contact }}</td>
                <td>{{ crudproduct.current_condition }}</td>
                <td>{{ crudproduct.reporting_person_email }}</td>
                <td>{{ crudproduct.state }}</td>
                <td>{{ crudproduct.town }}</td>
                <td>{{ crudproduct.description }}</td>
                <td>{{ crudproduct.sparepart }}</td>
                <td>{{ crudproduct.assigned_date }}</td>
                <td>{{ crudproduct.closure_name }}</td>
                <td>{{ crudproduct.closure_contact }}</td>
                <td>{{ crudproduct.assign_to }}</td>
                <td>{{ crudproduct.assign_to_email }}</td>
                <td>{{ crudproduct.assign_to_number }}</td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-card>
    </div>
  </div>
</template>

<script>
import axios from "axios";
// import backbutton from "@/components/backbutton.vue";
export default {
  //   components: { backbutton },
  metaInfo: {
    // if no subcomponents specify a metaInfo.title, this title will be used
    title: "Upload User Excel",
  },
  data() {
    return {
      crude_tickets: [],
      isLoading: false,
      is_mail_sent: false,
      isUploading: false,
      is_Uploaded: false,
      isProcessing: false,
      isProcess: false,
      is_Processed: false,
      is_Truncated: false,
      isError: false,
      hardrefresh: false,
      error_msg: "",
      path: "",
    };
  },
  mounted() {
    this.getData();
    this.path = this.mediaUrl;
    console.log("mounted first");
  },
  methods: {
    async getData(page = 1) {
      this.isLoading = true;
      let crude_tickets = await axios.get(`/crude_tickets`);
      this.crude_tickets = crude_tickets.data.data;
      console.log(this.crude_tickets);
      this.count = crude_tickets.data.count;
      this.serialNoStarting = (page - 1) * this.rowsPerPage;
      this.isLoading = false;
    },
    async handleFileUpload() {
      this.isUploading = true;
      this.isUploadingError = false;
      this.error_msg = "";
      this.tickets = this.$refs.file.files[0];
      let formData = new FormData();
      formData.append("tickets", this.tickets);
      this.changeValue = 100;
      localStorage.setItem("isFileUploaded", "true");

      await axios
        .post("upload_ticket", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
          if (response.data.data) this.crude_tickets = response.data.data;
          this.isUploading = false; // Hide loading message
        })
        .catch(() => {
          this.isError = true;
          this.error_msg =
            "Looks like system faced an error while uploading the File. Kindly check the file before uploading ...";
        });
    },
    async processFile() {
      try {
        this.isProcess = true;
        this.isProcessing = true;
        await axios
          .get(`process_ticket`)
          .then(() => {
            this.isProcess = false;
          })
          .catch(() => {
            this.isError = true;
            this.error_msg =
              "Looks like system faced an error while processing the File. Kindly check the file before processing ...";
          });
        await axios.get("truncate_tickets");
        this.crude_products = [];
        this.isProcessing = false;
        this.is_Processed = true;
        alert("Processed Successfully");
        this.getData();
      } catch (e) {
        console.log(e);
      }
    },
    async truncatePendingDocuments() {
      this.is_Truncated = true;
      await axios.get("truncate_tickets");
      alert("Truncated Successfully");
      this.is_Truncated = false;
      this.getData();

      this.crude_products = [];
    },
  },
};
</script>
<style scoped>
.v-data-table>.v-data-table__wrapper>table>thead>tr>th,
td {
  min-width: 130px !important;
}
</style>
