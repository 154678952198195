<template>
  <!-- partial -->
  <div class="page-content">
    <div class="row">
      <div class="col-lg-8 col-md-8 col-sm-6 col-xs-12">
        <h5 class="txt-dark">
          Values List Details
          <save-button @click="save"></save-button>
          <small v-if="isSaving">Saving...</small>
          <!-- <add-button link="/values/create"></add-button> -->
        </h5>
      </div>
      <!-- Breadcrumb -->
      <div class="col-lg-4 col-sm-6 col-md-4 col-xs-12">
        <ol class="breadcrumb breadcrumb-line" style="float: right">
          <li class="breadcrumb-item">
            <router-link :to="`/`">Dashboard</router-link>
          </li>
          <li class="breadcrumb-item"><a href="#">Values List Detail</a></li>
          <li class="breadcrumb-item active" aria-current="page">List</li>
        </ol>
      </div>
    </div>
    <!-- /Breadcrumb -->
    <!-- Add Button -->
    <div class="col-md-12 grid-margin stretch-card">
      <div class="card">
        <div class="card-body">
          <div class="col-lg-8 col-md-8 col-sm-6 col-xs-12">
            <my-select
              style="margin: 9px !important"
              disableFormControl="true"
              :selection.sync="search"
              :suggestions="values"
              @change="getValueLists()"
              placeholder="Select value"
            ></my-select>
          </div>
          <div class="col-lg-8 col-md-8 col-sm-6 col-xs-12">
            <my-select
              style="margin: 9px !important"
              @change="getData"
              disableFormControl="true"
              :selection.sync="searchList"
              :suggestions="valueLists"
              placeholder="Select value list"
            ></my-select>
          </div>
        </div>
      </div>
    </div>
    <!-- /Add Button -->
    <!-- Table -->
    <div class="row">
      <div class="col-md-12 grid-margin stretch-card">
        <div class="card">
          <div class="card-body">
            <div class="table-responsive">
              <table id="dataTableExample" class="table table-hover">
                <thead>
                  <tr>
                    <th>Sr. No.</th>
                    <th>Name</th>
                    <th>Created By</th>
                    <th>Is Active</th>
                    <th class="text-center">Action</th>
                  </tr>
                </thead>
                <tbody>
                  <loading-table
                    :isLoading="isLoading"
                    cols="5"
                  ></loading-table>
                  <tr v-if="valueListDetails.length == 0">
                    <td colspan="5" class="text-center">No data Found</td>
                  </tr>
                  <tr
                    v-for="(valueListDetail, ar) in valueListDetails"
                    :key="`valueListDetail${ar}`"
                  >
                    <td>{{ ar + 1 }}.</td>
                    <td>
                      <input
                        v-uppercase
                        type="text"
                        placeholder="Name"
                        v-model="valueListDetail.name"
                      />
                    </td>
                    <td>
                      <input
                        v-uppercase
                        type="text"
                        placeholder="Code"
                        v-model="valueListDetail.created_by_id"
                      />
                    </td>
                    <td>
                      <my-checkbox
                        :selection.sync="valueListDetail.is_active"
                        placeholder=""
                      ></my-checkbox>
                    </td>
                    <td>
                      <div class="text-center">
                        <button
                          class="btn btn-danger btn-sm"
                          @click="deleteValueList(valueListDetail)"
                          v-if="!valueListDetail.id"
                        >
                          Delete
                        </button>
                        <button
                          class="btn btn-danger btn-sm"
                          @click="delete_data(valueListDetail.id)"
                          v-else
                        >
                          Delete
                        </button>
                      </div>
                    </td>
                  </tr>
                  <tr v-if="searchList">
                    <td colspan="5" align="center" @click="addEmptyValueList()">
                      Add New Row
                    </td>
                  </tr>
                </tbody>
              </table>
              <!-- /Table -->
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- partial -->
</template>

<script type="text/javascript">
import axios from "axios";

export default {
  name: "Values",
  data() {
    return {
      values: [],
      count: 0,
      serialNoStarting: 1,
      isLoading: false,
      // isExcelloading: false,
      isSaving: false,
      search: "",
      searchList: "",
      valueLists: [],
      valueListDetails: [],
      // value_lists_excel: [],
      // excel_fields: {
      //   "Sr No": "sr_no",
      //   "VALUE NAME": "value_name",
      //   DESCRIPTION: "description",
      //   CODE: "code",
      // },
      // ExportValueLists: [],
    };
  },
  mounted() {
    this.getMasters();
  },
  methods: {
    async getMasters() {
      let masters = await axios.get("value_lists/masters");
      masters = masters.data;
      masters.values.forEach((value) => {
        this.values.push({
          id: value.id,
          name: value.category + " | " + value.name,
        });
      });
    },
    async getValueLists() {
      this.valueLists = [];
      let valueLists = await axios.get(`values/${this.search}/value_lists`);
      valueLists = valueLists.data.data;
      valueLists.forEach((valuelist) => {
        this.valueLists.push({
          id: valuelist.id,
          name: valuelist.name + " | " + valuelist.code,
        });
      });
    },
    async getData(page = 1) {
      this.valueListDetails = [];
      this.isLoading = true;
      let valueListDetails = await axios.get(
        `/value_lists/${this.searchList}/value_list_details`
      );
      this.valueListDetails = valueListDetails.data.data;
      this.count = valueListDetails.data.count;
      this.serialNoStarting = (page - 1) * this.rowsPerPage;
      this.isLoading = false;
    },
    addEmptyValueList() {
      this.valueListDetails.push({
        value_id: this.search,
        company_id: this.company.id,
        value_list_id: this.searchList,
        name: "",
        created_by_id: "",
        is_active: 1,
      });
    },
    deleteValueList(valueListDetail) {
      this.valueListDetails = this.valueListDetails.filter(
        (ar) => ar != valueListDetail
      );
    },
    async save() {
      if (this.valueListDetails.length > 0) {
        this.isSaving = true;
        let payload = {
          // datas: this.values,
          datas: this.valueListDetails,
        };
        let response = await axios.post(
          `/value_lists/${this.searchList}/value_list_details_multiple`,
          payload
        );
        this.valueListDetails = response.data.data;
        this.isSaving = false;
      }
    },
    async delete_data(id) {
      let check = confirm("Are you sure you want to delete the data");
      if (check) {
        try {
          this.isLoading = true;
          await axios.post(`delete?id=${id}&table_name=value_lists`);
          this.isLoading = false;
          this.getData();
        } catch (e) {
          this.isLoading = false;
        }
      }
    },
    // async exportToExcel() {
    //   this.isExcelloading = true;
    //   this.title = "Value List Master";
    //   this.value_lists_excel = [];
    //   let ExportValueLists = [];
    //   ExportValueLists = await axios.get(`/values/${this.search}/value_lists`);
    //   this.ExportValueLists = ExportValueLists.data.data;
    //   let sr_no = 1;
    //   this.ExportValueLists.forEach((valuelist) => {
    //     this.value_lists_excel.push({
    //       sr_no: sr_no,
    //       description: valuelist.description,
    //       code: valuelist.code,
    //       value_name: valuelist.value.name,
    //     });
    //     sr_no++;
    //   });
    //   this.isExcelloading = false;
    // },
  },
};
</script>
