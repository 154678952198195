<template>
  <section>
    <button class="btn btn-inverse-primary" v-if="isLoading">Loading...</button>
    <button
      @click="$emit('submit')"
      v-else
      class="btn btn-primary"
      style="width: 130px"
    >
      <i class="icon-rocket"></i><span class="btn-text">{{ text }}</span>
    </button>
  </section>
</template>

<script type="text/javascript">
export default {
  name: "SubmitButtonWidget",
  props: ["isLoading", "text"],
};
</script>
