<template>
  <!-- partial -->
  <div class="page-content">
    <div class="row">
      <div class="col-lg-7 col-md-7 col-sm-6 col-xs-12">
        <h5 class="txt-dark">
          <back-button link="/tickets"></back-button>
          Tickets Spares &nbsp;
          <save-button @click="save"></save-button>
          <small v-if="isSaving">Saving...</small>
        </h5>
      </div>
      <!-- Breadcrumb -->
      <div class="col-lg-5 col-sm-6 col-md-5 col-xs-12">
        <ol class="breadcrumb breadcrumb-line" style="float: right">
          <li class="breadcrumb-item">
            <router-link :to="`/`">Dashboard</router-link>
          </li>
          <li class="breadcrumb-item">Products</li>
          <li class="breadcrumb-item">Spare Parts</li>
          <li class="breadcrumb-item active" aria-current="page">List</li>
        </ol>
      </div>
    </div>
    <!-- /Breadcrumb -->
    <!-- Search & Filtering  -->
    <div class="row">
      <div class="col-md-12 grid-margin stretch-card">
        <div class="card">
          <div class="card-body">
            <div class="col-lg-8 col-md-8 col-sm-6 col-xs-12">
              <input
                type="text"
                class="form-control"
                placeholder="Search..."
                v-model="searchKeyword"
                @keydown.enter="getData"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Search & Filtering  -->
    <!-- Table -->
    <div class="row">
      <div class="col-md-12 grid-margin stretch-card">
        <div class="card">
          <div class="card-body">
            <!-- Export To Excel -->
            <div class="row">
              <div class="col-md-8"></div>
              <div class="col-md-4">
                <div style="float: right">
                  <!-- <a
                      href="#"
                      class="btn btn-primary"
                      v-if="ExportValueLists.length == 0"
                      @click="exportToExcel"
                    > -->
                  <!-- <span v-if="isExcelloading">
                        <div class="d-flex align-items-center">
                          <strong>Generating Excel</strong>
                          &nbsp;
                          <div
                            class="
                              spinner-border
                              text-light
                              spinner-border-sm
                              ms-auto
                            "
                            role="status"
                            aria-hidden="true"
                          ></div>
                        </div>
                      </span> -->
                  <!-- <span v-else>Generate Excel</span>
                    </a> -->
                  <!-- <download-excel
                      :title="title"
                      v-else
                      class="btn btn-success"
                      :data="value_lists_excel"
                      :fields="excel_fields"
                      worksheet="Job Categories"
                      name="Job Category.xls"
                      >Download Excel
                    </download-excel> -->
                </div>
              </div>
            </div>
            <br />
            <!-- End Export to Excel -->
            <div class="table-responsive">
              <table id="dataTableExample" class="table table-hover">
                <thead>
                  <tr>
                    <th>Sr. No.</th>
                    <th>Spare Name</th>
                    <th>Is Active</th>
                    <th class="text-center" v-if="roleName == 'SUPER ADMIN'">
                      Action
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <loading-table
                    :isLoading="isLoading"
                    cols="12"
                  ></loading-table>
                  <tr v-if="productSpares.length == 0">
                    <td colspan="12" class="text-center">No data Found</td>
                  </tr>
                  <tr
                    v-for="(productSpare, ar) in productSpares"
                    :key="`productSpare${ar}`"
                  >
                    <td>{{ ar + 1 }}.</td>
                    <td>
                      <v-autocomplete
                        v-model="productSpare.spare_part_id"
                        :items="products"
                        placeholder="Select Product"
                        prepend-icon="mdi-snowflake"
                        clearable
                      ></v-autocomplete>
                    </td>
                    <td>
                      <div class="form-check form-switch mb-2">
                        <input
                          type="checkbox"
                          v-model="productSpare.is_active"
                          class="form-check-input"
                          id="formSwitch1"
                        />
                      </div>
                    </td>
                    <td v-if="roleName == 'SUPER ADMIN'">
                      <div class="text-center">
                        <v-tooltip bottom v-if="!productSpare.id">
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn
                              v-bind="attrs"
                              v-on="on"
                              small
                              color="primary"
                              icon
                              @click="deleteValueList(productSpare)"
                            >
                              <v-icon>mdi-delete-outline</v-icon>
                            </v-btn>
                          </template>
                          <span>Delete</span>
                        </v-tooltip>
                        <v-tooltip bottom v-else>
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn
                              v-bind="attrs"
                              v-on="on"
                              small
                              color="primary"
                              icon
                              @click="delete_data(productSpare.id)"
                            >
                              <v-icon>mdi-delete-outline</v-icon>
                            </v-btn>
                          </template>
                          <span>Delete</span>
                        </v-tooltip>
                      </div>
                    </td>
                  </tr>
                  <tr v-if="ticket_id">
                    <td
                      colspan="12"
                      align="center"
                      style="
                        background-color: #6571ff;
                        color: white;
                        border-radius: 10px;
                        box-shadow: none;
                      "
                      @click="addEmptyValueList()"
                    >
                      Add New Row
                    </td>
                  </tr>
                </tbody>
              </table>
              <!-- /Table -->
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- partial -->
</template>

<script type="text/javascript">
import axios from "axios";

export default {
  name: "ProductSpareCreate",
  data() {
    return {
      ticket_id: "",
      count: 0,
      serialNoStarting: 1,
      isLoading: false,
      isSaving: false,
      page: 1,
      searchKeyword: "",
      productSpares: [],
      products: [],
      value_lists_excel: [],
    };
  },
  mounted() {
    this.roleName = this.user.roles[0].name;
    this.getData();
    this.getMasters();

    console.log(this.products);
  },
  methods: {
    async getMasters() {
      let masters = await axios.get("ticket/ticket_spares");
      masters = masters.data;
      masters.data.forEach((product) => {
        this.products.push({
          id: product.id,
          text: product.name,
          value: product.id,
        });
      });
    },
    // Get Data
    async getData() {
      this.isLoading = true;
      this.searchKeyword = this.searchKeyword ? this.searchKeyword : "";
      let query =
        "page=" +
        this.page +
        "&rowsPerPage=" +
        this.rowsPerPage +
        "&search_keyword=" +
        this.searchKeyword;
      try {
        this.ticket_id = this.$route.params.ticketId;
        let productSpares = await axios.get(
          `/ticket/${this.ticket_id}/ticket_spares?${query}`
        );
        this.productSpares = productSpares.data.data;
        this.count = productSpares.data.count;
        console.log(this.productSpares);
      } catch (error) {
        console.error(error);
      }
      this.isLoading = false;
      // this.exportToExcel();
    },
    // Adding an Emplty Row
    addEmptyValueList() {
      this.productSpares.push({
        ticket_id: this.ticket_id,
        company_id: this.company.id,
        name: "",
        created_by_id: this.user.id,
        is_active: 1,
      });
    },
    // Delete an Empty Row
    deleteValueList(productSpare) {
      this.productSpares = this.productSpares.filter(
        (ar) => ar != productSpare
      );
    },
    // Save
    async save() {
      if (this.productSpares.length > 0) {
        this.isSaving = true;
        let payload = {
          datas: this.productSpares,
        };
        let response = await axios.post(
          `/ticket/${this.ticket_id}/ticket_spares_multiple`,
          payload
        );
        this.productSpares = response.data.data;
        this.isSaving = false;
      }
    },

    // Delete from DB
    async delete_data(id) {
      let check = confirm("Are you sure you want to delete the data");
      if (check) {
        try {
          this.isLoading = true;
          await axios.delete(`ticket/${this.ticket_id}/ticket_spares/${id}`);
          this.isLoading = false;
          this.getData();
        } catch (e) {
          this.isLoading = false;
        }
      }
    },
    // Clear Data for latest Filter Excel Generation
    clearData() {
      this.ExportValueLists = [];
      console.log("Cleared Data");
    },
  },
};
</script>
