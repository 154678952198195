<template>
  <!-- partial -->
  <div class="page-content">
    <div class="row">
      <div class="col-lg-7 col-md-7 col-sm-6 col-xs-12">
        <h5 class="txt-dark">
          Customers &nbsp;
          <add-button link="/customers/create"></add-button>
        </h5>
      </div>
      <!-- Breadcrumb -->
      <div class="col-lg-5 col-sm-6 col-md-5 col-xs-12">
        <ol class="breadcrumb breadcrumb-line" style="float: right">
          <li class="breadcrumb-item">
            <router-link :to="`/`">Dashboard</router-link>
          </li>
          <li class="breadcrumb-item">Customers</li>

          <li class="breadcrumb-item active" aria-current="page">List</li>
        </ol>
      </div>
    </div>
    <!-- /Breadcrumb -->
    <!-- Search & Filtering  -->
    <div class="row">
      <div class="col-md-12 grid-margin stretch-card">
        <div class="card">
          <div class="card-body row">
            <div class="col-lg-8 col-md-8 col-sm-6 col-xs-12">
              <input type="text" class="form-control" placeholder="Search..." v-model="searchKeyword"
                @keydown.enter="getData" />
            </div>
            <v-col sm="2">
              <v-autocomplete ref="customer_type" v-model="form.customer_type" :items="customer_types"
                label="Customer Type" prepend-icon="mdi-account" :error-messages="errors.customer_type" clearable
                @input="getData"></v-autocomplete>
            </v-col>
            <v-col sm="2">
              <v-autocomplete ref="customer_company" v-model="form.customer_company" :items="CustomerCompanies"
                label="Customer Company" prepend-icon="mdi-account" :error-messages="errors.customer_company" clearable
                @input="getData"></v-autocomplete>
            </v-col>
          </div>
        </div>
      </div>
    </div>
    <!-- Search & Filtering  -->
    <!-- Table -->
    <div class="row">
      <div class="col-md-12 grid-margin stretch-card">
        <div class="card">
          <div class="card-body">
            <div>
              <div class="wrapper1" ref="wrapper1" @scroll="handleScroll1">
                <div class="div1"></div>
              </div>
              <div class="wrapper2">
                <div class="div2">
                  <div class="table-responsive">
                    <v-simple-table>
                      <template v-slot:default>
                        <thead>
                          <tr>
                            <th>Sr No</th>
                            <th>Name</th>
                            <th>Email</th>
                            <th>Phone</th>
                            <th>Customer Type</th>
                            <th>Products</th>
                            <th>Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          <!-- Table Loader -->
                          <loading-table :isLoading="isLoading" cols="8
                        "></loading-table>
                          <!-- If No Data Received-->
                          <tr v-if="customers.length == 0">
                            <td colspan="8" class="text-center" style="color: grey">
                              No Data Found
                            </td>
                          </tr>
                          <!-- If data Received -->
                          <tr v-for="(customer, at) in customers" :key="`customer${at}`">
                            <td>{{ at + 1 }}</td>
                            <td>{{ customer.name || "" }}</td>
                            <td>
                              {{ customer.email || "" }}
                            </td>
                            <td>
                              {{ customer.phone || "" }}
                            </td>
                            <td>{{ customer.customer_type || "" }}</td>
                            <!-- <td>
                        <div  &&
                          customer.customer_product_lists.length > 0
                          ">
                        </div>
                      </td> -->
                            <td>
                              <template v-if="customer.customer_product_lists.length > 0
              ">
                                <ul>
                                  <li v-for="product in customer.customer_product_lists" :key="product.id">
                                    {{
              product.product
                ? product.product.name
                : ""
            }}
                                  </li>
                                </ul>
                              </template>
                              <template v-else> No Products </template>
                            </td>
                            <td>
                              <v-btn class="mx-2" color="primary" fab x-small :to="`/customers/${customer.id}`">
                                <v-icon dark> mdi-pencil </v-icon>
                              </v-btn>
                              <v-btn class="mx-2" color="sucess" fab x-small
                                :to="`/customers/${customer.id}/customer-products`">
                                <v-icon dark>mdi-plus</v-icon>
                              </v-btn>
                            </td>
                          </tr>
                        </tbody>
                      </template>
                    </v-simple-table>
                    <!-- /Table -->
                    <div class="col-md-12">
                      <div style="float: right">
                        <v-pagination pagination v-model="page" :count="count" :length="paginationLength"
                          prev-icon="mdi-menu-left" next-icon="mdi-menu-right"></v-pagination>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- <pagination :count="count" @submit="getData"></pagination> -->
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- partial -->
</template>

<script type="text/javascript">
import axios from "axios";

export default {
  name: "port",
  watch: {
    page(newVal) {
      this.getData(newVal);
    },
  },
  data() {
    return {
      form: {},
      page: 1,
      paginationLength: 0, // Add paginationLength
      serialNoStarting: 1,
      customers: [],
      count: 0,
      // serialNoStarting: 1,
      isLoading: false,
      isExcelloading: false,
      searchKeyword: "",

      ports_excel: [],
      CustomerCompanies: [],
      excel_fields: {
        "Sr No": "sr_no",
        "Port Name": "port_name",
        Country: "country",
        Latitude: "latitude",
        Longitude: "longitude",
        Timezone: "timezone",
      },
      Exportports: [],
      customer_types: [
        { text: "Private", value: "Private" },
        { text: "Commercial", value: "Commercial" },
      ],
    };
  },
  mounted() {
    this.getMasters();
    this.getData();
  },
  methods: {
    // async getData() {
    async getData() {
      this.isLoading = true;
      this.searchKeyword = this.searchKeyword ? this.searchKeyword : "";
      this.form.customer_type = this.form.customer_type
        ? this.form.customer_type
        : "";
      this.form.customer_company = this.form.customer_company
        ? this.form.customer_company
        : "";
      let query =
        "page=" +
        this.page +
        "&rowsPerPage=" +
        this.rowsPerPage +
        "&customer_type=" +
        this.form.customer_type +
        "&customer_company=" +
        this.form.customer_company +
        "&search_keyword=" +
        this.searchKeyword;
      let customers = await axios.get(`/customers?${query}`);
      this.customers = customers.data.data;
      this.count = customers.data.count;
      this.serialNoStarting = (this.page - 1) * this.rowsPerPage;
      this.paginationLength = Math.ceil(this.count / this.rowsPerPage);
      this.isLoading = false;
    },
    async getMasters() {
      let masters = await axios.get("tickets/masters?values=CustomerCompany");
      masters = masters.data;
      console.log(masters);
      //roles
      masters.CustomerCompany.forEach((customer_companies) => {
        // const roleWithId3 = user.roles.find((role) => role.id === 4);
        this.CustomerCompanies.push({
          id: customer_companies.id,
          text: customer_companies.company_name,
          value: customer_companies.id,
        });
      });
    },
  },
};
</script>
