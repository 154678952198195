<template>
  <span>
    <v-select 
      :options="options" 
      label="label" 
      v-model="value"
      :reduce="label => label.code"
      @input="$emit('update:selection', value)"  
      @blur="blurEvent"
    >
      <template #search="{attributes, events}">
        <input
          class="vs__search"
          :required="!value"
          v-bind="attributes"
          v-on="events"
        />
      </template>
    </v-select>

    <!-- <select 
      :class="disableFormControl ? '' : 'form-control'" 
      v-model="value" 
      @change="$emit('update:selection', value)"  
      @blur="blurEvent"
    >
      <option value="" disabled>{{ placeholder }}</option>
      <option 
        v-for="(match, g) in matches"
        :key="`match${g}`"
        :value="match.id">{{ match.name }}</option>
    </select> -->
    <script type="application/javascript" defer src="/custom.js"></script>
  </span>
</template>

<script type="text/javascript">
export default {
  name: 'MySelectWidget',
  data () {
    return {
      value: '',
      matches: [],
    }
  },
  computed: {
    options() {
      var options = [];
      options.push({
        label: this.placeholder,
        code: "",
      })
      this.matches.forEach(
        match => {
        options.push({
          label: match.name,
          code: match.id,
        })
      })
      return options;
    }
  },
  props: ['selection', 'suggestions', 'placeholder', 'disableFormControl'],
  watch: {
    'selection': 'getValue',
    'suggestions': 'getMatches',
    'value': 'changeEvent'
  },
  mounted() {
    this.getValue()
    this.getMatches()
  },
  methods: {
    getValue() {
      this.value = this.isInt(this.selection) ? parseInt(this.selection) : this.selection
    },
    getMatches() {
      this.matches = this.suggestions
    },
    changeEvent() {
      this.$emit('change')
    },
    blurEvent() {
      this.$emit('blur', this.value)
    },
    isInt(value) {
      var x;
      if (isNaN(value)) {
        return false;
      }
      x = parseFloat(value);
      return (x | 0) === x;
    }
  }
}
</script>

<style>
select {
  color: grey !important; 
}
</style>