<template>
  <div class="page-content">
    <div class="row">
      <div class="col-lg-8 col-md-8 col-sm-6 col-xs-12">
        <h5 class="txt-dark">
          <back-button link="/products"></back-button>
          Upload Product
        </h5>
      </div>
      <!-- Breadcrumb -->
      <div class="col-lg-4 col-sm-6 col-md-4 col-xs-12">
        <ol class="breadcrumb breadcrumb-line" style="float: right">
          <li class="breadcrumb-item">
            <router-link :to="`/`">Dashboard</router-link>
          </li>
          <li class="breadcrumb-item">
            <router-link :to="`products`">Products</router-link>
          </li>
          <li class="breadcrumb-item active" aria-current="page">Add</li>
        </ol>
      </div>
    </div>
    <div class="row">
      <v-card elevation="6">
        <v-btn text>
          <!-- <a :href="`${path}store-vyu/samples/user_upload_v1_0.xlsx`"
            >Download sample file</a
          > -->
        </v-btn>
        <v-card-title>
          <!-- <backbutton></backbutton> -->
          Upload Products Data
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col v-if="crude_products.length == 0">
              <input type="file" id="file" name="file" ref="file" accept="application/ms-excel" multiple
                @input="handleFileUpload" />
            </v-col>
            <v-col v-else>
              <v-btn color="success" :loading="isProcessing" :disabled="isProcessing" @click="processFile">
                Upload file</v-btn>

              &nbsp;
              <v-btn color="error" @click="truncatePendingDocuments">
                Delete</v-btn>
            </v-col>
          </v-row>
          <v-row>
            <span style="color: red" v-show="isError">{{ error_msg }} </span>
          </v-row>
        </v-card-text>
      </v-card>
    </div>
    <br />
    <br />
    <div class="row">
      <v-card elevation="6">
        <v-simple-table fixed-header>
          <template v-slot:default>
            <thead>
              <tr>
                <th class="text-left">Name</th>
                <th class="text-left">Description</th>
                <th class="text-left">Serial No</th>

              </tr>
            </thead>
            <tbody>
              <tr v-for="(crudproduct, at) in crude_products" :key="`crudproduct${at}`">
                <td>{{ crudproduct.name }}</td>
                <td>{{ crudproduct.description }}</td>
                <td>{{ crudproduct.serial_no }}</td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-card>
    </div>

  </div>
</template>

<script>
import axios from "axios";
// import backbutton from "@/components/backbutton.vue";
export default {
  //   components: { backbutton },
  metaInfo: {
    // if no subcomponents specify a metaInfo.title, this title will be used
    title: "Upload User Excel",
  },
  data() {
    return {
      crude_products: [],
      isLoading: false,
      is_mail_sent: false,
      isUploading: false,
      is_Uploaded: false,
      isProcessing: false,
      is_Processed: false,
      is_Truncated: false,
      isError: false,
      error_msg: "",
      path: "",
    };
  },
  mounted() {
    this.getData();
    this.path = this.mediaUrl;
    console.log(this.path);
  },
  methods: {
    async getData(page = 1) {
      this.isLoading = true;
      let crude_products = await axios.get(`/crude_products`);
      this.crude_products = crude_products.data.data;
      console.log(this.crude_products);
      this.count = crude_products.data.count;
      this.serialNoStarting = (page - 1) * this.rowsPerPage;
      this.isLoading = false;
    },
    async handleFileUpload() {
      this.isUploading = true;
      this.isUploadingError = false;
      this.error_msg = "";
      this.products = this.$refs.file.files[0];
      let formData = new FormData();
      formData.append("products", this.products);
      this.changeValue = 100;
      await axios
        .post("upload_product", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
          if (response.data.data) this.crude_products = response.data.data;
          this.is_Uploaded = true;
        })
        .catch(() => {
          this.isError = true;
          this.error_msg =
            "Looks like system faced an error while uploading the File. Kindly check the file before uploading ...";
        });
    },
    async processFile() {
      try {
        this.isProcessing = true;
        await axios.get(`process_product`).catch(() => {
          this.isError = true;
          this.error_msg =
            "Looks like system faced an error while processing the File. Kindly check the file before processing ...";
        });
        await axios.get("truncate_products");
        this.crude_products = [];
        this.isProcessing = false;
        this.is_Processed = true;
        // alert("Processed Successfully");
      } catch (e) {
        console.log(e);
      }
    },
    async truncatePendingDocuments() {
      await axios.get("truncate_products");
      this.is_Truncated = true;

      // alert("Truncated Successfully");
      this.crude_products = [];
    },
  },
};
</script>
<style scoped>
.v-data-table>.v-data-table__wrapper>table>thead>tr>th,
td {
  min-width: 130px !important;
}
</style>
