<template>
  <div class="page-content">
    <div class="row">
      <div class="col-lg-8 col-md-8 col-sm-6 col-xs-12">
        <h5 class="txt-dark">
          <back-button link="/tickets"></back-button>
          Add Ticket
        </h5>
      </div>
      <div class="col-lg-4 col-sm-6 col-md-4 col-xs-12">
        <ol class="breadcrumb breadcrumb-line" style="float: right">
          <li class="breadcrumb-item">
            <router-link :to="`/`">Dashboard</router-link>
          </li>
          <li class="breadcrumb-item">
            <router-link :to="`tickets`">Tickets</router-link>
          </li>
          <li class="breadcrumb-item active" aria-current="page">Add</li>
        </ol>
      </div>
    </div>
    <div class="row">
      <div class="col-md-10 grid-margin stretch-card">
        <div class="card">
          <div class="card-body">
            <div class="row">
              <div class="col-sm-6">
                <label class="col-form-label">Title</label>
                <v-text-field v-model="form.title" prepend-inner-icon="mdi-ticket-account" outlined clearable
                  :error-messages="errors.title"></v-text-field>
              </div>
              <div class="col-sm-6">
                <label class="col-form-label">Ticket number</label>
                <v-text-field v-model="form.ticket_id" prepend-inner-icon="mdi-ticket-account" outlined clearable
                  :error-messages="errors.ticket_id"></v-text-field>
              </div>
            </div>

            <div class="row">
              <label class="col-form-label">Description</label>
              <div class="col-sm-12">
                <v-textarea placeholder="Enter your description" prepend-inner-icon="mdi-ticket-percent"
                  v-model="form.description" outlined :error-messages="errors.description"></v-textarea>
              </div>
            </div>

            <div class="row">
              <div class="col-sm-6">
                <label class="col-form-label"> OutLet Name</label>
                <v-text-field v-model="form.outlet_name" prepend-inner-icon="mdi-ticket-account" outlined
                  clearable></v-text-field>
              </div>
              <div class="col-sm-6">
                <label class="col-form-label"> Department name</label>
                <v-text-field v-model="form.dp_name" prepend-inner-icon="mdi-ticket-account" outlined
                  clearable></v-text-field>
              </div>
            </div>
            <div class="row">
              <label class="col-form-label"> Cooler </label>
              <div class="col-sm-12">
                <v-text-field v-model="form.cooler" prepend-inner-icon="mdi-ticket-account" outlined
                  clearable></v-text-field>
              </div>
            </div>
            <div class="row">
              <label class="col-form-label"> Closure Details</label>
              <div class="col-sm-6">
                <v-text-field v-model="form.closure_name" prepend-inner-icon="mdi-ticket-account" outlined
                  clearable></v-text-field>
              </div>
              <div class="col-sm-6">
                <v-text-field v-model="form.closure_contact" prepend-inner-icon="mdi-ticket-account" outlined
                  clearable></v-text-field>
              </div>
            </div>

            <div class="row">
              <label class="col-form-label"> Action Remark </label>
              <div class="col-sm-12">
                <v-text-field v-model="form.action_remark" prepend-inner-icon="mdi-ticket-account" outlined
                  clearable></v-text-field>
              </div>
            </div>

            <div class="row">
              <label class="col-form-label"> Reporting Person Details </label>
              <div class="col-sm-4">
                <label class="col-form-label"> Name </label>
                <v-text-field v-model="form.reporting_person_name" prepend-inner-icon="mdi-ticket-account" outlined
                  clearable></v-text-field>
              </div>
              <div class="col-sm-4">
                <label class="col-form-label">Phone </label>
                <v-text-field v-model="form.reporting_person_contact" prepend-inner-icon="mdi-ticket-account" outlined
                  clearable></v-text-field>
              </div>
            </div>
            <div class="row">
              <div class="col-sm-12">
                <label class="col-form-label"> Current Condition </label>
                <v-text-field v-model="form.current_condition" prepend-inner-icon="mdi-ticket-account" outlined
                  clearable></v-text-field>
              </div>
            </div>
            <div class="row">
              <div class="col-sm-6">
                <label class="col-form-label"> Email ID </label>
                <v-text-field v-model="form.emailId" prepend-inner-icon="mdi-ticket-account" outlined
                  clearable></v-text-field>
              </div>
              <div class="col-sm-3">
                <label class="col-form-label"> State </label>
                <v-autocomplete ref="state" v-model="form.state" :items="States" label="State"
                  append-icon="mdi-ticket-account" clearable :error-messages="errors.state"></v-autocomplete>
              </div>
              <div class="col-sm-3">
                <label class="col-form-label"> City </label>
                <v-autocomplete ref="state" v-model="form.town" :items="Cities" label="City"
                  append-icon="mdi-ticket-account" clearable :error-messages="errors.city"></v-autocomplete>
              </div>
            </div>
            <div class="row">
              <label class="col-form-label"> Call Status </label>
              <div class="col-sm-4">
                <v-autocomplete v-model="form.call_status" :items="call_status" label="Call Status"
                  prepend-icon="mdi-star-circle-outline"></v-autocomplete>
              </div>
            </div>
            <div class="row">
              <div class="col-sm-4">
                <v-autocomplete ref="users" v-model="form.customer_type" :items="customer_types"
                  @input="getMasters(form.customer_type)" label=" Select Customer" prepend-icon="mdi-account-box"
                  clearable :error-messages="errors.customer_type"></v-autocomplete>
              </div>
              <div class="col-sm-4">
                <v-autocomplete ref="users" v-model="form.customer_id" @input="getProductLists(form.customer_id)"
                  :items="customers" label=" Select Customer" prepend-icon="mdi-account-box" clearable
                  :error-messages="errors.customer_id"></v-autocomplete>
              </div>
              <div class="col-sm-4">
                <v-autocomplete ref="issue" v-model="form.product_id" :items="customerproductLists" @input="
              getProductissueLists(form.customer_id, form.product_id)
              " label="Select Product" chips density="comfortable" prepend-icon="mdi-crosshairs-gps" clearable
                  :error="asssignProduct" :error-messages="asssignProduct ? ['No Product assign to a customer'] : []
              "></v-autocomplete>
              </div>
              <div class="col-sm-4">
                <v-autocomplete ref="productissueLists" v-model="form.ticket_issue_lists" :items="productIssueLists"
                  chips label="Issue Lists" prepend-icon="mdi-shape" multiple clearable></v-autocomplete>
              </div>
              <div class="col-sm-4">
                <v-autocomplete ref="status" v-model="form.status_type" :items="status_types" label="Status"
                  prepend-icon="mdi-chart-donut" :error-messages="errors.status_type"></v-autocomplete>
              </div>
              <div class="col-sm-4">
                <v-autocomplete ref="priority" v-model="form.priority_type_id" :items="priorites" label="Priority"
                  prepend-icon="mdi-star-circle-outline" :error-messages="errors.priority_type_id"></v-autocomplete>
              </div>
            </div>
            <div class="col-sm-12 mt-5 mb-5">
              <label for="file_video">Select Video</label><br />
              <input class="mt-4" type="file" id="file_video" name="file_video" ref="file_video" @change="VideoPath"
                accept="video/*" />
              <br />
              <v-btn v-if="form.video_path != null" x-small class="reset_image my-1" color="red" dark @click="
              ($refs.file_video.value = null), (form.video_path = null)
              ">Remove</v-btn>
            </div>
            <v-card-text>
              <v-divider class="mt-5 mb-5"></v-divider>
              <div class="d-flex justify-end">
                <v-progress-circular v-if="isLoading" indeterminate color="primary"></v-progress-circular>
                <v-btn size="large" v-else color="primary" x-large dark @click="save">Save Ticket</v-btn>
              </div>
            </v-card-text>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script type="text/javascript">
import axios from "axios";

export default {
  name: "UserCreate",
  data() {
    return {
      form: {
        role_id: "",
        created_by_id: "",
        customer_type: "",
        customer_id: "",
        product_list_id: "",
      },
      priorites: [
        { text: "high", value: 1 },
        { text: "medium", value: 2 },
        { text: "low", value: 3 },
      ],
      products: [],
      customerproductLists: [],
      asssignProduct: false,
      productIssueLists: [],
      roles: [],
      customers: [],
      customertypeLists: [],
      status_types: [
        { text: "Ongoing", value: "Ongoing" },
        { text: "Pending", value: "Pending" },
        { text: "Completed", value: "Completed" },
      ],
      customer_types: [
        { text: "Private", value: "Private" },
        { text: "Commercial", value: "Commercial" },
      ],
      call_status: [
        { text: "OPEN", value: "OPEN" },
        { text: "HOLD", value: "HOLD" },
        { text: "CLOSE", value: "CLOSE" },
      ],

      isLoading: false,
      required_field_title: false,
      image1_url: "",
      image2_url: "",
      image3_url: "",
      image4_url: "",
      image5_url: "",
      image6_url: "",
      image7_url: "",
      image8_url: "",
      image9_url: "",
      States: [],
      Cities: [],
    };
  },
  mounted() {
    this.getMasters(this.form.customer_type);
    this.path = this.mediaUrl;
    this.form.created_by_id = this.user.id;
  },
  methods: {
    async getProductLists(selectedValueId) {
      this.isLoading = true;
      console.log(selectedValueId);
      let customerproductLists = await axios.get(
        `/customers/${selectedValueId}/customer_products`
      );

      customerproductLists = customerproductLists.data.data;
      if (customerproductLists.length === 0) {
        console.log(customerproductLists.length);
        this.asssignProduct = true;
        this.isLoading = false;
      } else {
        this.asssignProduct = false;
      }
      this.customerproductLists = [];
      customerproductLists.forEach((product_list) => {
        this.customerproductLists.push({
          id: product_list.id,
          text: product_list.product.name,
          value: product_list.product.id,
        });
        this.isLoading = false;
      });
    },
    async getProductissueLists(selectedValueId, selectedProductId) {
      console.log("Selected Product ID:", selectedProductId);
      console.log("Selected Value ID:", selectedValueId);

      this.isLoading = true;
      try {
        let productIssueLists = await axios.get(
          `/products/${selectedProductId}/product_issues`
        );
        productIssueLists = productIssueLists.data.data;
        this.productIssueLists = [];
        productIssueLists.forEach((product_issue_list) => {
          this.productIssueLists.push({
            id: product_issue_list.id,
            text: product_issue_list.title,
            value: product_issue_list.id,
          });
        });
        // Set the product_id from the selected product
      } catch (error) {
        console.error("Error fetching product issues:", error);
      } finally {
        this.isLoading = false;
      }
    },

    async getMasters(CustomerValueid) {
      console.log(CustomerValueid);
      let masters;
      if (CustomerValueid) masters = await axios.get("tickets/masters?values=customers");
      else masters = await axios.get("tickets/masters?values=limited");
      masters = masters.data;
      
      this.customers = [];
      // if(this.form.customer_types == CustomerValueid)
      masters.customers.forEach((customer) => {
        if (customer.customer_type === CustomerValueid) {
          this.customers.push({
            id: customer.id,
            text: customer.name,
            value: customer.id,
          });
        }
      });
      masters.States.forEach((state) => {
        this.States.push({
          id: state.id,
          text: state.title,
          value: state.title,
        });
      });
      masters.City.forEach((city) => {
        this.Cities.push({
          id: city.id,
          text: city.title,
          value: city.title,
        });
      });
    },

    async save() {
      try {
        let updatedTicketIssues = [];
        console.log(this.form.ticket_issue_lists);
        if (this.form.title != null || this.form.status_type != null) {
          this.form.ticket_issue_lists.forEach((id) => {
            updatedTicketIssues.push({
              product_issue_id: id,
              ticket_id: this.form.id,
            });
          });
          this.form.ticket_issues = updatedTicketIssues;
          console.log(updatedTicketIssues);
          //  this.form.company = this.company.id;
          this.isLoading = true;
          this.ticket = [];
          let ticket = await axios.post("/tickets", this.form);
          this.ticket = ticket.data.data;
          await this.handleFileUpload();
          this.isLoading = false;
          this.$router.push("/tickets");
        } else {
          let ticket = await axios.post("/tickets", this.form);
          this.ticket = ticket.data.data;
          this.isLoading = false;
        }
      } catch (e) {
        this.isLoading = false;
      }
    },
    onImage1Change(e) {
      const file = e.target.files[0];
      this.image1_url = URL.createObjectURL(file);
      console.log(1);
      console.log(this.image1_url);
    },
    onImage2Change(e) {
      const file2 = e.target.files[0];
      this.image2_url = URL.createObjectURL(file2);
    },
    onImage3Change(e) {
      const file3 = e.target.files[0];
      this.image3_url = URL.createObjectURL(file3);
    },
    onImage4Change(e) {
      const file4 = e.target.files[0];
      this.image4_url = URL.createObjectURL(file4);
    },
    onImage5Change(e) {
      const file5 = e.target.files[0];
      this.image5_url = URL.createObjectURL(file5);
    },
    onImage6Change(e) {
      const file6 = e.target.files[0];
      this.image6_url = URL.createObjectURL(file6);
    },
    onImage7Change(e) {
      const file7 = e.target.files[0];
      this.image7_url = URL.createObjectURL(file7);
    },
    onImage8Change(e) {
      const file8 = e.target.files[0];
      this.image8_url = URL.createObjectURL(file8);
    },
    onChallanImageChange(e) {
      const file9 = e.target.files[0];
      this.image9_url = URL.createObjectURL(file9);
    },
    VideoPath(e) {
      const file = e.target.files[0];
      this.video_path = URL.createObjectURL(file);
    },
    async handleFileUpload() {
      this.isLoading = true;
      const ticketid = this.ticket.id;
      // console.log(this.ticket.id)
      let formData = new FormData();
      formData.append("id", ticketid);
      let video_path = this.$refs.file_video?.files[0];
      formData.append("video_path", video_path);
      console.log([...formData.entries()]);
      for (let index = 1; index <= 8; index++) {
        const imageRefKey = `file_${index}`;
        const imageKey = `image_path_${index}`;
        console.log(imageKey);
        console.log(imageRefKey);

        // Check if the file input exists and has a selected file
        if (
          this.$refs[imageRefKey] &&
          this.$refs[imageRefKey].files.length > 0
        ) {
          const file = this.$refs[imageRefKey].files[0];
          formData.append(imageKey, file);
          console.log("append");
          console.log([...formData.entries()]);
        }
      }
      if (this.$refs.file_9 && this.$refs.file_9.files.length > 0) {
        const challanFile = this.$refs.file_9.files[0];
        formData.append("challan_image", challanFile);
      }
      await axios
        .post("tickets/imageUpload", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .catch(function () {
          console.log("FAILURE!!");
        });
      this.isLoading = false;
    },
  },
};
</script>
