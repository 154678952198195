<template>
  <div>
    <LeftSideBar></LeftSideBar>
    <div class="page-wrapper">
      <Nav></Nav>
      <router-view />
      <my-footer></my-footer>
    </div>
  </div>
</template>

<script type="text/javascript">
import Nav from "@/views/auth-layout/nav.vue";
import LeftSideBar from "@/views/auth-layout/left-side-bar.vue";
import MyFooter from "@/views/auth-layout/footer.vue";

export default {
  name: "AuthLayout",
  components: {
    Nav,
    LeftSideBar,
    MyFooter,
  },
};
</script>