// globalFunctions.js

    export default {
        methods: {
          handleScroll1() {
            this.$refs.wrapper2.scrollLeft = this.$refs.wrapper1.scrollLeft;
            console.log('global');
          },
          handleScroll2() {
            this.$refs.wrapper1.scrollLeft = this.$refs.wrapper2.scrollLeft;
          },
        },
      };

