<template>
  <!-- partial -->
  <div class="page-content">
    <div class="row">
      <div class="col-lg-8 col-md-8 col-sm-6 col-xs-12">
        <h5 class="txt-dark">
          <back-button link="/values"></back-button>
          Update Value
        </h5>
      </div>
      <!-- Breadcrumb -->
      <div class="col-lg-4 col-sm-6 col-md-4 col-xs-12">
        <ol class="breadcrumb breadcrumb-line" style="float: right">
          <li class="breadcrumb-item">
            <router-link :to="`/`">Dashboard</router-link>
          </li>
          <li class="breadcrumb-item">
            <router-link :to="`/values`">Values</router-link>
          </li>
          <li class="breadcrumb-item active" aria-current="page">Update</li>
        </ol>
      </div>
    </div>
    <!-- /Breadcrumb -->
    <!-- Form  -->
    <div class="row">
      <div class="col-md-10 grid-margin stretch-card">
        <div class="card">
          <div class="card-body">

            <div class="row">
              <div class="col-md-12">
                <label class="col-form-label">Title</label>
                <div class="col-sm-12">
                  <v-text-field v-model="form.title" prepend-inner-icon="mdi-format-list-text" outlined
                    placeholder="Title" clearable></v-text-field>
                </div>
              </div>

              <div class="col-md-12">
                <label class="col-form-label">Description</label>
                <div class="col-sm-12">
                  <v-text-field v-model="form.description" prepend-inner-icon="mdi-format-list-text" outlined
                    placeholder="Description" clearable></v-text-field>
                </div>
              </div>
            </div>
            <div class="form mb-0">
              <submit-button :isLoading="isLoading" @submit="update" text="Update" style="float: right"></submit-button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- partial -->
</template>

<script type="text/javascript">
import axios from "axios";

export default {
  name: "ValueUpdate",
  data() {
    return {
      form: {},
      isLoading: false,
    };
  },
  mounted() {
    this.getData();
  },
  methods: {
    async getData() {
      this.isLoading = true;
      let form = await axios.get(`/values/${this.$route.params.valueId}`);
      this.form = form.data.data;
      this.isLoading = false;
    },
    async update() {
      try {
        this.isLoading = true;
        await axios.patch(`/values/${this.$route.params.valueId}`, this.form);
        this.isLoading = false;
        this.$router.push("/values");
      } catch (e) {
        this.isLoading = false;
      }
    },

  },
};
</script>
