<template>
  <div class="page-content">
    <div class="row">
      <div class="col-lg-8 col-md-8 col-sm-6 col-xs-12">
        <h5 class="txt-dark">
          <back-button link="/tickets"></back-button>
          Update Ticket
        </h5>
      </div>
      <div class="col-lg-4 col-sm-6 col-md-4 col-xs-12">
        <ol class="breadcrumb breadcrumb-line" style="float: right">
          <li class="breadcrumb-item">
            <router-link :to="`/`">Dashboard</router-link>
          </li>
          <li class="breadcrumb-item">
            <router-link :to="`tickets`">Tickets</router-link>
          </li>
          <li class="breadcrumb-item active" aria-current="page">Add</li>
        </ol>
      </div>
    </div>
    <div class="row">
      <div class="col-md-10 grid-margin stretch-card">
        <div class="card">
          <div class="card-body">

            <div class="row">
              <div class="col-sm-6">
                <label class="col-form-label">Title</label>
                <v-text-field v-model="form.title" prepend-inner-icon="mdi-ticket-account" outlined clearable
                  :error-messages="errors.title"></v-text-field>
                <span v-if="required_field_title" style="color: red; padding-left:30px">Title field is required</span>
              </div>
              <div class="col-sm-6">
                <label class="col-form-label">Ticket number</label>
                <v-text-field v-model="form.ticket_id" prepend-inner-icon="mdi-ticket-account" outlined
                  clearable></v-text-field>
              </div>

            </div>

            <div class="row">
              <label class="col-form-label">Description</label>
              <div class="col-sm-12">
                <v-textarea placeholder="Enter your description" prepend-inner-icon="mdi-ticket-percent"
                  v-model="form.description" outlined></v-textarea>
              </div>
            </div>

            <div class="row">
              <label class="col-form-label"> Before Service Description</label>
              <div class="col-sm-12">
                <v-textarea placeholder="Enter your description" prepend-inner-icon="mdi-ticket-percent"
                  v-model="form.before_service_description" outlined></v-textarea>
              </div>
            </div>
            <div class="row">
              <label class="col-form-label"> After Service Description</label>
              <div class="col-sm-12">
                <v-textarea placeholder="Enter your description" prepend-inner-icon="mdi-ticket-percent"
                  v-model="form.after_service_description" outlined></v-textarea>
              </div>
            </div>
            <div class="row">
              <label class="col-form-label"> Before Service</label>
              <div class="col-sm-4">
                <img v-if="form.image_path_1 != null" :src="path + '/' + form.image_path_1" alt="image 1"
                  style="height: 50%; width: 50% !important" /><br />
                <input type="file" @change="onImage1Change" accept="image/*" id="file_1" name="file_1" ref="file_1" />
                <br />
                <v-btn x-small v-if="form.image_path_1 != null" class="reset_image my-1" color="red" dark @click="
              ($refs.file_1.value = null), (form.image_path_1 = null)
              ">Remove</v-btn><br />
                <a :href="`${mediaUrl}/${form.image_path_1}`" v-if="form.image_path_1 != null" target="_blank">View
                  Image</a>
              </div>
              <div class="col-sm-4">
                <img v-if="form.image_path_2 != null" :src="path + '/' + form.image_path_2" alt="image 2"
                  style="height: 50%; width: 50% !important" /><br />
                <input type="file" @change="onImage2Change" accept="image/*" id="file_2" name="file_2"
                  ref="file_2" /><br />
                <v-btn x-small v-if="form.image_path_2 != null" class="reset_image my-1" color="red" dark @click="
              ($refs.file_2.value = null), (form.image_path_2 = null)
              ">Remove</v-btn><br />
                <a :href="`${mediaUrl}/${form.image_path_2}`" v-if="form.image_path_2 != null" target="_blank">View
                  Image</a>
              </div>
              <div class="col-sm-4">
                <img v-if="form.image_path_3 != null" :src="path + '/' + form.image_path_3" alt="image 3"
                  style="height: 50%; width: 50% !important" /><br />
                <input type="file" @change="onImage3Change" accept="image/*" id="file_3" name="file_3"
                  ref="file_3" /><br />
                <v-btn x-small v-if="form.image_path_3 != null" class="reset_image my-1" color="red" dark @click="
              ($refs.file_3.value = null), (form.image_path_3 = null)
              ">Remove</v-btn><br />
                <a :href="`${mediaUrl}/${form.image_path_3}`" v-if="form.image_path_3 != null" target="_blank">View
                  Image</a>
              </div>
              <div class="col-sm-4">
                <img v-if="form.image_path_4 != null" :src="path + '/' + form.image_path_4" alt="image 4"
                  style="height: 50%; width: 50% !important" /><br />
                <input type="file" @change="onImage4Change" accept="image/*" id="file_4" name="file_4"
                  ref="file_4" /><br />
                <v-btn x-small v-if="form.image_path_4 != null" class="reset_image my-1" color="red" dark @click="
              ($refs.file_4.value = null), (form.image_path_4 = null)
              ">Remove</v-btn><br />
                <a :href="`${mediaUrl}/${form.image_path_4}`" v-if="form.image_path_4 != null" target="_blank">View
                  Image</a>
              </div>
            </div>
            <div class="row">
              <label class="col-form-label">After Service</label>
              <div class="col-sm-4">
                <!-- Display existing images -->
                <img v-if="form.image_path_5 != null" :src="path + '/' + form.image_path_5" alt="Image 5"
                  style="height: 50%; width: 50% !important" /><br />
                <input type="file" @change="onImage5Change" accept="image/*" id="file_5" name="file_5"
                  ref="file_5" /><br />
                <v-btn x-small v-if="form.image_path_5 != null" class="reset_image my-1" color="red" dark @click="
              ($refs.file_5.value = null), (form.image_path_5 = null)
              ">Remove</v-btn><br />
                <a :href="`${mediaUrl}/${form.image_path_5}`" v-if="form.image_path_5 != null" target="_blank">View
                  Image</a>
              </div>
              <div class="col-sm-4">
                <img v-if="form.image_path_6 != null" :src="path + '/' + form.image_path_6" alt="Image 6"
                  style="height: 50%; width: 50% !important" /><br />
                <input type="file" @change="onImage6Change" accept="image/*" id="file_6" name="file_6"
                  ref="file_6" /><br />
                <v-btn x-small v-if="form.image_path_6 != null" class="reset_image my-1" color="red" dark @click="
              ($refs.file_6.value = null), (form.image_path_6 = null)
              ">Remove</v-btn><br />
                <a :href="`${mediaUrl}/${form.image_path_6}`" v-if="form.image_path_6 != null" target="_blank">View
                  Image</a>
              </div>
              <div class="col-sm-4">
                <img v-if="form.image_path_7 != null" :src="path + '/' + form.image_path_7" alt="Image 7"
                  style="height: 50%; width: 50% !important" /><br />
                <input type="file" @change="onImage7Change" accept="image/*" id="file_7" name="file_7"
                  ref="file_7" /><br />
                <v-btn x-small v-if="form.image_path_7 != null" class="reset_image my-1" color="red" dark @click="
              ($refs.file_7.value = null), (form.image_path_7 = null)
              ">Remove</v-btn><br />
                <a :href="`${mediaUrl}/${form.image_path_7}`" v-if="form.image_path_7 != null" target="_blank">View
                  Image</a>
              </div>
              <div class="col-sm-4">
                <img v-if="form.image_path_8 != null" :src="path + '/' + form.image_path_8" alt="Image 8"
                  style="height: 50%; width: 50% !important" /><br />
                <input type="file" @change="onImage8Change" accept="image/*" id="file_8" name="file_8"
                  ref="file_8" /><br />
                <v-btn x-small v-if="form.image_path_8 != null" class="reset_image my-1" color="red" dark @click="
              ($refs.file_8.value = null), (form.image_path_8 = null)
              ">Remove</v-btn><br />
                <a :href="`${mediaUrl}/${form.image_path_8}`" v-if="form.image_path_8 != null" target="_blank">View
                  Image</a>
              </div>
            </div>

            <div class="row">
              <div class="col-sm-4 mb-9">
                <label class="col-form-label"> Challan Image</label><br>
                <img v-if="form.challan_image != null" :src="path + '/' + form.challan_image"
                  alt="Challan Service Image" style="height: 80%; width: 30% !important" /><br>
                <input type="file" @change="onChallanImageChange" accept="image/*" id="challanImageInput" name="file_9"
                  ref="file_9" /> <br>
                <v-btn x-small class="reset_image my-1" color="red" v-if="form.challan_image != null" dark @click="
              ($refs.file_9.value = null), (form.challan_image = null)
              ">Remove</v-btn><br />
                <a :href="`${mediaUrl}/${form.challan_image}`" v-if="form.challan_image != null" target="_blank">View
                  Image</a>
              </div>
              <div class="col-sm-4 mb-9">
                <label class="col-form-label"> Outlet Image</label><br>
                <img v-if="form.outlet_image_path != null" :src="path + '/' + form.outlet_image_path"
                  alt=" Outlet Image" style="height: 80%; width: 30% !important" /><br>
                <input type="file" @change="outLetImageChange" accept="image/*" id="OutletImageInput" name="file_10"
                  ref="file_10" /> <br>
                <v-btn x-small class="reset_image my-1" color="red" v-if="form.outlet_image_path != null" dark @click="
              ($refs.file_10.value = null), (form.outlet_image_path = null)
              ">Remove</v-btn><br />
                <a :href="`${mediaUrl}/${form.outlet_image_path}`" v-if="form.outlet_image_path != null"
                  target="_blank">View
                  Image</a>
              </div>
            </div>
            <div class="row mt-5">
              <div class="col-sm-6">
                <label class="col-form-label"> OutLet Name</label>
                <v-text-field v-model="form.outlet_name" prepend-inner-icon="mdi-ticket-account" outlined
                  clearable></v-text-field>
              </div>
              <div class="col-sm-6">
                <label class="col-form-label"> Department name</label>
                <v-text-field v-model="form.dp_name" prepend-inner-icon="mdi-ticket-account" outlined
                  clearable></v-text-field>
              </div>
            </div>
            <div class="row">
              <label class="col-form-label"> Cooler </label>
              <div class="col-sm-12">
                <v-text-field v-model="form.cooler" prepend-inner-icon="mdi-ticket-account" outlined
                  clearable></v-text-field>
              </div>
            </div>
            <div class="row">
              <label class="col-form-label"> Closure Details</label>
              <div class="col-sm-6">
                <v-text-field v-model="form.closure_name" prepend-inner-icon="mdi-ticket-account" outlined
                  clearable></v-text-field>
              </div>
              <div class="col-sm-6">
                <v-text-field v-model="form.closure_contact" prepend-inner-icon="mdi-ticket-account" outlined
                  clearable></v-text-field>
              </div>
            </div>

            <div class="row">
              <label class="col-form-label"> Action Remark </label>
              <div class="col-sm-12">
                <v-text-field v-model="form.action_remark" prepend-inner-icon="mdi-ticket-account" outlined
                  clearable></v-text-field>
              </div>
            </div>

            <div class="row">
              <label class="col-form-label"> Reporting Person Details </label>
              <div class="col-sm-4">
                <v-text-field v-model="form.reporting_person_name" prepend-inner-icon="mdi-ticket-account" outlined
                  clearable></v-text-field>
              </div>
              <div class="col-sm-4">
                <v-text-field v-model="form.reporting_person_contact" prepend-inner-icon="mdi-ticket-account" outlined
                  clearable></v-text-field>
              </div>
            </div>
            <div class="row">
              <div class="col-sm-12">
                <label class="col-form-label"> Current Condition </label>
                <v-text-field v-model="form.current_condition" prepend-inner-icon="mdi-ticket-account" outlined
                  clearable></v-text-field>
              </div>
            </div>
            <div class="row">
              <div class="col-sm-6">
                <label class="col-form-label"> Email ID </label>
                <v-text-field v-model="form.emailId" prepend-inner-icon="mdi-ticket-account" outlined
                  clearable></v-text-field>
              </div>
              <div class="col-sm-3">
                <label class="col-form-label"> State </label>
                <v-autocomplete ref="state" v-model="form.state" :items="States" label="State"
                  append-icon="mdi-ticket-account" clearable :error-messages="errors.state"></v-autocomplete>
              </div>
              <div class="col-sm-3">
                <label class="col-form-label"> City </label>
                <v-autocomplete ref="state" v-model="form.town" :items="Cities" label="City"
                  append-icon="mdi-ticket-account" clearable :error-messages="errors.city"></v-autocomplete>
              </div>
            </div>
            <div class="row">
              <div class="col-sm-4">
                <label class="col-form-label"> Call Status </label>
                <v-autocomplete v-model="form.call_status" :items="call_status" label="Call Status"
                  prepend-icon="mdi-star-circle-outline"></v-autocomplete>
              </div>
              <div class="col-sm-4">
                <label class="col-form-label"> Assign to </label>
                <v-autocomplete ref="users" v-model="form.assigned_to_id" :items="Technicians"
                  label=" Select Technician" prepend-icon="mdi-account-box" clearable></v-autocomplete>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-sm-4">
              <v-autocomplete v-model="form.customer_type" :items="customer_types"
                @input="getMasters(form.customer_type)" label=" Select Customer" prepend-icon="mdi-account-box"
                clearable></v-autocomplete>
            </div>
            <div class="col-sm-4">
              <v-autocomplete v-model="form.customer_id" @change="getProductLists(form.customer_id)" :items="customers"
                label=" Select Customer" prepend-icon="mdi-account-box" clearable></v-autocomplete>
            </div>

            <div class="col-sm-4">
              <v-autocomplete ref="issue" v-model="form.product_id" :items="customerproductLists" label="Select Product"
                chips density="comfortable" prepend-icon="mdi-crosshairs-gps" clearable></v-autocomplete>
            </div>
            <div class="col-sm-4">
              <v-autocomplete ref="productissueLists" v-model="form.ticket_issues"
                @change="getProductissueLists(form.product_id)" :items="productIssueLists" chips label="Issue Lists"
                prepend-icon="mdi-shape" multiple clearable></v-autocomplete>
            </div>
            <div class="col-sm-4">
              <v-autocomplete ref="getProductSparepartLists" @change="getProductSparepartLists(form.product_id)"
                v-model="form.ticket_spare_parts" :items="productSparePartLists" chips label="Spare Part List"
                prepend-icon="mdi-shape" multiple clearable></v-autocomplete>
            </div>
            <div class="col-sm-4">
              <v-autocomplete ref="status" v-model="form.status_type" :items="statusTypes" label="Status"
                prepend-icon="mdi-chart-donut" :error-messages="errors.status_type_id"></v-autocomplete>
              <span v-if="required_field_status" style="color: red ; padding-left:30px">Status is required</span>
            </div>
            <div class="col-sm-4">
              <v-autocomplete ref="priority" v-model="form.priority_type_id" :items="priorites" label="Priority"
                prepend-icon="mdi-star-circle-outline" :error-messages="errors.priority_type_id"></v-autocomplete>
            </div>
            <div class="col-sm-4">
              <video :src="form.video_path ? `${path}/${form.video_path}` : null" controls width="600"
                height="400"></video>
              <br />
              <v-btn v-if="form.video_path !== null" x-small class="reset_image my-1" color="red" dark
                @click="($refs.file_video.value = null), (form.video_path = null)">Remove</v-btn>
            </div>


          </div>
          <v-card-text>
            <v-divider class="mt-5 mb-5"></v-divider>
            <div class="d-flex justify-end">
              <v-progress-circular v-if="isLoading" indeterminate color="primary"></v-progress-circular>
              <v-btn size="large" v-else color="primary" x-large dark @click="update">Update Ticket</v-btn>
            </div>
          </v-card-text>
        </div>
      </div>
    </div>
  </div>
</template>

<script type="text/javascript">
import axios from "axios";

export default {
  name: "TicketId",
  data() {
    return {
      form: {
        role_id: "",
        created_by_id: "",
        ticket_list_issues: [],
        ticket_spare_parts_list: [],
        ticket_spare_parts: []
      },
      priorites: [
        { text: "high", value: 1 },
        { text: "medium", value: 2 },
        { text: "low", value: 3 },
      ],
      customer_types: [
        { text: "Private", value: "Private" },
        { text: "Commercial", value: "Commercial" },
      ],
      call_status: [
        { text: "OPEN", value: "OPEN" },
        { text: "HOLD", value: "HOLD" },
        { text: "CLOSE", value: "CLOSE" },
      ],
      path: "",
      roles: [],
      products: [],
      customers: [],
      Technicians: [],
      customerproductLists: [],
      customertypeLists: [],
      productIssueLists: [],
      productSparePartLists: [],
      statusTypes: [
        { text: "Ongoing", value: "Ongoing" },
        { text: "Pending", value: "Pending" },
        { text: "Completed", value: "Completed" },
      ],

      isLoading: false,
      required_field_title: false,
      required_field_status: false,
      image1_url: "",
      image2_url: "",
      image3_url: "",
      image4_url: "",
      image5_url: "",
      image6_url: "",
      image7_url: "",
      image8_url: "",
      challan_image: "",
      outlet_image_path: "",
      States: [],
      Cities: [],
    };
  },

  async mounted() {
    await this.getData();
    if (this.form.customer_type != null) {
      this.getMasters(this.form.customer_type);
      console.log('with customer type');
    } else {
      this.getMasters();
    }

    this.path = this.mediaUrl;
    this.form.created_by_id = this.user.id;

  },

  methods: {
    async update() {
      try {
        this.isLoading = true;
        let updatedTicketIssues = [];
        let updatedTicketSpare = [];
        if (this.form.title != null) {

          this.form.ticket_issues.forEach((id) => {
            updatedTicketIssues.push({
              product_issue_id: id,
              ticket_id: this.form.id
            });
          });
          this.form.ticket_spare_parts.forEach((id) => {
            updatedTicketSpare.push({
              spare_part_id: id,
              ticket_id: this.form.id
            });
          });
          this.form.ticket_issues = updatedTicketIssues;
          console.log(this.form.ticket_issues)
          this.form.ticket_spare_parts = updatedTicketSpare;
          await axios.post(`/tickets`, this.form);
          await this.handleFileUpload();
          this.isLoading = false;
          this.$router.push("/tickets");
        } else {
          if (this.form.title == null) {
            this.required_field_title = true
            this.isLoading = false;

          } else if (this.form.status_type == null) {
            this.required_field_status = true
            this.isLoading = false;
          }

        }

      } catch (e) {
        console.log(e);
        this.isLoading = false;
      }
    },
    async getMasters(CustomerValueid) {
      let masters;
      if (CustomerValueid) masters = await axios.get("tickets/masters?values=customers");
      else masters = await axios.get("tickets/masters?values=limited");
      masters = masters.data;
      masters.users.forEach((user) => {
        const roleWithId3 = user.roles.find((role) => role.id === 4);
        if (roleWithId3) {
          this.Technicians.push({
            id: user.id,
            text: user.name,
            value: user.id,
          });
        }
      });
      masters.States.forEach((state) => {
        this.States.push({
          id: state.id,
          text: state.title,
          value: state.title,
        });
      });
      masters.City.forEach((city) => {
        this.Cities.push({
          id: city.id,
          text: city.title,
          value: city.title,
        });
      });
      this.customers = [];
      // if(this.form.customer_types == CustomerValueid)
      masters.customers.forEach((customer) => {
        if (customer.customer_type == CustomerValueid) {
          this.customers.push({
            id: customer.id,
            text: customer.name,
            value: customer.id,
          });
        }
      });
    },
    async getData() {

      this.isLoading = true;
      let form = await axios.get(`/tickets/${this.$route.params.ticketId}`);
      this.form = form.data.data;

      this.getProductissueLists(this.form.product_id);
      let ticket_issues = [];

      console.log('here');
      console.log(this.form.ticket_list_issues);
      this.form.ticket_list_issues.forEach((issue) => {
        ticket_issues.push({
          id: issue.product_issue.id,
          text: issue.product_issue.description,
          value: issue.product_issue.id,
        });
      });

      console.log("backend issues:");
      this.form.ticket_issues = [...new Set(ticket_issues)];
      console.log(this.form.ticket_issues);
      // this.isLoading = false;

      this.getProductLists(this.form.customer_id);
      let ticket_spare_parts = [];
      this.form.ticket_spare_parts_list.forEach((sparepart) => {
        ticket_spare_parts.push({
          id: sparepart.spare_part_id,
          text: sparepart.name,
          value: sparepart.spare_part_id,
        });
      });
      this.form.ticket_spare_parts = [...new Set(ticket_spare_parts)];
      console.log("here another");
      console.log(this.form.ticket_spare_parts);
      await this.getProductSparepartLists(this.form.product_id);

    },
    async getProductLists(ProductIssueValueId) {
      // this.isLoading = true;
      let customerproductLists = await axios.get(
        `/customers/${ProductIssueValueId}/customer_products`
      );
      customerproductLists = customerproductLists.data.data;
      this.customerproductLists = [];
      customerproductLists.forEach((product_list) => {
        this.customerproductLists.push({
          id: product_list.id,
          text: product_list.product.name,
          value: product_list.product.id,
        });
        this.isLoading = false;
      });
      // customerproductLists.forEach((type_list) => {
      //   this.customertypeLists.push({
      //     id: type_list.id,
      //     text: type_list.customer.customer_type,
      //     value: type_list.customer.customer_type,
      //   });
      // this.isLoading = false;
      // });
    },

    async getProductissueLists(ProductIssueValueId) {
      console.log(ProductIssueValueId);
      this.isLoading = true;
      let productIssueLists = await axios.get(
        `/products/${ProductIssueValueId}/product_issues`
      );
      productIssueLists = productIssueLists.data.data;




      this.productIssueLists = [];
      productIssueLists.forEach((product_issue_list) => {
        this.productIssueLists.push({
          id: product_issue_list.id,
          text: product_issue_list.title,
          value: product_issue_list.id,
        });
        this.isLoading = false;
      });
      console.log(" productIssueLists Master :");
      console.log(this.productIssueLists);
    },
    async getProductSparepartLists(selectedProductId) {
      // console.log(selectedProductId);
      this.isLoading = true;
      console.log(selectedProductId)
      let productSparePartLists = await axios.get(
        `/products/${selectedProductId}/product_spares`
      );
      productSparePartLists = productSparePartLists.data.data;
      console.log('productSparePartLists')
      console.log(productSparePartLists)
      console.log('productSparePartLists')
      // this.productIssueLists = [];
      productSparePartLists.forEach((product_sparepart_list) => {
        this.productSparePartLists.push({
          id: product_sparepart_list.id,
          text: product_sparepart_list.name,
          value: product_sparepart_list.id,
        });
        this.isLoading = false;
      });

      console.log(" productIssueLists sparepart :");
      console.log(this.productSparePartLists);
    },

    VideoPath(e) {
      const file = e.target.files[0];
      this.video_path = URL.createObjectURL(file);
    },

    onImage1Change(e) {
      const file = e.target.files[0];
      this.image1_url = URL.createObjectURL(file);
    },
    onImage2Change(e) {
      const file2 = e.target.files[0];
      this.image2_url = URL.createObjectURL(file2);
    },
    onImage3Change(e) {
      const file3 = e.target.files[0];
      this.image3_url = URL.createObjectURL(file3);
    },
    onImage4Change(e) {
      const file4 = e.target.files[0];
      this.image4_url = URL.createObjectURL(file4);
    },
    onImage5Change(e) {
      const file5 = e.target.files[0];
      this.image5_url = URL.createObjectURL(file5);
    },
    onImage6Change(e) {
      const file6 = e.target.files[0];
      this.image6_url = URL.createObjectURL(file6);
    },
    onImage7Change(e) {
      const file7 = e.target.files[0];
      this.image7_url = URL.createObjectURL(file7);
    },
    onImage8Change(e) {
      const file8 = e.target.files[0];
      this.image8_url = URL.createObjectURL(file8);
    },
    onChallanImageChange(e) {
      const file9 = e.target.files[0];
      this.challan_image = URL.createObjectURL(file9);
    },
    outLetImageChange(e) {
      const file10 = e.target.files[0];
      this.outlet_image_path = URL.createObjectURL(file10);
    },
    async handleFileUpload() {
      this.isLoading = true;
      const ticketid = this.form.id;
      let formData = new FormData();
      formData.append("id", ticketid);
      let video_path = this.$refs.file_video?.files[0];
      formData.append("video_path", video_path);
      for (let index = 1; index <= 8; index++) {
        const imageRefKey = `file_${index}`;
        const imageKey = `image_path_${index}`;
        // Check if the file input exists and has a selected file
        if (
          this.$refs[imageRefKey] &&
          this.$refs[imageRefKey].files.length > 0
        ) {
          const file = this.$refs[imageRefKey].files[0];
          formData.append(imageKey, file);
        }
      }
      if (this.$refs.file_10 && this.$refs.file_10.files.length > 0) {
        const file10 = this.$refs.file_10.files[0];
        // Use the appropriate API endpoint for the outlet image
        let outletFormData = new FormData();
        outletFormData.append("ticket_id", ticketid);
        outletFormData.append("outlet_image_path", file10);

        await axios.post("upload_outlet_image_path", outletFormData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }).catch(function () {
          console.log("Outlet Image Upload FAILURE!!");
        });
      }
      if (this.$refs.file_9 && this.$refs.file_9.files.length > 0) {
        const file9 = this.$refs.file_9.files[0];
        // Use the appropriate API endpoint for the outlet image
        let outletFormData = new FormData();
        outletFormData.append("ticket_id", ticketid);
        outletFormData.append("challan_image", file9);

        await axios.post("upload_challan_image", outletFormData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }).catch(function () {
          console.log("Challan Image Upload FAILURE!!");
        });
      }
      await axios
        .post("tickets/imageUpload", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .catch(function () {
          console.log("FAILURE!!");
        });
      this.isLoading = false;
    },
  },
};
</script>
