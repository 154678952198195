<template>
  <span @click="save">
    <i class="link-arrow" data-feather="save"></i>

    <!-- <i class="fa fa-floppy-o"></i> -->
  </span>
</template>

<script type="text/javascript">
export default {
  name: "SaveButtonWidget",
  props: ["link"],
  methods: {
    save() {
      this.$emit("click");
    },
  },
};
</script>

<style scoped>
span {
  margin-left: 5px;
}
</style>
