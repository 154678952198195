<template>
  <!-- partial -->
  <div class="page-content">
    <div class="row">
      <div class="col-lg-8 col-md-8 col-sm-6 col-xs-12">
        <h5 class="txt-dark">
          Values Lists &nbsp;
          <save-button @click="save"></save-button>
          <small v-if="isSaving">Saving...</small>
          <!-- <add-button link="/values/create"></add-button> -->
        </h5>
      </div>
      <!-- Breadcrumb -->
      <div class="col-lg-4 col-sm-6 col-md-4 col-xs-12">
        <ol class="breadcrumb breadcrumb-line" style="float: right">
          <li class="breadcrumb-item">
            <router-link :to="`/`">Dashboard</router-link>
          </li>
          <li class="breadcrumb-item"><a href="#">Values List</a></li>
          <li class="breadcrumb-item active" aria-current="page">List</li>
        </ol>
      </div>
    </div>
    <!-- /Breadcrumb -->
    <!-- Add Button -->
    <div class="row">
      <div class="col-md-12 grid-margin stretch-card">
        <div class="card">
          <div class="card-body">
            <!-- <div class="col-lg-10 col-md-8 col-sm-6 col-xs-12"> -->
            <v-autocomplete
              @change="getData"
              ref="value"
              v-model="value_id"
              :items="valueItems"
              label="Select Value"
              prepend-icon="mdi-format-list-text"
              solo
            ></v-autocomplete>
            <!-- </div> -->
          </div>
        </div>
      </div>
    </div>
    <!-- /Add Button -->
    <!-- Table -->
    <div class="row">
      <div class="col-md-12 grid-margin stretch-card">
        <div class="card">
          <div class="card-body">
            <div class="table-responsive">
              <table id="dataTableExample" class="table table-hover">
                <thead>
                  <tr>
                    <th>Sr. No.</th>
                    <th>Title</th>
                    <th>Description</th>
                    <!-- <th>Is Active</th> -->
                    <th class="text-center">Action</th>
                  </tr>
                </thead>
                <tbody>
                  <loading-table
                    :isLoading="isLoading"
                    cols="12"
                  ></loading-table>
                  <tr v-if="valueLists.length == 0">
                    <td colspan="12" class="text-center">No data Found</td>
                  </tr>
                  <tr
                    v-for="(valueList, i) in valueLists"
                    :key="`valueList${i}`"
                  >
                    <td>{{ i + 1 }}</td>
                    <td>
                      <v-col class="px-0">
                        <v-text-field
                          v-model="valueList.title"
                          outlined
                          dense
                          label="Title"
                          :input="
                            (valueList.title = valueList.title.toUpperCase())
                          "
                          hide-details="auto"
                          :error-messages="
                            valueList.title == ''
                              ? 'This Title field is required'
                              : ''
                          "
                        ></v-text-field>
                      </v-col>
                    </td>
                    <td>
                      <v-col class="px-0">
                        <v-text-field
                          :error-messages="
                            valueList.description == ''
                              ? 'this description field is required'
                              : ''
                          "
                          v-model="valueList.description"
                          :input="
                            (valueList.description =
                              valueList.description.toUpperCase())
                          "
                          outlined
                          dense
                          label="Description"
                          hide-details="auto"
                        ></v-text-field>
                      </v-col>
                    </td>
                    <!-- <td>
                      <my-checkbox
                        :selection.sync="valueList.is_active"
                        placeholder=""
                      ></my-checkbox>
                    </td> -->

                    <td class="text-right">
                      <div class="text-center">
                        <v-tooltip bottom v-if="!valueList.id">
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn
                              v-bind="attrs"
                              v-on="on"
                              small
                              color="primary"
                              icon
                              @click="deleteValueList(valueList)"
                            >
                              <v-icon>mdi-delete-outline</v-icon>
                            </v-btn>
                          </template>
                          <span>Delete</span>
                        </v-tooltip>
                        <v-tooltip bottom v-else>
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn
                              v-bind="attrs"
                              v-on="on"
                              small
                              color="primary"
                              icon
                              @click="delete_data(valueList.id)"
                            >
                              <v-icon>mdi-delete-outline</v-icon>
                            </v-btn>
                          </template>
                          <span>Delete</span>
                        </v-tooltip>
                      </div>
                    </td>
                  </tr>
                  <tr v-if="value_id">
                    <td colspan="9">
                      <v-btn
                        outlined
                        block
                        color="primary"
                        @click="addEmptyValueList()"
                      >
                        Add New Row
                      </v-btn>
                    </td>
                  </tr>
                </tbody>
              </table>
              <!-- /Table -->
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- partial -->
</template>

<script type="text/javascript">
import axios from "axios";

export default {
  name: "Values",
  data() {
    return {
      value_id: "",
      valueItems: [],
      count: 0,
      serialNoStarting: 1,
      isLoading: false,
      // isExcelloading: false,
      isSaving: false,
      search: "",
      valueLists: [],
      // value_lists_excel: [],
      // excel_fields: {
      //   "Sr No": "sr_no",
      //   "VALUE NAME": "value_name",
      //   DESCRIPTION: "description",
      //   CODE: "code",
      // },
      // ExportValueLists: [],
    };
  },
  mounted() {
    this.getMasters();
  },
  methods: {
    async getMasters() {
      let masters = await axios.get("value_lists/masters");
      masters = masters.data;
      masters.values.forEach((value) => {
        this.valueItems.push({
          id: value.id,
          text: value.title,
          value: value.id,
        });
      });
    },
    async getData() {
      this.isLoading = true;
      let valueLists = await axios.get(`/values/${this.value_id}/value_lists`);
      this.valueLists = valueLists.data.data;
      this.count = valueLists.data.count;
      this.isLoading = false;
      // this.exportToExcel();
    },
    addEmptyValueList() {
      this.valueLists.push({
        value_id: this.value_id,
        company_id: this.company.id,
        title: "",
        description: "",
        created_by_id: this.user.id,
        is_active: 1,
      });
    },
    deleteValueList(valueList) {
      this.valueLists = this.valueLists.filter((ar) => ar != valueList);
    },
    async save() {
      if (this.valueLists.length > 0) {
        this.isSaving = true;
        let payload = {
          datas: this.valueLists,
        };
        let response = await axios.post(
          `/values/${this.value_id}/value_lists_multiple`,
          payload
        );
        this.valueLists = response.data.data;
        this.isSaving = false;
      }
    },
    async delete_data(id) {
      let check = confirm("Are you sure you want to delete the data");
      if (check) {
        try {
          this.isLoading = true;
          await axios.post(`valuelists/${id}`);
          this.isLoading = false;
          this.getData();
        } catch (e) {
          this.isLoading = false;
        }
      }
    },
    // async exportToExcel() {
    //   this.isExcelloading = true;
    //   this.title = "Value List Master";
    //   this.value_lists_excel = [];
    //   let ExportValueLists = [];
    //   ExportValueLists = await axios.get(`/values/${this.search}/value_lists`);
    //   this.ExportValueLists = ExportValueLists.data.data;
    //   let sr_no = 1;
    //   this.ExportValueLists.forEach((valuelist) => {
    //     this.value_lists_excel.push({
    //       sr_no: sr_no,
    //       description: valuelist.description,
    //       code: valuelist.code,
    //       value_name: valuelist.value.name,
    //     });
    //     sr_no++;
    //   });
    //   this.isExcelloading = false;
    // },
  },
};
</script>
