import Vue from "vue";
import VueRouter from "vue-router";
import store from "@/store";

import Home from "../views/dashboards/index.vue";

// layout
import AuthLayout from "../views/auth-layout/index.vue";
import UnAuthLayout from "../views/unauth-layout/index.vue";

import Dashboards from "../views/dashboards/index.vue";

// Auth
import Login from "@/views/auth/login.vue";
import Register from "@/views/auth/register.vue";
import ForgotPassword from "@/views/auth/forgot-password.vue";

// Companies
import CompaniesPage from "../views/companies/index.vue";
import CompanyCreatePage from "../views/companies/create.vue";
import CompanyIdPage from "../views/companies/id.vue";

// Orders
import Orders from "@/views/orders/index.vue";
import OrderId from "@/views/orders/id.vue";

// Values
// import ValuesUpload from "@/views/values/upload.vue";
import Values from "@/views/values/index.vue";
import ValueCreate from "@/views/values/create.vue";
import ValueId from "@/views/values/id.vue";

// ValueLists
// import ValueListUpload from "@/views/value-lists/upload.vue";
import ValueLists from "@/views/value-lists/index.vue";
import ValueListCreate from "@/views/value-lists/create.vue";
// import ValueListId from "@/views/value-lists/id.vue";

// ValueListDetailsva;u
// import ValueListUpload from "@/views/value-lists/upload.vue";
import ValueListDetails from "@/views/value-list-details/index.vue";
import ValueListDetailCreate from "@/views/value-list-details/create.vue";
// import ValueListDetailId from "@/views/value-list-details/id.vue";

// System Setup
//Organizations
import Users from "@/views/users/index.vue";
import UserCreate from "@/views/users/create.vue";
import UserId from "@/views/users/id.vue";
import PasswordChange from "@/views/users/Password.vue"; // Import the PasswordChange component
import Profiles from "@/views/profiles/index.vue";
import ProfileId from "@/views/profiles/id.vue";

//Tickets
import Tickets from "@/views/tickets/index.vue";
import TicketCreate from "@/views/tickets/create.vue";
import TicketId from "@/views/tickets/id.vue";
import TicketsUploadPage from "@/views/tickets/upload.vue";
import TicketSummaries from "@/views/tickets/summary.vue";

import Products from "@/views/products/index.vue";
import GenerateProductQrCode from "@/views/products/generate-product-qrcode.vue";
import ProductCreate from "@/views/products/create.vue";
import ProductId from "@/views/products/id.vue";
import ProductsUploadPage from "@/views/products/upload.vue";
// import ports from "@/views/system-setup/ports/index.vue";
// import portCreate from "@/views/system-setup/ports/create.vue";

//Customers
import Customers from "@/views/customers/index.vue";
import CustomerCompanies from "@/views/CustomerCompanies/index.vue";
import CompanyCreate from "@/views/CustomerCompanies/create.vue";
import CompanyId from "@/views/CustomerCompanies/id.vue";
import CustomerCreate from "@/views/customers/create.vue";
import CustomerId from "@/views/customers/id.vue";

import ProductIssues from "@/views/product-issues/index.vue";
import ProductSpares from "@/views/product-spares/index.vue";
import ProductSpareCreate from "@/views/product-spares/create.vue";

// import ProductsIssuesUploadPage from "@/views/product-issues/upload.vue";

import CustomerProducts from "@/views/customer-products/index.vue";
import IssueList from "@/views/ticket-issue/index.vue";

// about us
import AboutUsPage from "../views/about-us/index.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "",
    component: AuthLayout,
    children: [
      {
        path: "/",
        name: "Home",
        component: Home,
      },
      {
        path: "/dashboards",
        name: "Dashboards",
        component: Dashboards,
      },
      // Values
      {
        path: "/values",
        name: "Values",
        component: Values,
      },
      // {
      //   path: "/values/upload",
      //   name: "ValuesUpload",
      //   component: ValuesUpload,
      // },
      {
        path: "/values/create",
        name: "ValueCreate",
        component: ValueCreate,
      },
      {
        path: "/values/:valueId",
        name: "ValueId",
        component: ValueId,
      },
      // ValueLists
      {
        path: "/value-lists",
        name: "ValueLists",
        component: ValueLists,
      },
      // {
      //   path: "/value-lists/upload",
      //   name: "ValueListUpload",
      //   component: ValueListUpload,
      // },
      {
        path: "/value-lists/create",
        name: "ValueListCreate",
        component: ValueListCreate,
      },
      // {
      //   path: "/value-lists/:valueListId",
      //   name: "ValueListId",
      //   component: ValueListId,
      // },
      // ValueListDetails
      {
        path: "/value-list-details",
        name: "ValueListDetails",
        component: ValueListDetails,
      },
      {
        path: "/value-list-details/create",
        name: "ValueListDetailCreate",
        component: ValueListDetailCreate,
      },

      {
        path: "/users",
        name: "Users",
        component: Users,
      },
      {
        path: "/users/:userId/password-change",
        name: "PasswordChange",
        component: PasswordChange,
      },
      {
        path: "/users/create",
        name: "UserCreate",
        component: UserCreate,
      },
      {
        path: "/users/:userId",
        name: "UserId",
        component: UserId,
      },
      {
        path: "/users/:status",
        name: "Users",
        component: Users,
      },
      {
        path: "/profiles",
        name: "Profiles",
        component: Profiles,
      },
      {
        path: "/profiles/:profileId",
        name: "ProfileId",
        component: ProfileId,
      },
      {
        path: "/products/upload",
        name: "ProductsUploadPage",
        component: ProductsUploadPage,
      },
      {
        path: "/tickets/upload",
        name: "TicketsUploadPage",
        component: TicketsUploadPage,
      },
      // Tickets
      {
        path: "/tickets",
        name: "Tickets",
        component: Tickets,
      },
      {
        path: "/tickets/create",
        name: "TicketCreate",
        component: TicketCreate,
      },
      {
        path: "/tickets-summary/:ticketId",
        name: "TicketSummaries",
        component: TicketSummaries, // Replace with your actual component
      },
      {
        path: "/tickets/:ticketId",
        name: "TicketId",
        component: TicketId,
      },
      {
        path: "/tickets/:status_type",
        name: "tickets",
        component: Tickets,
      },

      // Owner
      {
        path: "/products",
        name: "Products",
        component: Products,
      },
      {
        path: "/generate-product-qrcodes",
        name: "GenerateProductQrCode",
        component: GenerateProductQrCode,
      },
      {
        path: "/products/create",
        name: "ProductCreate",
        component: ProductCreate,
      },
      {
        path: "/products/:productId",
        name: "ProductId",
        component: ProductId,
      },
      {
        path: "/products/:productId/product-issues",
        name: "ProductIssues",
        component: ProductIssues,
      },
      {
        path: "/products/:productId/product-spares",
        name: "ProductSpares",
        component: ProductSpares,
      },
      {
        path: "/tickets/:ticketId/ticket-spares",
        name: "ProductSpareCreate",
        component: ProductSpareCreate,
      },
      //   {
      //     path: "/reports/ticket-report",
      //     name: "MonthlyReportPage",
      //     component: MonthlyReportPage,
      // },
      {
        path: "/customers",
        name: "Customers",
        component: Customers,
      },
      {
        path: "/customers/companies",
        name: "CustomerCompanies",
        component: CustomerCompanies,
      },
      {
        path: "/customers/companies/create",
        name: "CompanyCreate",
        component: CompanyCreate,
      },
      {
        path: "/customers/companies/:CompanyId",
        name: "CompanyId",
        component: CompanyId,
      },
      {
        path: "/customers/create",
        name: "CustomerCreate",
        component: CustomerCreate,
      },
    
      {
        path: "/customers/:customerId",
        name: "CustomerId",
        component: CustomerId,
      },
      {
        path: "/customers/:customerId/customer-products",
        name: "CustomerProducts",
        component: CustomerProducts,
      },
      {
        path: "/tickets/:issueId/issueList",
        name: "IssueList",
        component: IssueList,
      },
      // Comapnies
      {
        path: "/companies",
        name: "CompaniesPage",
        component: CompaniesPage,
      },
      {
        path: "/companies/create",
        name: "CompanyCreatePage",
        component: CompanyCreatePage,
      },
      {
        path: "/companies/:companyId",
        name: "CompanyIdPage",
        component: CompanyIdPage,
      },
      //about - us
      {
        path: "/about-us",
        name: "AboutUsPage",
        component: AboutUsPage,
      },
      // Orders
      {
        path: "/orders",
        name: "Orders",
        component: Orders,
      },
      {
        path: "/orders/:orderId",
        name: "OrderId",
        component: OrderId,
      },

    ],
  },
  {
    path: "",
    name: "UnAuthLayout",
    component: UnAuthLayout,
    children: [
      {
        path: "/login",
        name: "Login",
        component: Login,
      },
      {
        path: "/register",
        name: "Register",
        component: Register,
      },
      {
        path: "/forgotpassword",
        name: "ForgotPassword",
        component: ForgotPassword,
      },
    ],
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  if (
    to.name === "Login" ||
    to.name === "Register" ||
    to.name === "ForgotPassword"
  ) {
    if (store.getters["auth/authenticated"]) {
      return next({
        name: "Home",
      });
    }
    next();
  } else if (!store.getters["auth/authenticated"]) {
    return next({
      name: "Login",
    });
  }
  next();
});

router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById("loading-bg");
  if (appLoading) {
    appLoading.style.display = "none";
  }
  let script = document.createElement("script");
  script.setAttribute("src", "/vendors/feather-icons/feather.min.js");
  script.setAttribute("type", "text/javascript");
  document.body.appendChild(script);

  script = document.createElement("script");
  script.setAttribute("src", "/js/template.js");
  script.setAttribute("type", "text/javascript");
  document.body.appendChild(script);
});

export default router;
