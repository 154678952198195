<template>
  <!-- partial -->
  <div class="page-content">
    <div class="row">
      <div class="col-lg-8 col-md-8 col-sm-6 col-xs-12">
        <h5 class="txt-dark">Orders &nbsp;</h5>
      </div>
      <!-- Breadcrumb -->
      <div class="col-lg-4 col-sm-6 col-md-4 col-xs-12">
        <ol class="breadcrumb breadcrumb-line" style="float: right">
          <li class="breadcrumb-item">
            <router-link :to="`/`">Dashboard</router-link>
          </li>
          <li class="breadcrumb-item"><a href="#">Orders</a></li>
          <li class="breadcrumb-item active" aria-current="page">List</li>
        </ol>
      </div>
    </div>
    <!-- Search & Filtering  -->
    <div class="row">
      <div class="col-md-12 grid-margin stretch-card">
        <div class="card">
          <div class="card-body row">
            <div class="col-lg-8 col-md-8 col-sm-6 col-xs-12">
              <v-text-field
                v-model="filter.search_keyword"
                label="Search ... "
                prepend-inner-icon="mdi-newspaper-variant-multiple-outline"
                placeholder="Search By Name........"
                @keydown.enter="(page = 1), getData()"
              ></v-text-field>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Search & Filtering  -->
    <!-- Table -->
    <div class="row">
      <div class="col-md-12 grid-margin stretch-card">
        <div class="card">
          <div class="card-body">
            <div class="table-responsive">
              <table id="dataTableExample" class="table table-hover">
                <thead>
                  <tr>
                    <th>Sr No</th>
                    <th>User</th>
                    <th>Description</th>
                    <th>Plan</th>
                    <th>Pricing</th>
                    <!-- <th>Action</th> -->
                  </tr>
                </thead>
                <tbody>
                  <!-- Table Loader -->
                  <loading-table
                    :isLoading="isLoading"
                    cols="5"
                  ></loading-table>
                  <!-- If No Data Received-->
                  <tr v-if="orders.length == 0">
                    <td colspan="5" class="text-center" style="color: grey">
                      No Data Found
                    </td>
                  </tr>
                  <!-- If data Received -->
                  <tr v-else v-for="(order, at) in orders" :key="`order${at}`">
                    <td>{{ serialNoStarting + at + 1 }}</td>
                    <td>{{ order.user.name }}</td>
                    <td>{{ order.description }}</td>
                    <td>{{ order.plan }}</td>
                    <td>{{ order.price }}</td>
                    <!-- <td>
                      <v-btn
                        class="mx-2"
                        fab
                        x-small
                        color="primary"
                        :to="`/orders/${order.id}`"
                      >
                        <v-icon dark> mdi-pencil </v-icon>
                      </v-btn>
                    </td> -->
                  </tr>
                </tbody>
              </table>
              <!-- /Table -->
            </div>
            <pagination :count="count" @submit="getData"></pagination>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- partial -->
</template>

<script type="text/javascript">
import axios from "axios";

export default {
  name: "order",
  data() {
    return {
      filter: {
        search_keyword: "",
      },
      orders: [],
      count: 0,
      serialNoStarting: 1,
      isLoading: false,
    };
  },
  mounted() {
    this.getData();
  },
  methods: {
    async getData(page = 1) {
      this.isLoading = true;
      let query =
        "page=" +
        page +
        "&rowsPerPage=" +
        this.rowsPerPage +
        "&search_keyword=" +
        this.filter.search_keyword;
      let orders = await axios.get(`/orders?${query}`);
      this.orders = orders.data.data;
      this.count = orders.data.count;
      this.serialNoStarting = (page - 1) * this.rowsPerPage;
      this.isLoading = false;
    },
  },
};
</script>
