<template>
  <!-- partial -->
  <div class="page-content">
    <div class="row">
      <div class="col-lg-7 col-md-7 col-sm-6 col-xs-12">
        <h5 class="txt-dark">
          Companies &nbsp;
          <add-button link="/companies/create"></add-button>
        </h5>
      </div>
      <!-- Breadcrumb -->
      <div class="col-lg-5 col-sm-6 col-md-5 col-xs-12">
        <ol class="breadcrumb breadcrumb-line" style="float: right">
          <li class="breadcrumb-item">
            <router-link :to="`/`">Dashboard</router-link>
          </li>
          <li class="breadcrumb-item">Companies</li>

          <li class="breadcrumb-item active" aria-current="page">List</li>
        </ol>
      </div>
    </div>
    <!-- /Breadcrumb -->
    <!-- Search & Filtering  -->
    <div class="row">
      <div class="col-md-12 grid-margin stretch-card">
        <div class="card">
          <div class="card-body row">
            <div class="col-lg-8 col-md-8 col-sm-6 col-xs-12">
              <input
                type="text"
                class="form-control"
                placeholder="Search..."
                v-model="search_keyword"
                @keydown.enter="getData"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Search & Filtering  -->
    <!-- Table -->
    <div class="row">
      <div class="col-md-12 grid-margin stretch-card">
        <div class="card">
          <div class="card-body">
            <!-- End Export to Excel -->
            <div class="table-responsive">
              <table id="dataTableExample" class="table table-hover">
                <thead>
                  <tr>
                    <th class="text-left">Sr No</th>
                    <th class="text-left">Name</th>
                    <th class="text-left">Email</th>
                    <th class="text-left">Contact Person</th>
                    <th class="text-left">Phone</th>
                    <th class="text-left">Address</th>
                    <th class="text-right">Action</th>
                  </tr>
                </thead>
                <tbody>
                  <!-- Table Loader -->
                  <loading-table
                    :isLoading="isLoading"
                    cols="8
                        "
                  ></loading-table>
                  <!-- If No Data Received-->
                  <tr v-if="companies.length == 0">
                    <td colspan="8" class="text-center" style="color: grey">
                      No Data Found
                    </td>
                  </tr>
                  <!-- If data Received -->
                  <tr v-for="(company, at) in companies" :key="`company${at}`">
                    <td>{{ serialNoStarting + at + 1 }}</td>

                    <td>{{ company.name || "" }}</td>
                    <td>{{ company.email || "" }}</td>
                    <td>{{ company.contact_person || "" }}</td>
                    <td>{{ company.phone || "" }}</td>
                    <td>
                      {{ company.address || "" }}
                    </td>
                    <td>
                      <v-btn
                        class="mx-2"
                        color="primary"
                        fab
                        x-small
                        :to="`/companies/${company.id}`"
                      >
                        <v-icon dark> mdi-pencil </v-icon>
                      </v-btn>
                    </td>
                  </tr>
                </tbody>
              </table>
              <!-- /Table -->
            </div>
            <pagination :count="count" @submit="getData"></pagination>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- partial -->
</template>

<script>
import axios from "axios";
export default {
  name: "companiesPage",
  data() {
    return {
      form: {
        is_active: "",
      },
      page: 1,
      serialNoStarting: 1,
      paginationLength: 0,
      count: 0,
      search_keyword: "",
      companies: [],

      isLoading: false,
    };
  },
  watch: {
    page: "getData",
  },
  mounted() {
    this.getData();
  },
  methods: {
    async getData() {
      this.isLoading = true;
      this.search_keyword = this.search_keyword ? this.search_keyword : "";
      let companies = [];
      let query =
        "page=" +
        this.page +
        "&rowsPerPage=" +
        this.rowsPerPage +
        "&search_keyword=" +
        this.search_keyword +
        "&is_active=" +
        this.form.is_active;
      companies = await axios.get(`companies?${query}`);
      this.companies = companies.data.data;
      this.count = companies.data.count;
      this.serialNoStarting = (this.page - 1) * this.rowsPerPage;
      this.paginationLength = Math.ceil(this.count / this.rowsPerPage);
      this.isLoading = false;
    },
  },
};
</script>
