<template>
  <!-- partial:../../partials/_navbar.html -->
  <nav class="navbar">
    <a href="#" class="sidebar-toggler">
      <i data-feather="menu"></i>
    </a>
    <div class="navbar-content">
      <v-btn color="black" large text class="user-btn">

        {{ user.name != null ? user.name : user.email }}
        [ {{ roleName }} ]
      </v-btn>
      <!-- <form class="search-form">
        <div class="input-group">
          <div class="input-group-text">
            <i data-feather="search"></i>
          </div>
          <input
            type="text"
            class="form-control"
            id="navbarForm"
            placeholder="Search here..."
          />
        </div>
      </form> -->
      <ul class="navbar-nav">
        <li class="nav-item">
          <router-link to="/about-us" class="nav-link">
            <span class="link-title">About-us</span>
          </router-link>
        </li>
        <li class="nav-item">
          <a href="javascript:void" @click="logout" class="text-body ms-0">
            <i class="me-2 icon-md" data-feather="log-out"></i>
          </a>
        </li>

      </ul>
    </div>
  </nav>
  <!-- partial -->
</template>

<script type="text/javascript">
import { mapActions } from "vuex";

export default {
  data() {
    return {
      roleId: "",
      roleName: ""
    };
  },
  mounted() {
    this.roleId = this.user.roles[0].id;
    this.roleName = this.user.roles[0].name;

    console.log(this.roleId1);
  },
  methods: {
    ...mapActions({
      logOut: "auth/logOut",
    }),
    logout() {
      this.logOut();
      location.replace("/");
    },
  },
};
</script>
<style scoped>
.user-btn {
  max-width: 50%;
  /* Limit the maximum width to half the available width */
  white-space: nowrap;
  /* Prevent text from wrapping to the next line */
  overflow: hidden;
  /* Hide any overflowing text */
  text-overflow: ellipsis;
  /* Show ellipsis (...) for truncated text */

  /* margin-left : 40px;  */
  margin-top: 20px;
}
</style>