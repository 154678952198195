<template>
  <!-- partial -->

  <div class="page-content">
    <div class="row">
      <div class="col-lg-7 col-md-7 col-sm-6 col-xs-12">
        <h5 class="txt-dark">
          Tickets &nbsp;
          <add-button link="/tickets/create" v-if="roleName == 'SUPER ADMIN' || roleName == 'MIM'"></add-button>
          &nbsp;
          <upload-button link="/tickets/upload"></upload-button>
        </h5>
      </div>
      <!-- Breadcrumb -->
      <div class="col-lg-5 col-sm-6 col-md-5 col-xs-12">
        <ol class="breadcrumb breadcrumb-line" style="float: right">
          <li class="breadcrumb-item">
            <router-link :to="`/dashboards`">Dashboard</router-link>
          </li>
          <li class="breadcrumb-item">Tickets</li>
          <li class="breadcrumb-item active" aria-current="page">List</li>
        </ol>
      </div>
    </div>
    <!-- /Breadcrumb -->
    <!-- Search & Filtering  -->
    <div class="row">
      <div class="col-md-12 grid-margin stretch-card">
        <div class="card">
          <div class="card-body row">
            <div class="col-lg-8 col-md-8 col-sm-6 col-xs-12">
              <v-text-field v-model="search_keyword" label="Search ... "
                prepend-inner-icon="mdi-newspaper-variant-multiple-outline"
                placeholder="Search By Title, Description........" @keydown.enter="getData"></v-text-field>
            </div>
            <div class="col-md-4">
              <div style="float: right">
                <v-card-title v-if="tickets.length != 0">
                  <v-spacer></v-spacer>
                  <v-btn class="primary mx-4" @click="generateExcel()">
                    <span v-if="isExcelloading"> Generating Excel... </span>
                    <span v-else>Generate Excel</span>
                  </v-btn>
                </v-card-title>
              </div>
            </div>
            <v-row>
              <v-col sm="3">
                <v-autocomplete ref="Technician" v-model="form.assigned_to_id" :items="Technicians"
                  label="Select Technician" prepend-icon="mdi-feature-search" @input="getData"
                  clearable></v-autocomplete>
              </v-col>
              <v-col sm="3">
                <v-autocomplete ref="status" v-model="form.status_type" :items="statusTypes" label="Status"
                  prepend-icon="mdi-calendar" @input="getData" clearable
                  :error-messages="errors.status_type"></v-autocomplete>
              </v-col>
              <v-col sm="3">
                <v-autocomplete ref="state" v-model="form.state" :items="States" label="State"
                  prepend-icon="mdi-calendar" @input="getData" clearable
                  :error-messages="errors.state"></v-autocomplete>
              </v-col>
              <v-col sm="3">
                <v-autocomplete ref="state" v-model="form.city" :items="Cities" label="City" prepend-icon="mdi-calendar"
                  @input="getData" clearable :error-messages="errors.city"></v-autocomplete>
              </v-col>
              <v-col sm="3">
                <v-menu v-model="from_date_menu" :close-on-content-click="false" :nudge-right="40"
                  transition="scale-transition" offset-y min-width="auto">
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field v-model="form.from_date" label="From date" prepend-icon="mdi-calendar" readonly
                      v-bind="attrs" v-on="on" clearable @input="getData()"
                      @click:clear="form.from_date = null"></v-text-field>
                  </template>
                  <v-date-picker v-model="form.from_date" @input="getData()"
                    @change="from_date_menu = false"></v-date-picker>
                </v-menu>
              </v-col>
              <v-col sm="3">
                <v-menu v-model="to_date_menu" :close-on-content-click="false" :nudge-right="40"
                  transition="scale-transition" offset-y min-width="auto">
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field v-model="form.to_date" label="To date" prepend-icon="mdi-calendar" readonly
                      v-bind="attrs" v-on="on" clearable @input="getData()"
                      @click:clear="form.to_date = null"></v-text-field>
                  </template>
                  <v-date-picker v-model="form.to_date" @input="getData()"
                    @change="to_date_menu = false"></v-date-picker>
                </v-menu>
              </v-col>

            </v-row>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12 grid-margin stretch-card">
        <div class="card">
          <div class="card-body">
            <v-row>
              <v-col cols="4">
                <v-autocomplete ref="Technician" v-model="form.assigned_to_id" :items="Technicians"
                  label="Select Technician" chips density="comfortable" prepend-icon="mdi-crosshairs-gps" clearable
                  @input="assignSelectedTickets"></v-autocomplete>
              </v-col>
              <v-col cols="4">
                <v-switch v-model="toggleValue" inset :label="`Technicians: ${this.toggleValue == true ? 'Assigned' : 'Un-assigned'
            }`" @change="handleSwitchChange"></v-switch>
              </v-col>
            </v-row>
            <div>
              <div class="wrapper1" ref="wrapper1" @scroll="handleScroll1">
                <div class="div1"></div>
              </div>
              <div class="wrapper2">
                <div class="div2">
                  <div class="table-responsive" ref="wrapper2" @scroll="handleScroll2">
                    <table id="dataTableExample" class="table table-hover">
                      <thead>
                        <tr>
                          <th>Sr No</th>
                          <th>Ticket Number</th>
                          <th>Select name</th>
                          <th>Technician Name</th>
                          <th>Date</th>
                          <th>Customer Type</th>
                          <th>Customer Name</th>
                          <th>Title</th>
                          <th>Priority</th>
                          <th>Product</th>
                          <th style="max-width: 85px;">Issue List</th>
                          <th>Status Type</th>
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        <!-- Table Loader -->
                        <loading-table :isLoading="isLoading" cols="12
                        "></loading-table>
                        <!-- If No Data Received-->
                        <tr v-if="tickets.length == 0">
                          <td colspan="12" class="text-center" style="color: grey">
                            No Data Found
                          </td>
                        </tr>
                        <!-- If data Received -->
                        <tr v-for="(ticket, at) in tickets" :key="`ticket${at}`">
                          <td>{{ serialNoStarting + at + 1 }}</td>
                          <td>
                            {{
            ticket.ticket_id
              ? ticket.ticket_id
              : ""
          }}
                          </td>
                          <td>
                            <input type="checkbox" v-if="!ticket.assigned_to_id" v-model="selectedTickets"
                              :value="ticket.id" />
                          </td>
                          <td>
                            {{ ticket.assigned_to ? ticket.assigned_to.name : "" }}
                          </td>
                          <td>
                            {{ ticket.created_at | ddmmyyyy }}
                          </td>
                          <td>
                            {{ ticket.customer ? ticket.customer.customer_type : "" }}
                          </td>
                          <td>
                            {{ ticket.customer ? ticket.customer.name : "" }}
                          </td>
                          <td class="wrap_2">{{ ticket.title }}</td>
                          <td>
                            <span class="badge danger" v-if="ticket.priority_type_id === 1">
                              High Priority
                            </span>
                            <span class="badge warning" v-else-if="ticket.priority_type_id === 2">
                              Medium Priority
                            </span>
                            <span class="badge primary" v-else> Low Priority </span>
                          </td>
                          <td class="wrap_2">
                            {{ ticket.product ? ticket.product.name : "" }}
                          </td>
                          <td class="wrap_2">

                            <ul>
                              <li v-for="issues in ticket.ticket_list_issues" :key="issues.id">
                                {{ issues.product_issue.title ? issues.product_issue.title : "" }}
                              </li>
                            </ul>
                          </td>
                          <td>
                            {{ ticket.status_type }}
                          </td>
                          <td>
                            <v-btn class="mx-2" color="primary" fab x-small :to="`/tickets/${ticket.id}`">
                              <v-icon dark> mdi-pencil </v-icon>
                            </v-btn>
                            <v-tooltip bottom>
                              <template v-slot:activator="{ on, attrs }">
                                <v-btn v-bind="attrs" v-on="on" class="mx-2" color="info" fab x-small
                                  :to="`/tickets-summary/${ticket.id}`">
                                  <v-icon dark> mdi-eye </v-icon>
                                </v-btn>
                              </template>
                              <span>View Summary</span>
                            </v-tooltip>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    <div style="float: right">
                      <v-pagination pagination v-model="page" :count="count" :length="paginationLength"
                        prev-icon="mdi-menu-left" next-icon="mdi-menu-right" :total-visible="totalVisible">
                        <template v-slot:prev="{ on }">
                          <v-btn @click="goToPage(page - 1)" text icon v-if="page > 1" v-on="on">Previous</v-btn>
                        </template>
                        <template v-slot:next="{ on }">
                          <v-btn @click="goToPage(page + 1)" text icon v-if="page < paginationLength"
                            v-on="on">Next</v-btn>
                        </template>
                      </v-pagination>
                    </div>
                    <!-- /Table -->
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- partial -->
</template>

<script type="text/javascript">
import axios from "axios";

export default {
  name: "user",
  watch: {
    page(newVal) {
      this.getData(newVal);
    },
  },
  data() {
    return {
      totalVisible: 5,
      toggleValue: false,
      roleName: "",
      form: {
        to_date: new Date().toISOString().substr(0, 10),

      },
      tickets: [],
      States: [],
      Cities: [],
      showAssigned: false,
      search_keyword: "",
      selectedStatus: "",
      Technicians: [],
      statusTypes: [
        { text: "Ongoing", value: "Ongoing" },
        { text: "Pending", value: "Pending" },
        { text: "Completed", value: "Completed" },
      ],
      count: 0,
      paginationLength: 0,
      serialNoStarting: 1,
      isLoading: false,
      isExcelloading: false,
      page: 1,
      selectAll: false,
      selectedTickets: [],
      from_date: "",
      from_date_menu: false,
      to_date: "",
      to_date_menu: false,
    };
  },

  created() {
    const statusType = this.$route.query.status_type;
    console.log("Status Type:", statusType);
    this.form.status_type = statusType;
    this.getData();
  },
  mounted() {
    this.roleName = this.user.roles[0].name;
    this.getData();
    this.getMasters();
    this.path = this.mediaUrl;
  },
  methods: {
    handleSwitchChange() {
      // Use the current value of toggleValue (true or false)
      console.log("Switch changed. Current value:", this.toggleValue);
      this.getData();
    },
    async getMasters() {
      let masters = await axios.get("tickets/masters?values=limited");
      masters = masters.data;
      //roles
      masters.users.forEach((user) => {
        const roleWithId3 = user.roles.find((role) => role.id === 4);
        if (roleWithId3) {
          this.Technicians.push({
            id: user.id,
            text: user.name,
            value: user.id,
          });
        }
      });
      masters.States.forEach((state) => {
        this.States.push({
          id: state.id,
          text: state.title,
          value: state.title,
        });
      });
      masters.City.forEach((city) => {
        this.Cities.push({
          id: city.id,
          text: city.title,
          value: city.title,
        });
      });
    },

    async generateExcel() {
      this.isExcelloading = true;
      this.search_keyword = this.search_keyword ? this.search_keyword : "";
      this.form.assigned_to_id = this.form.assigned_to_id
        ? this.form.assigned_to_id
        : "";
      this.form.status_type = this.form.status_type
        ? this.form.status_type
        : "";
      this.form.city = this.form.city ? this.form.city : "";
      this.form.state = this.form.state ? this.form.state : "";
      this.form.month = this.form.month ? this.form.month : "";
      this.form.from_date = this.form.from_date ? this.form.from_date : "";
      this.form.to_date = this.form.to_date ? this.form.to_date : "";
      this.form.year = this.form.year ? this.form.year : "";

      let query =
        "&search_keyword=" +
        this.search_keyword +
        "&assigned_to_id=" +
        this.form.assigned_to_id +
        "&status_type=" +
        this.form.status_type +
        "&city=" +
        this.form.city +
        "&state=" +
        this.form.state +
        "&month=" +
        this.form.month +
        "&year=" +
        this.form.year +
        "&assigned_tickets=" +
        this.toggleValue +
        "&ticket_created_from_date=" +
        this.form.from_date +
        "&ticket_created_to_date=" +
        this.form.to_date;

      try {
        let response = await axios.get(`ticket_exports?${query}`);
        console.log(response.data.data);
        if (response && response.data && response.data.data) {
          window.open(`${response.data.data}`, "_blank");
        } else {
          console.error("Invalid response data:", response.data);
        }
      } catch (error) {
        console.error("Error fetching Excel data:", error);
      }
      this.isExcelloading = false;
    },
    async getData() {
      this.isLoading = true;
      this.search_keyword = this.search_keyword ? this.search_keyword : "";
      this.form.assigned_to_id = this.form.assigned_to_id
        ? this.form.assigned_to_id
        : "";
      this.form.status_type = this.form.status_type
        ? this.form.status_type
        : "";
      this.form.city = this.form.city ? this.form.city : "";
      this.form.state = this.form.state ? this.form.state : "";
      this.form.month = this.form.month ? this.form.month : "";
      this.form.from_date = this.form.from_date ? this.form.from_date : "";
      this.form.to_date = this.form.to_date ? this.form.to_date : "";
      this.form.year = this.form.year ? this.form.year : "";
      let query =
        "page=" +
        this.page +
        "&rowsPerPage=" +
        this.rowsPerPage +
        "&search_keyword=" +
        this.search_keyword +
        "&assigned_to_id=" +
        this.form.assigned_to_id +
        "&status_type=" +
        this.form.status_type +
        "&city=" +
        this.form.city +
        "&state=" +
        this.form.state +
        "&month=" +
        this.form.month +
        "&year=" +
        this.form.year +
        "&assigned_tickets=" +
        this.toggleValue +
        "&ticket_created_from_date=" +
        this.form.from_date +
        "&ticket_created_to_date=" +
        this.form.to_date;
      let tickets = await axios.get(`/tickets?${query}`);
      this.tickets = tickets.data.data;
      this.count = tickets.data.count;
      this.serialNoStarting = (this.page - 1) * this.rowsPerPage;
      this.paginationLength = Math.ceil(this.count / this.rowsPerPage);
      this.isLoading = false;
    },
    // handleScroll1() {
    //   this.$refs.wrapper2.scrollLeft = this.$refs.wrapper1.scrollLeft;
    // },
    // handleScroll2() {
    // this.$refs.wrapper1.scrollLeft = this.$refs.wrapper2.scrollLeft;
    // },
    goToPage(newPage) {
      this.page = newPage;
      console.log(this.page)
      this.getData();
    },
    selectAllItems() {
      this.selectedTickets = this.selectAll
        ? this.tickets.map((ticket) => ticket.id)
        : [];
      console.log(this.selectedTickets);
    },
    getPriorityLabel(priorityTypeId) {
      if (priorityTypeId === 1) {
        return "High Priority";
      } else if (priorityTypeId === 2) {
        return "Medium Priority";
      } else if (priorityTypeId === 3) {
        return "Low Priority";
      } else {
        return "";
      }
    },
    async assignSelectedTickets() {
      if (this.selectedTickets.length === 0 || !this.form.assigned_to_id) {
        return;
      }
      const technicianId = this.form.assigned_to_id;
      try {
        await axios.post("/update-ticket-technician", {
          technicianId,
          ticketIds: this.selectedTickets,
        });
        this.selectedTickets = [];
        this.form.assigned_to_id = null;
        this.getData();
      } catch (error) {
        console.error("Ticket assignment update failed:", error);
      }
    },
  },
};
</script>
