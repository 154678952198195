<template>
  <div class="page-wrapper full-page">
    <div class="page-content d-flex align-items-center justify-content-center">
      <div class="row w-100 mx-0 auth-page">
        <div class="col-md-8 col-xl-6 mx-auto">
          <div class="card">
            <div class="row forget-password">
              <div class="col-md-6 pe-md-0">
                <div class="auth-side-wrapper"></div>
              </div>
              <div class="col-md-6 ps-md-0">
                <div class="auth-form-wrapper px-4 py-5">
                  <a href="#" class="noble-ui-logo d-block mb-2"><span>FSS Forget Passsword</span></a>
                  <h5 class="text-muted fw-normal mb-4">
                    Welcome back! To forget your passsword enter registered email.
                  </h5>
                  <div class="mb-3" v-if="EmailId">
                    <label for="userEmail" class="form-label">Email address</label>
                    <input type="email" class="form-control" placeholder="Email" v-model="form.email" />
                    <span v-if="Email_not_found" class="error-block" style="color: red;">Email not found</span>
                    <span v-if="Emailsend" class="error-block " style="color: green;">Email Send</span>
                  </div>
                  <div class="mb-3" v-if="Emailsend">
                    <label for="otp" class="form-label">Enter OTP</label>
                    <input type="text" class="form-control" placeholder="OTP" v-model="form.otp" />
                    <span v-if="Otperror" style="color: red;">invalid OTP</span>
                  </div>
                  <div class="mb-3" v-if="Otpmatch">
                    <label for="otp" class="form-label">Enter New Password</label>
                    <input :class="{ 'border-danger': PasswordsDoNotMatchError }" type="text" class="form-control"
                      placeholder="Enter password" v-model="form.password" />
                    <span v-if="PasswordsDoNotMatchError" style="color: red ;">Password doesn't match</span>
                  </div>
                  <div class="mb-3" v-if="Otpmatch">
                    <label for="otp" class="form-label">Re-typepassword</label>
                    <input :class="{ 'border-danger': PasswordsDoNotMatchError }" type="text" class="form-control"
                      placeholder="Re-TypePassword" v-model="form.soft_password" />
                    <span v-if="PasswordsDoNotMatchError" style="color: red ;">Password doesn't match</span>
                    <span v-if="PasswordChange" style="color: green;">Password changed redirecting....</span>

                  </div>
                  <!-- <span v-if="PasswordsDoNotMatchError" style="color: red;">Password doesn't match</span> -->
                  <button class="btn btn-info btn-rounded" v-if="isLoading">
                    Loading...
                  </button>
                  <button @click="forget_password" v-else class="btn btn-primary me-2 mb-2 mb-md-0 text-white">
                    proceed
                  </button>

                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import axios from "axios";
import { mapActions } from "vuex";

export default {
  name: "ForgotPassword",
  data() {
    return {
      form: {
        email: "",
      },
      isLoading: false,
      EmailId: true,
      Email_not_found: false,
      Emailsend: false,
      Otpmatch: false,
      PasswordsDoNotMatchError: false,
      Otperror: false,
      PasswordChange: false,
    };
  },
  created() {
    if (this.$route.query.user) {
      this.form = {
        email: this.$route.query.user,
      };
      this.forget_password();
    }
  },
  methods: {
    ...mapActions({
      Forgetpassword: "auth/Forgetpassword",
    }),
    async forget_password() {
      console.log(1243);
      this.isLoading = true;
      try {
        // if (this.Emailsend == false) {

        let response = await this.Forgetpassword(this.form);

        if (response.data === 'MATCH') {
          console.log('recieved')
          this.Otpmatch = true
          this.Emailsend = false
          this.EmailId = false
          this.isLoading = false
        } else if (response.data === 'NOT MATCH') {
          this.Otperror = true
          this.isLoading = false
        }
        else if (response.data === 'CHANGED') {
          console.log('recieved')
          this.isLoading = false;
          this.PasswordsDoNotMatchError = false
          this.PasswordChange = true
          setTimeout(() => {
            this.$router.replace({
              name: "Home",
            });
          }, 3000);
        } else if (response.data === 'NOT CHANGED') {
          this.PasswordsDoNotMatchError = true
          this.isLoading = false;
        }
        else {
          this.Emailsend = true;
          this.Email_not_found = false;
          // console.log(response);
          console.log(response.data);
          // console.log(response.data.otp);
          this.isLoading = false;
        }

      } catch (error) {
        console.error("Error while processing forget password:", error);
        this.Email_not_found = true;
        this.isLoading = false;
      }


    },
  },
};
</script>

<style>
.forget-password {
  min-height: 361px !important;
  /* overflow: auto; */
  /* Add this line to enable scrolling if content overflows */
}

.border-danger {
  border-color: #ff000073 !important;
}

.mb-3 {
  position: relative;
}

.mb-3 .border-danger {
  animation: shake 0.5s;
  border-color: red;
}
</style>