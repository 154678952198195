<template>
  <!-- partial -->
  <div class="page-content">
    <div class="row">
      <div class="col-lg-7 col-md-7 col-sm-6 col-xs-12">
        <h5 class="txt-dark">
          <back-button link="/customers"></back-button>
          Customer Products &nbsp;
          <save-button @click="save"></save-button>
          <small v-if="isSaving">Saving...</small>
        </h5>
      </div>
      <!-- Breadcrumb -->
      <div class="col-lg-5 col-sm-6 col-md-5 col-xs-12">
        <ol class="breadcrumb breadcrumb-line" style="float: right">
          <li class="breadcrumb-item">
            <router-link :to="`/`">Dashboard</router-link>
          </li>
          <li class="breadcrumb-item">Planned Maintenance</li>
          <li class="breadcrumb-item">Job Categories</li>
          <li class="breadcrumb-item active" aria-current="page">List</li>
        </ol>
      </div>
    </div>
    <!-- /Breadcrumb -->
    <!-- Search & Filtering  -->
    <div class="row">
      <div class="col-md-12 grid-margin stretch-card">
        <div class="card">
          <div class="card-body">
            <div class="col-lg-8 col-md-8 col-sm-6 col-xs-12">
              <input
                type="text"
                class="form-control"
                placeholder="Search..."
                v-model="searchKeyword"
                @keydown.enter="search"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Search & Filtering  -->
    <!-- Table -->
    <div class="row">
      <div class="col-md-12 grid-margin stretch-card">
        <div class="card">
          <div class="card-body">
            <!-- Export To Excel -->
            <div class="row">
              <div class="col-md-8"></div>
              <div class="col-md-4">
                <div style="float: right">
                  <!-- <a
                    href="#"
                    class="btn btn-primary"
                    v-if="ExportValueLists.length == 0"
                    @click="exportToExcel"
                  > -->
                  <!-- <span v-if="isExcelloading">
                      <div class="d-flex align-items-center">
                        <strong>Generating Excel</strong>
                        &nbsp;
                        <div
                          class="
                            spinner-border
                            text-light
                            spinner-border-sm
                            ms-auto
                          "
                          role="status"
                          aria-hidden="true"
                        ></div>
                      </div>
                    </span> -->
                  <!-- <span v-else>Generate Excel</span>
                  </a> -->
                  <!-- <download-excel
                    :title="title"
                    v-else
                    class="btn btn-success"
                    :data="value_lists_excel"
                    :fields="excel_fields"
                    worksheet="Job Categories"
                    name="Job Category.xls"
                    >Download Excel
                  </download-excel> -->
                </div>
              </div>
            </div>
            <br />
            <!-- End Export to Excel -->
            <div class="table-responsive">
              <table id="dataTableExample" class="table table-hover">
                <thead>
                  <tr>
                    <th>Sr. No.</th>
                    <th>Product Name</th>
                    <th>Is Active</th>
                    <th class="text-center">Action</th>
                  </tr>
                </thead>
                <tbody>
                  <loading-table
                    :isLoading="isLoading"
                    cols="12"
                  ></loading-table>
                  <tr v-if="customerProducts.length == 0">
                    <td colspan="12" class="text-center">No data Found</td>
                  </tr>
                  <tr
                    v-for="(customerProduct, ar) in customerProducts"
                    :key="`customerProduct${ar}`"
                  >
                    <td>{{ ar + 1 }}.</td>
                    <td>
                      <v-autocomplete
                        v-model="customerProduct.product_id"
                        :items="products"
                        placeholder="Select Product"
                        prepend-icon="mdi-snowflake"
                        clearable
                      ></v-autocomplete>
                    </td>
                    <td>
                      <div class="form-check form-switch mb-2">
                        <input
                          type="checkbox"
                          v-model="customerProduct.is_active"
                          class="form-check-input"
                          id="formSwitch1"
                        />
                      </div>
                    </td>
                    <td>
                      <div class="text-center">
                        <button
                          class="btn btn-danger btn-sm"
                          @click="deleteValueList(customerProduct)"
                          v-if="!customerProduct.id"
                        >
                          Delete
                        </button>
                        <button
                          class="btn btn-danger btn-sm"
                          @click="delete_data(customerProduct.id)"
                          v-else
                        >
                          Delete
                        </button>
                      </div>
                    </td>
                  </tr>
                  <tr v-if="customer_id">
                    <td
                      colspan="12"
                      align="center"
                      style="
                        background-color: #6571ff;
                        color: white;
                        border-radius: 10px;
                        box-shadow: none;
                      "
                      @click="addEmptyValueList()"
                    >
                      Add New Row
                    </td>
                  </tr>
                </tbody>
              </table>
              <!-- /Table -->
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- partial -->
</template>

<script type="text/javascript">
import axios from "axios";

export default {
  name: "Values",
  data() {
    return {
      customer_id: "",
      count: 0,
      serialNoStarting: 1,
      isLoading: false,
      isSaving: false,
      isExcelloading: false,
      searchKeyword: "",
      customerProducts: [],
      products: [],
      value_lists_excel: [],
      excel_fields: {
        "Sr No": "sr_no",
        NAME: "name",
        "IS ACTIVE": "is_active",
      },
      ExportValueLists: [],
    };
  },
  mounted() {
    this.getData();
    this.getMasters();
  },
  methods: {
    async getMasters() {
      let masters = await axios.get("users/masters");
      masters = masters.data;
      masters.products.forEach((product) => {
        this.products.push({
          id: product.id,
          text: product.name,
          value: product.id,
        });
      });
    },
    // Get Data
    async getData() {
      this.ExportValueLists = [];
      this.isLoading = true;

      try {
        this.customer_id = this.$route.params.customerId;
        let customerProducts = await axios.get(
          `/customers/${this.customer_id}/customer_products`
        );

        this.customerProducts = customerProducts.data.data;
        this.count = customerProducts.data.count;
        console.log(this.customerProducts);
      } catch (error) {
        console.error(error);
      }
      this.isLoading = false;
    },
    // Adding an Emplty Row
    addEmptyValueList() {
      this.customerProducts.push({
        customer_id: this.customer_id,
        company_id: this.company.id,
        product_id: "",
        code: "",
        is_active: 1,
      });
    },
    // Delete an Empty Row
    deleteValueList(customerProduct) {
      this.customerProducts = this.customerProducts.filter(
        (ar) => ar != customerProduct
      );
    },
    // Save
    async save() {
      if (this.customerProducts.length > 0) {
        this.isSaving = true;
        let payload = {
          datas: this.customerProducts,
        };
        console.log(payload);
        let response = await axios.post(
          `/customers/${this.customer_id}/customer_product_lists_multiple`,
          payload
        );
        this.$router.push("/customers");

        this.customerProducts = response.data.data;
        this.isSaving = false;
      }
    },
    // Search
    async search() {
      this.isLoading = true;
      let page = 1;
      let columns = ["name"];

      let valueLists = await axios.get(
        `/searchData?table_name=value_lists&columns=${columns}&searchKeyword=${this.searchKeyword}&value=${this.value_id}&page=${page}&rowsPerPage=${this.rowsPerPage}`
      );
      this.valueLists = valueLists.data.data;
      this.count = valueLists.data.count;
      this.serialNoStarting = (page - 1) * this.rowsPerPage;
      this.isLoading = false;
      this.clearData();
    },
    // Delete from DB
    async delete_data(id) {
      let check = confirm("Are you sure you want to delete the data");
      if (check) {
        try {
          this.isLoading = true;
          await axios.delete(
            `customers/${this.customer_id}/customer_products/${id}`
          );
          this.isLoading = false;
          this.getData();
        } catch (e) {
          this.isLoading = false;
        }
      }
    },
    // Clear Data for latest Filter Excel Generation
    clearData() {
      this.ExportValueLists = [];
      console.log("Cleared Data");
    },
    // Export to Excel
    async exportToExcel() {
      this.isExcelloading = true;
      this.title = "Job Categories";
      this.value_lists_excel = [];
      let ExportValueLists = [];
      let columns = ["name"];
      ExportValueLists = await axios.get(
        `/searchData?table_name=value_lists&columns=${columns}&searchKeyword=${this.searchKeyword}&value=${this.value_id}`
        // `/values/${this.value_id}/value_lists`
      );
      this.ExportValueLists = ExportValueLists.data.data;
      let sr_no = 1;
      this.ExportValueLists.forEach((valuelist) => {
        this.value_lists_excel.push({
          sr_no: sr_no,
          name: valuelist.name,
          is_active: valuelist.is_active ? "YES" : "NO",
        });
        sr_no++;
      });
      this.isExcelloading = false;
    },
  },
};
</script>
