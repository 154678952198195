<template>
  <!-- partial -->
  <div class="page-content">
    <div class="row">
      <div class="col-lg-7 col-md-7 col-sm-6 col-xs-12">
        <h5 class="txt-dark">
          My Profile &nbsp;
          <!-- <add-button link="/users/create"></add-button> -->
        </h5>
      </div>
      <!-- Breadcrumb -->
      <div class="col-lg-5 col-sm-6 col-md-5 col-xs-12">
        <ol class="breadcrumb breadcrumb-line" style="float: right">
          <li class="breadcrumb-item">
            <router-link :to="`/dashboards`">Dashboard</router-link>
          </li>
          <li class="breadcrumb-item">My Profile</li>
        </ol>
      </div>
    </div>
    <!-- /Breadcrumb -->

    <!-- /Breadcrumb -->
    <!-- Search & Filtering  -->

    <!-- Search & Filtering  -->
    <!-- Table -->
    <div class="row">
      <div class="col-md-4">
        <label class="col-form-label">Name</label>
        <div class="col-sm-12">
          <v-text-field v-model="form.name" prepend-inner-icon="mdi mdi-account" outlined clearable></v-text-field>
        </div>
      </div>
      <div class="col-md-4">
        <label class="col-form-label">Email</label>
        <div class="col-sm-12">
          <v-text-field v-model="form.email" prepend-inner-icon="mdi-email" outlined readonly></v-text-field>
        </div>
      </div>
      <div class="col-md-4">
        <label class="col-form-label">Phone Number</label>
        <div class="col-sm-12">
          <v-text-field v-model="form.phone" prepend-inner-icon="mdi-phone" outlined readonly></v-text-field>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-4">
        <label class="col-form-label">Address</label>
        <div class="col-sm-12">
          <v-text-field v-model="form.address" prepend-inner-icon="mdi-map-marker" outlined clearable></v-text-field>
        </div>
      </div>
      <div class="col-md-4">
        <label class="col-form-label">Employee Code</label>
        <div class="col-sm-12">
          <v-text-field v-model="form.employee_code" prepend-inner-icon="mdi-account-star" outlined
            readonly></v-text-field>
        </div>
      </div>
      <div class="col-md-4">
        <label class="col-form-label">Date Of Birth</label>

        <v-menu v-model="dob_menu" :close-on-content-click="false" :nudge-right="40" transition="scale-transition"
          offset-y min-width="auto">
          <template v-slot:activator="{ on, attrs }">
            <v-text-field v-model="form.dob" label="Date Of Birth" prepend-icon="mdi-calendar-range" readonly
              v-bind="attrs" v-on="on" clearable @click:clear="dob = null"></v-text-field>
          </template>
          <v-date-picker :id="`dob`" v-model="form.dob" @change="dob_menu = false"></v-date-picker>
        </v-menu>
      </div>
    </div>
    <div class="row">

      <div class="col-md-4">
        <label class="col-form-label">Role</label>
        <div class="col-sm-12">
          <v-text-field v-model="form.roles[0].name" prepend-inner-icon="mdi-account-star" outlined
            readonly></v-text-field>
        </div>
      </div>

      <div class="col-md-4">
        <label class="col-form-label">Password</label>
        <div class="col-sm-12">
          <v-text-field v-model="form.soft_password" prepend-inner-icon="mdi-account-star" outlined></v-text-field>
        </div>
      </div>
      <div class="col-md-4">
        <label class="col-form-label">Profile Image</label>
        <div class="col-sm-12">
          <input type="file" id="file" name="file" ref="file" accept="image/*" />
          <br />
          <v-btn v-if="form.image_path != null" x-small class="reset_image my-1" color="red" dark
            @click="($refs.file.value = null), (form.image_path = null)">Remove</v-btn>
        </div>
      </div>
    </div>
    <div class="form-group mb-0">
      <submit-button :isLoading="isLoading" @submit="update" text="Update" style="float: right"></submit-button>
    </div>
  </div>

  <!-- partial -->
</template>

<script type="text/javascript">

import axios from "axios";

export default {
  name: "user",
  data() {
    return {
      form: {
        role_id: "",
        active: "",
      },
      users: [],
      roles: [],
      roleId: "",
      //   page: 1,
      count: 0,
      paginationLength: 0,
      serialNoStarting: 1,
      search_keyword: "",
      statuses: [
        {
          id: 0,
          text: "Select Active Status",
          value: "",
        },
        { id: "ACTIVE", text: "ACTIVE", value: "1" },
        { id: "INACTIVE", text: "INACTIVE", value: "0" },
      ],
      isLoading: false,
      isExcelloading: false,
    };
  },
  mounted() {
    this.getData();
    this.getMasters();
    this.path = this.mediaUrl;
    this.roleId = this.user.roles[0].id;
  },
  methods: {
    async getMasters() {
      let masters = await axios.get("users/masters");
      masters = masters.data;
      //roles
      masters.roles.forEach((role) => {
        this.roles.push({
          id: role.id,
          text: role.name,
          value: role.id,
        });
      });
    },
    async generateExcel() {
      this.isExcelloading = true;
      try {
        let response = await axios.get(`usersExports?`);
        // console.log(response.data.data);
        if (response && response.data && response.data.data) {
          window.open(`${response.data.data}`, "_blank");
        } else {
          console.error("Invalid response data:", response.data);
        }
      } catch (error) {
        console.error("Error fetching Excel data:", error);
      }
      this.isExcelloading = false;
    },
    // async getData() {
    async getData() {
      this.isLoading = true;

      let userId = this.user.id;

      let roleId = this.user.roles[0].id;
      if (roleId == 1 || roleId == 2 || roleId == 3) {
        let form = await axios.get(
          `/users/${userId}`
        );
        this.form = form.data.data;
        this.count = form.data.count;
        this.isLoading = false;

      }
    },
    async update() {
      try {
        this.isLoading = true;
        let userId = this.user.id;

        //  let form = await axios.post(`/users`, this.form);
        //  console.log(form);
        await axios.patch(`/users/${userId}`, this.form);
        // this.form = form.data.data;
        await this.handleFileUpload();

        this.isLoading = false;
        this.$router.push("/");
      } catch (e) {
        console.log(e);
        this.isLoading = false;
      }
    },
    async handleFileUpload() {
      this.isLoading = true;

      const userid = this.user.id;
      console.log(userid);
      this.isLoading = true;

      let formData = new FormData();
      formData.append("id", userid);
      console.log(formData);
      let image_path = this.$refs.file?.files[0];
      formData.append("image_path", image_path);
      await axios
        .post("upload_user_image", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .catch(function () {
          console.log("FAILURE!!");
        });
      this.isLoading = false;
    },
  },
};
</script>
