<template>
  <div class="page-content">
    <div class="row">
      <div class="col-lg-8 col-md-8 col-sm-6 col-xs-12">
        <h5 class="txt-dark">
          <back-button link="/users"></back-button>
          Add User
        </h5>
      </div>
      <!-- Breadcrumb -->
      <div class="col-lg-4 col-sm-6 col-md-4 col-xs-12">
        <ol class="breadcrumb breadcrumb-line" style="float: right">
          <li class="breadcrumb-item">
            <router-link :to="`/`">Dashboard</router-link>
          </li>
          <li class="breadcrumb-item">
            <router-link :to="`users`">Users</router-link>
          </li>
          <li class="breadcrumb-item active" aria-current="page">Add</li>
        </ol>
      </div>
    </div>
    <div class="row">
      <div class="col-md-10 grid-margin stretch-card">
        <div class="card">
          <div class="card-body">
            <div class="row">
              <div class="col-md-4">
                <label class="col-form-label">Name</label>
                <div class="col-sm-12">
                  <v-text-field v-model="form.name" prepend-inner-icon="mdi mdi-account" outlined clearable
                    :error-messages="errors.name"></v-text-field>
                </div>
              </div>
              <div class="col-md-4">
                <label class="col-form-label">Email</label>
                <div class="col-sm-12">
                  <v-text-field v-model="form.email" prepend-inner-icon="mdi-email" outlined clearable
                    :error-messages="errors.email"></v-text-field>
                </div>
              </div>
              <div class="col-md-4">
                <label class="col-form-label">Phone Number</label>
                <div class="col-sm-12">
                  <v-text-field v-model="form.phone" prepend-inner-icon="mdi-phone" outlined clearable
                    :error-messages="errors.phone"></v-text-field>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-4">
                <label class="col-form-label">Address</label>
                <div class="col-sm-12">
                  <v-text-field v-model="form.address" prepend-inner-icon="mdi-map-marker" outlined
                    clearable></v-text-field>
                </div>
              </div>
              <div class="col-md-4">
                <label class="col-form-label">Employee Code</label>
                <div class="col-sm-12">
                  <v-text-field v-model="form.employee_code" prepend-inner-icon="mdi-account-star" outlined
                    clearable></v-text-field>
                </div>
              </div>
              <div class="col-md-4">
                <label class="col-form-label">Date Of Joining</label>

                <v-menu v-model="doj_menu" :close-on-content-click="false" :nudge-right="40" transition="scale-transition"
                  offset-y min-width="auto">
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field v-model="form.doj" label="Date Of Joining" prepend-icon="mdi-calendar" readonly
                      v-bind="attrs" v-on="on" clearable @click:clear="doj = null"></v-text-field>
                  </template>
                  <v-date-picker :id="`doj`" v-model="form.doj" @change="doj_menu = false"></v-date-picker>
                </v-menu>
              </div>
            </div>
            <div class="row">
              <div class="col-md-4">
                <label class="col-form-label">Date Of Birth</label>

                <v-menu v-model="dob_menu" :close-on-content-click="false" :nudge-right="40" transition="scale-transition"
                  offset-y min-width="auto">
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field v-model="form.dob" label="Date Of Birth" prepend-icon="mdi-calendar-range" readonly
                      v-bind="attrs" v-on="on" clearable @click:clear="dob = null"></v-text-field>
                  </template>
                  <v-date-picker :id="`dob`" v-model="form.dob" @change="dob_menu = false"></v-date-picker>
                </v-menu>
              </div>
              <div class="col-md-4">
                <label class="col-form-label">Role</label>
                <v-autocomplete ref="role" v-model="form.role_id" :items="roles" placeholder="Select Role" clearable
                  prepend-icon="mdi-account" :error-messages="errors.role_id"></v-autocomplete>
              </div>

              <!-- <div class="col-md-4" v-if="form.role_id == '4'">
                <label class="col-form-label">Imei No</label>
                <div class="col-sm-12">
                  <v-text-field
                    v-model="form.imei_no"
                    prepend-inner-icon="mdi-cellphone-settings"
                    outlined
                    clearable
                  ></v-text-field>
                </div> -->
              <!-- </div> -->
              <div class="col-md-4">
                <label class="col-form-label">Profile Image</label>
                <div class="col-sm-12">
                  <input type="file" id="file_1" name="file_1" ref="file_1" @change="FormImagepath" accept="image/*" />
                  <br />
                  <v-btn x-small class="reset_image my-1" color="red" dark
                    @click="($refs.file.value = null), (form.image_path = null)">Remove</v-btn>
                </div>
                <div class="col-sm-6">
                  <div id="preview" v-if="image_path">
                    <label for="">Image 2 Preview</label>
                    <br />
                    <img :src="image_path" style="height: 20%; width: 20% !important" />
                  </div>
                </div>
              </div>
              <v-col sm="4">
                <v-switch v-model="form.active" inset :label="`Is Active: ${form.active.toString() == 'true' ? 'YES' : 'NO'
                  }`"></v-switch>
              </v-col>
            </div>
            <br />
            <div class="form-group mb-0">
              <submit-button :isLoading="isLoading" @submit="save" text="Save" style="float: right"></submit-button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script type="text/javascript">
import axios from "axios";

export default {
  name: "UserCreate",
  data() {
    return {
      form: {
        active: true,
      },
      roles: [],
      mims: [],
      products: [],
      dob_menu: false,
      doj_menu: false,
      isLoading: false,
      customer_types: [
        { text: "Private", value: "Private" },
        { text: "Commercial", value: "Commercial" },
      ],
    };
  },
  mounted() {
    this.roleName = this.user.roles[0].name;
    this.getMasters();
  },
  methods: {
    FormImagepath(e) {
      const file = e.target.files[0];
      this.image_path = URL.createObjectURL(file);
    },
    async getMasters() {
      let masters = await axios.get("users/masters");
      masters = masters.data;
      //roles
      masters.roles.forEach((role) => {
        if (this.roleName == "ADMIN" || this.roleName == "MIM") {
          let roles = [];
          roles = masters.roles.find((role) => role.name == "TECHNICIAN");
          this.roles.push({
            id: roles.id,
            text: roles.name,
            value: roles.id,
          });
        } else {
          this.roles.push({
            id: role.id,
            text: role.name,
            value: role.id,
          });
        }
      });
      masters.products.forEach((product) => {
        this.products.push({
          id: product.id,
          text: product.name,
          value: product.id,
        });
      });
      // console.log(masters);
      //user
      masters.users.forEach((user) => {
        console.log(user);
        const roleWithId3 = user.roles.find((role) => role.id === 3);
        if (roleWithId3) {
          this.mims.push({
            id: user.id,
            text: user.name,
            value: user.id,
          });
        }
      });
    },
    async save() {
      try {
        //  this.form.company = this.company.id;
        this.isLoading = true;
        let userData = await axios.post("/users", this.form);
        this.userData = userData.data.data;
        await this.handleFileUpload();
        this.isLoading = false;
        this.$router.push("/users");
      } catch (e) {
        this.isLoading = false;
      }
    },
    async handleFileUpload() {
      this.isLoading = true;
      const userid = this.userData.id;
      console.log(userid);
      this.isLoading = true;

      let formData = new FormData();
      formData.append("id", userid);
      console.log(formData);
      let image_path = this.$refs.file_1?.files[0];
      formData.append("image_path", image_path);
      await axios
        .post("upload_user_image", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .catch(function () {
          console.log("FAILURE!!");
        });
      this.isLoading = false;
    },

  },
};
</script>
<style scoped></style>
