<template>
  <v-card>
    <v-card-title class="headline">Change Password</v-card-title>
    <v-card-text>
      <v-alert v-if="passwordChanged" color="success" icon="mdi-check-circle">
        Password changed successfully.
      </v-alert>
      <v-form @submit.prevent="changePassword">
        <v-text-field
          v-model="newPassword"
          label="New Password"
          type="password"
          required
        ></v-text-field>
        <v-btn color="primary" type="submit">Change Password</v-btn>
      </v-form>
    </v-card-text>
  </v-card>
</template>

<script>
import axios from "axios";

export default {
  name: "PasswordChange",
  data() {
    return {
      newPassword: "",
      passwordChanged: false, // Flag to track password change success
    };
  },
  methods: {
    async changePassword() {
      console.log(this.newPassword);
      let payload = {
        datas: {
          password: this.newPassword,
        },
      };
      let UserId = this.$route.params.userId;
      try {
        // Attempt to change the password
        let response = await axios.post(
          `/users/password_change?user_id=${UserId}`,
          payload
        );
        console.log(response);
        if (response.status === 200) {
          // Password change was successful (status code 200 OK)
          this.passwordChanged = true;
        //   this.$router.push("/users");

        }
      } catch (error) {
        console.error("Password change failed:", error);
      }
    },
  },
};
</script>
