<template>
  <tr v-if="isLoading">
    <td :colspan="cols">
      <div class="progress">
        <div
          class="progress-bar progress-bar-striped progress-bar-animated"
          role="progressbar"
          style="width: 100%"
          aria-valuenow="10"
          aria-valuemin="0"
          aria-valuemax="100"
        ></div>
      </div>
      <!-- <div class="d-flex justify-content-center">
        <div class="spinner-border" role="status"></div>
      </div> -->
    </td>
  </tr>
</template>

<script type="text/javascript">
export default {
  name: "LoadingTableWidget",
  props: ["isLoading", "cols"],
};
</script>
