<template>
  <!-- partial -->
  <div class="page-content">
    <div class="row">
      <div class="col-lg-7 col-md-7 col-sm-6 col-xs-12">
        <h5 class="txt-dark">
          Products &nbsp;
          <add-button link="/products/create" v-if="roleName == 'SUPER ADMIN' || roleName == 'MIM'"></add-button>
          &nbsp;
          <upload-button link="/products/upload" v-if="roleName == 'SUPER ADMIN' || roleName == 'MIM'"></upload-button>
        </h5>
      </div>
      <!-- Breadcrumb -->
      <div class="col-lg-5 col-sm-6 col-md-5 col-xs-12">
        <ol class="breadcrumb breadcrumb-line" style="float: right">
          <li class="breadcrumb-item">
            <router-link :to="`/`">Dashboard</router-link>
          </li>
          <li class="breadcrumb-item">Products</li>
          <li class="breadcrumb-item active" aria-current="page">List</li>
        </ol>
      </div>
    </div>
    <!-- /Breadcrumb -->

    <!-- /Breadcrumb -->
    <!-- Search & Filtering  -->
    <div class="row">
      <div class="col-md-12 grid-margin stretch-card">
        <div class="card">
          <div class="card-body row">
            <div class="col-lg-8 col-md-8 col-sm-6 col-xs-12">
              <v-text-field v-model="search_keyword" label="Search ... "
                prepend-inner-icon="mdi-newspaper-variant-multiple-outline" placeholder="Search By Name........"
                @keydown.enter="getData"></v-text-field>
            </div>
            <v-row>
              <v-col sm="3">
                <v-autocomplete ref="state" v-model="form.state" :items="States" label="State"
                  prepend-icon="mdi-calendar" @input="getData" clearable
                  :error-messages="errors.state"></v-autocomplete>
              </v-col>
              <v-col sm="3">
                <v-autocomplete ref="state" v-model="form.city" :items="Cities" label="City" prepend-icon="mdi-calendar"
                  @input="getData" clearable :error-messages="errors.city"></v-autocomplete>
              </v-col>
              <div class="col-md-4">
                <div style="float: right">
                  <v-card-title v-if="products.length != 0">
                    <v-spacer></v-spacer>
                    <v-btn class="primary mx-4" @click="generateExcel()">
                      <span v-if="isExcelloading"> Generating Excel... </span>
                      <span v-else>Generate Excel</span>
                    </v-btn>
                  </v-card-title>
                </div>
              </div>
            </v-row>
          </div>
        </div>
      </div>
    </div>
    <!-- Search & Filtering  -->
    <!-- Table -->
    <div class="row">
      <div class="col-md-12">
        <div class="card">
          <div class="card-body">
            <div>
              <div class="wrapper1" ref="wrapper1" @scroll="handleScroll1">
                <div class="div1"></div>
              </div>
              <div class="wrapper2">
                <div class="div2">
                  <div class="table-responsive" ref="wrapper2" @scroll="handleScroll2">
                    <table id="dataTableExample" class="table table-hover">
                      <thead>
                        <tr>
                          <th>Sr No</th>
                          <th>Date</th>
                          <th>Name</th>
                          <th>Description</th>
                          <th>Product Image 1</th>
                          <th>Product Image 2</th>
                          <th>Issues</th>
                          <th>Spares</th>
                          <th>QR Code</th>
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        <!-- Table Loader -->
                        <loading-table :isLoading="isLoading" cols="8
                          "></loading-table>
                        <!-- If No Data Received-->
                        <tr v-if="products.length == 0">
                          <td colspan="8" class="text-center" style="color: grey">
                            No Data Found
                          </td>
                        </tr>
                        <!-- If data Received -->
                        <tr v-for="(product, at) in products" :key="`product${at}`">
                          <td>{{ serialNoStarting + at + 1 }}</td>
                          <td>{{ product.created_at | ddmmyyyy }}</td>

                          <td>{{ product.name }}</td>

                          <td>{{ product.description }}</td>
                          <td>
                            <img v-if="product.imagepath_1" :src="path + '/' + product.imagepath_1" alt="Featured Image"
                              style="width: 100px; height: 100px" />
                            <span v-else>No Image Available</span>
                          </td>
                          <td>
                            <img v-if="product.imagepath_2" :src="path + '/' + product.imagepath_2" alt="Featured Image"
                              style="width: 100px; height: 100px" />
                            <span v-else>No Image Available</span>
                          </td>
                          <td>
                            <ul>
                              <li v-for="issue in product.product_issue_lists" :key="issue.id">
                                {{ issue.title }}
                              </li>
                            </ul>
                          </td>
                          <td>
                            <ul>
                              <li v-for="spare in product.product_spare_lists" :key="spare.id">
                                {{ spare.name }}
                              </li>
                            </ul>
                          </td>
                          <td>
                            <div id="qr-code-print-section" class="qr-code-print-section">
                              <qr-code :text="generateQRText(product)" :size="600">
                              </qr-code>
                            </div>
                            <!-- :text="product.id"  -->
                          </td>
                          <td>
                            <v-btn class="mx-2" color="primary" fab x-small :to="`/products/${product.id}`">
                              <v-icon dark> mdi-pencil </v-icon>
                            </v-btn>

                            <v-btn class="mx-2" color="primary" fab x-small @click="downloadQRCode(product)"><v-icon
                                dark>mdi-download</v-icon></v-btn>
                            <v-btn class="mx-2" color="primary" fab x-small @click="printTable(product)">
                              <v-icon dark>mdi-printer</v-icon>
                            </v-btn>
                            <v-tooltip bottom>
                              <template v-slot:activator="{ on, attrs }">
                                <v-btn v-bind="attrs" v-on="on" class="mx-2" color="sucess" fab x-small
                                  :to="`/products/${product.id}/product-issues`">
                                  <v-icon dark>mdi-plus</v-icon>
                                </v-btn>
                              </template>
                              <span>Add Product Issues</span>
                            </v-tooltip>
                            <v-tooltip bottom>
                              <template v-slot:activator="{ on, attrs }">
                                <v-btn v-bind="attrs" v-on="on" fab x-small class="mx-2"
                                  :to="`/products/${product.id}/product-spares`">
                                  <v-icon dark>mdi-plus</v-icon>
                                </v-btn>
                              </template>
                              <span>Add Product Spares</span>
                            </v-tooltip>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    <!-- /Table -->
                    <div class="col-md-12">
                      <div style="float: right">
                        <v-pagination pagination v-model="page" :count="count" :length="paginationLength"
                          prev-icon="mdi-menu-left" next-icon="mdi-menu-right"></v-pagination>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- partial -->
</template>

<script type="text/javascript">
import axios from "axios";

export default {
  name: "user",
  watch: {
    page(newVal) {
      this.getData(newVal);
    },
  },
  data() {
    return {
      form: {},
      States: [],
      Cities: [],
      products: [],
      count: 0,
      paginationLength: 0,
      serialNoStarting: 1,
      isLoading: false,
      isExcelloading: false,
      search_keyword: "",
      page: 1,
    };
  },
  mounted() {
    this.roleName = this.user.roles[0].name;
    this.getMasters();
    this.getData();
    this.path = this.mediaUrl;
  },
  methods: {
    async getMasters() {
      let masters = await axios.get("tickets/masters?values=limited");
      masters = masters.data;
      masters.States.forEach((state) => {
        this.States.push({
          id: state.id,
          text: state.title,
          value: state.title,
        });
      });
      masters.City.forEach((city) => {
        this.Cities.push({
          id: city.id,
          text: city.title,
          value: city.title,
        });
      });
    },
    generateQRText(product) {
      return `ProductId=${product.id}`;
    },
    async downloadQRCode(product) {
      try {
        // Generate the QR code image
        const qrCodeData = this.generateQRText(product);
        const qrCodeImage = await this.generateQRCodeImage(qrCodeData);

        // Create an anchor element and set the image data as the href
        const downloadLink = document.createElement('a');
        downloadLink.href = qrCodeImage;

        // Set the file name for the download
        const fileName = `product_qr_${product.id}.png`;
        downloadLink.download = fileName;

        // Trigger a click event to start the download
        document.body.appendChild(downloadLink);
        downloadLink.click();
        document.body.removeChild(downloadLink);
      } catch (error) {
        console.error('Error generating or downloading QR code:', error);
      }
    },
    generateQRCodeImage(data) {
      return new Promise((resolve, reject) => {
        try {
          const qr = require('qr-image');
          const qrCode = qr.image(data, { type: 'png' });

          // Creating a canvas to draw the QR code image
          const canvas = document.createElement('canvas');
          const ctx = canvas.getContext('2d');
          const img = new Image();

          // Convert the QR code stream to a blob
          this.streamToBlob(qrCode).then(imageBlob => {
            const qrCodeUrl = URL.createObjectURL(imageBlob);

            // Load the QR code image onto the canvas
            img.src = qrCodeUrl;
            img.onload = () => {
              canvas.width = img.width;
              canvas.height = img.height;
              ctx.drawImage(img, 0, 0);

              ctx.font = '20px Arial';
              ctx.fillStyle = '#6571ff';
              ctx.textAlign = 'center';
              ctx.fillText('FSS', canvas.width / 2, canvas.height - 2); // Example text position

              // Convert canvas to blob
              canvas.toBlob(blob => {
                resolve(URL.createObjectURL(blob));
              }, 'image/png');
            };
          }).catch(error => {
            console.error('Error generating QR code image:', error);
            reject(error);
          });
        } catch (error) {
          console.error('Error generating QR code image:', error);
          reject(error);
        }
      });
    },
    async streamToBlob(stream) {
      return new Promise((resolve, reject) => {
        const chunks = [];
        stream.on('data', (chunk) => chunks.push(chunk));
        stream.on('end', () => {
          const blob = new Blob(chunks, { type: 'image/png' });
          resolve(blob);
        });
        stream.on('error', reject);
      });
    },

    async printTable(product) {
      try {
        // Generate the QR code image
        const qrCodeData = this.generateQRText(product);
        const qrCodeImage = await this.generateQRCodeImage(qrCodeData);
        const img = new Image();
        img.src = qrCodeImage;
        const newWindow = window.open();
        newWindow.document.body.appendChild(img);
        img.onload = function () {
          newWindow.print();
        };
      } catch (error) {
        console.error('Error generating or printing QR code:', error);
      }
    },
    streamToBuffer(stream) {
      return new Promise((resolve, reject) => {
        const chunks = [];
        stream.on('data', (chunk) => chunks.push(chunk));
        stream.on('end', () => resolve(Buffer.concat(chunks)));
        stream.on('error', (error) => reject(error));
      });
    },
    formatDate(dateString) {
      const date = new Date(dateString);
      const day = date.getDate().toString().padStart(2, "0");
      const month = (date.getMonth() + 1).toString().padStart(2, "0"); // Note: January is 0
      const year = date.getFullYear();
      return `${day}/${month}/${year}`;
    },
    async getData() {
      this.isLoading = true;
      this.search_keyword = this.search_keyword ? this.search_keyword : "";
      this.form.city = this.form.city ? this.form.city : "";
      this.form.state = this.form.state ? this.form.state : "";
      let query =
        "page=" +
        this.page +
        "&rowsPerPage=" +
        this.rowsPerPage +
        "&city=" +
        this.form.city +
        "&state=" +
        this.form.state +
        "&search_keyword=" +
        this.search_keyword;
      let products = await axios.get(`/products?${query}`);

      this.products = products.data.data;
      this.count = products.data.count;
      this.serialNoStarting = (this.page - 1) * this.rowsPerPage;
      this.paginationLength = Math.ceil(this.count / this.rowsPerPage);

      this.isLoading = false;
    },
    async generateExcel() {
      this.isExcelloading = true;
      this.search_keyword = this.search_keyword ? this.search_keyword : "";
      this.search_keyword = this.search_keyword ? this.search_keyword : "";
      this.form.city = this.form.city ? this.form.city : "";
      let query =
        "&city=" +
        this.form.city +
        "&state=" +
        this.form.state +
        "&search_keyword=" +
        this.search_keyword;
      try {
        let response = await axios.get(`productsExports?${query}`);
        if (response && response.data && response.data.data) {
          window.open(`${response.data.data}`, "_blank");
        } else {
          console.error("Invalid response data:", response.data);
        }
      } catch (error) {
        console.error("Error fetching Excel data:", error);
      }
      this.isExcelloading = false;
    },
  },
};
</script>

<style scoped>
.table td img {
  border-radius: 0 !important;
  height: 100px;
  width: 100px;
}

#qr-print-section {
  display: none;
}

/* CSS for centering and enlarging the QR code */
#qr-print-section qr-code {
  margin: auto;
  width: 50%;
  /* Adjust the width as needed */
  height: auto;
}
</style>
