import Vue from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import store from "./store";

import Autocomplete from "@trevoreyre/autocomplete-vue";
import "@trevoreyre/autocomplete-vue/dist/style.css";

import globalMixins from './globalFunctions.js';
Vue.mixin(globalMixins);


import VueGoogleCharts from "vue-google-charts";
Vue.use(VueGoogleCharts);

import JsonExcel from "vue-json-excel";
Vue.component("downloadExcel", JsonExcel);

import VueQRCodeComponent from "vue-qrcode-component";
Vue.component("qr-code", VueQRCodeComponent);

import * as VueGoogleMaps from "vue2-google-maps";

Vue.use(VueGoogleMaps, {
  load: {
    key: "AIzaSyAm0FfE4ijnd7gx8dQ6KHp2rtOWNs9rYZk",
    // libraries: 'places', // This is required if you use the Autocomplete plugin
    // OR: libraries: 'places,drawing'
    // OR: libraries: 'places,drawing,visualization'
    // (as you require)

    //// If you want to set the version, you can do so:
    // v: '3.26',
  },

  //// If you intend to programmatically custom event listener code
  //// (e.g. `this.$refs.gmap.$on('zoom_changed', someFunc)`)
  //// instead of going through Vue templates (e.g. `<GmapMap @zoom_changed="someFunc">`)
  //// you might need to turn this on.
  // autobindAllEvents: false,

  //// If you want to manually install components, e.g.
  //// import {GmapMarker} from 'vue2-google-maps/src/components/marker'
  //// Vue.component('GmapMarker', GmapMarker)
  //// then disable the following:
  installComponents: true,
});

Vue.config.productionTip = false;

require("@/store/subscriber");

require("./mixin.js");
require("./axios.js");
require("./directives.js");
require("./components.js");
require("./filters.js");

import VCalendar from "v-calendar";
Vue.use(VCalendar);

Vue.use(Autocomplete);

import AsyncComputed from "vue-async-computed";
Vue.use(AsyncComputed);

import vSelect from "vue-select";
Vue.component("v-select", vSelect);
import "vue-select/dist/vue-select.css";
import vuetify from "./plugins/vuetify";

store.dispatch("auth/attempt", localStorage.getItem("token")).then(() => {
  new Vue({
    router,
    store,
    vuetify,
    render: (h) => h(App),
  }).$mount("#app");
});
