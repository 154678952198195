<template>
  <v-row>
    <v-col sm="3">
      <v-card color="#162DEB" dark>
        <div class="d-flex flex-no-wrap justify-space-between">
          <div>
            <v-card-title
              class="text-h5"
              v-text="technician.total_technician"
            ></v-card-title>

            <v-card-subtitle v-text="'TOTAL TECHNICIAN'"></v-card-subtitle>
            <v-card-actions>
              <v-btn
                class="ml-2 mt-5"
                outlined
                rounded
                small
                target="_blank"
                :to="{ path: '/users', query: { status: 'total' } }"
              >
                View List
              </v-btn>
            </v-card-actions>
          </div>
          <v-avatar class="ma-90" size="140">
            <v-icon class="imgStyle" x-large dark>mdi-account-group</v-icon>
          </v-avatar>
        </div>
      </v-card>
    </v-col>
    <v-col sm="3">
      <v-card color="#162DEB" dark>
        <div class="d-flex flex-no-wrap justify-space-between">
          <div>
            <v-card-title
              class="text-h5"
              v-text="technician.engaged_technician_count"
            ></v-card-title>

            <v-card-subtitle v-text="'ENGAGED TECHNICIAN'"></v-card-subtitle>

            <v-card-actions>
              <v-btn
                class="ml-2 mt-5"
                outlined
                rounded
                small
                target="_blank"
                :to="{ path: '/users', query: { status: 'engaged' } }"
              >
                View List
              </v-btn>
            </v-card-actions>
          </div>

          <v-avatar class="ma-90" size="140" tile>
            <v-icon class="imgStyle" x-large dark>mdi-account</v-icon>
          </v-avatar>
        </div>
      </v-card>
    </v-col>
    <v-col sm="3">
      <v-card color="#162DEB" dark>
        <div class="d-flex flex-no-wrap justify-space-between">
          <div>
            <v-card-title
              class="text-h5"
              v-text="technician.not_engaged_technician_count"
            ></v-card-title>

            <v-card-subtitle v-text="'FREE TECHNICIAN'"></v-card-subtitle>

            <v-card-actions>
              <v-btn
                class="ml-2 mt-5"
                outlined
                rounded
                small
                target="_blank"
                :to="{ path: '/users', query: { status: 'not-engaged' } }"
              >
                View List
              </v-btn>
            </v-card-actions>
          </div>

          <v-avatar class="ma-3" size="125" tile>
            <v-icon class="imgStyle" x-large dark>mdi-account-alert</v-icon>
          </v-avatar>
        </div>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import axios from "axios";

export default {
  name: "TaCounts",
  data() {
    return {
      technician: {},
    };
  },

  mounted() {
    this.getData();
  },
  methods: {
    async getData() {
      this.isLoading = true;
      let response = await axios.get("/admin_dashboard");
      this.technician = response.data;
      console.log(this.technician);
    },
  },
};
</script>
