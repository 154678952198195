<template>
  <!-- partial -->
  <div class="page-content">
    <div class="row">
      <div class="col-lg-8 col-md-8 col-sm-6 col-xs-12">
        <h5 class="txt-dark">
          <back-button link="/values"></back-button>
          Add Value
        </h5>
      </div>
      <!-- Breadcrumb -->
      <div class="col-lg-4 col-sm-6 col-md-4 col-xs-12">
        <ol class="breadcrumb breadcrumb-line" style="float: right">
          <li class="breadcrumb-item">
            <router-link :to="`/`">Dashboard</router-link>
          </li>
          <li class="breadcrumb-item">
            <router-link :to="`/values`">Values</router-link>
          </li>
          <li class="breadcrumb-item active" aria-current="page">Add</li>
        </ol>
      </div>
    </div>
    <!-- /Breadcrumb -->
    <!-- Form  -->
    <div class="row">
      <div class="col-md-8 grid-margin stretch-card">
        <div class="card">
          <div class="card-body">
            <div class="row">
              <div class="col-md-12">
                <label class="col-form-label">Title</label>
                <div class="col-sm-12">
                  <v-text-field
                   v-model="form.title"
                     @input="form.title = form.title.toUpperCase()"
                    prepend-inner-icon="mdi-format-list-text"
                    outlined
                    placeholder="Title"
                    clearable
                  ></v-text-field>
                </div>
              </div>

              <div class="col-md-12">
                <label class="col-form-label">Description</label>
                <div class="col-sm-12">
                  <v-text-field
                    v-model="form.description"
                     @input="form.description = form.description.toUpperCase()"
                    prepend-inner-icon="mdi-format-list-text"
                    outlined
                    placeholder="Description"
                    clearable
                  ></v-text-field>
                </div>
              </div>
            </div>
            <div class="form-group mb-0">
              <submit-button
                :isLoading="isLoading"
                @submit="save"
                text="Save"
                style="float: right"
              ></submit-button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- partial -->
</template>
<script type="text/javascript">
import axios from "axios";

export default {
  name: "ValueCreate",
  data() {
    return {
      form: {
          is_active: true,
        created_by_id: "",
      },
      isLoading: false,
    };
  },
   mounted() {
    this.form.created_by_id = this.user.id;
  },
  methods: {
    async save() {
      try {
        this.isLoading = true;
        await axios.post("/values", this.form);
        this.isLoading = false;
        this.$router.push("/values");
      } catch (e) {
        this.isLoading = false;
      }
    },
  },
};
</script>

