import Vue from 'vue'

// Components
import SubmitButton from '@/components/submit-button.vue'
import AddButton from '@/components/add-button.vue'
import UploadButton from '@/components/upload-button.vue'
import BackButton from '@/components/back-button.vue'
import LoadingTable from '@/components/loading-table.vue'
import Pagination from '@/components/pagination.vue'
 import Autocomplete from '@/components/autocomplete.vue'
import MySelect from '@/components/my-select.vue'
import MyCheckbox from '@/components/my-checkbox.vue'
import Loading from '@/components/loading.vue'
import MyDivInput from '@/components/my-div-input.vue'
import Errors from '@/components/errors.vue'
import MyDatepicker from '@/components/my-datepicker.vue'
import SaveButton from "@/components/save-button.vue";


Vue.component('submit-button', SubmitButton)
Vue.component('add-button', AddButton)
Vue.component('upload-button', UploadButton)
Vue.component('back-button', BackButton)
Vue.component('loading-table', LoadingTable)
Vue.component('pagination', Pagination)
 Vue.component('autocomplete', Autocomplete)
Vue.component('my-select', MySelect)
Vue.component('my-checkbox', MyCheckbox)
Vue.component('loading', Loading)
Vue.component('my-div-input', MyDivInput)
Vue.component('errors', Errors)
Vue.component('my-datepicker', MyDatepicker)
Vue.component("save-button", SaveButton);

