<template>
  <div class="page-content">
    <div class="row">
      <div class="col-lg-8 col-md-8 col-sm-6 col-xs-12">
        <h5 class="txt-dark">
          <back-button link="/customers"></back-button>
          Add Company
        </h5>
      </div>
      <!-- Breadcrumb -->
      <div class="col-lg-4 col-sm-6 col-md-4 col-xs-12">
        <ol class="breadcrumb breadcrumb-line" style="float: right">
          <li class="breadcrumb-item">
            <router-link :to="`/`">Dashboard</router-link>
          </li>
          <li class="breadcrumb-item">
            <router-link :to="`companies`">Companies</router-link>
          </li>
          <li class="breadcrumb-item active" aria-current="page">Add</li>
        </ol>
      </div>
    </div>
    <div class="row">
      <div class="col-md-10 grid-margin stretch-card">
        <div class="card">
          <div class="card-body">
            <div class="row">
              <div class="col-md-4">
                <label class="col-form-label">Name</label>
                <div class="col-sm-12">
                  <v-text-field
                    v-model="form.name"
                    prepend-inner-icon="mdi mdi-account"
                    outlined
                    clearable
                 
                    :error-messages="errors.name"
                  ></v-text-field>
                </div>
              </div>
              <div class="col-md-4">
                <label class="col-form-label">Email</label>
                <div class="col-sm-12">
                  <v-text-field
                    v-model="form.email"
                    prepend-inner-icon="mdi-email"
                  
                    outlined
                    clearable
                    :error-messages="errors.email"
                  ></v-text-field>
                </div>
              </div>
              <div class="col-md-4">
                <label class="col-form-label">Contact Person</label>
                <div class="col-sm-12">
                  <v-text-field
                    v-model="form.contact_person"
                    outlined
                   
                    prepend-inner-icon="mdi-card-account-phone"
                    :error-messages="errors.contact_person"
                  ></v-text-field>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-4">
                <label class="col-form-label">Phone</label>
                <div class="col-sm-12">
                  <v-text-field
                    v-model="form.phone"
                    outlined
                   
                    prepend-inner-icon="mdi-phone"
                    :error-messages="errors.phone"
                  ></v-text-field>
                </div>
              </div>
              <div class="col-md-4">
                <label class="col-form-label">Address</label>
                <div class="col-sm-12">
                  <v-text-field
                    v-model="form.address"
                    outlined
                 
                    prepend-inner-icon="mdi-map-marker-multiple"
                    :error-messages="errors.address"
                  ></v-text-field>
                </div>
              </div>
              <div class="col-md-4">
                <label class="col-form-label">Is Active</label>

                <div class="col-sm-12">
                  <v-switch
                    v-model="form.is_active"
                    inset
                    :label="`Is Active: ${
                      form.is_active.toString() == 'true' ? 'YES' : 'NO'
                    }`"
                  ></v-switch>
                </div>
              </div>
            </div>

            <br />
            <div class="form-group mb-0">
              <submit-button
                :isLoading="isLoading"
                @submit="save"
                text="Save"
                style="float: right"
              ></submit-button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";
export default {
  name: "CompanyCreatePage",
  data() {
    return {
      form: {
        name: "",
        is_active: true,
      },
      isLoading: false,
    };
  },

  methods: {
    async save() {
      try {
        this.isLoading = true;
        await axios.post("/companies", this.form);
        this.isLoading = false;
        this.$router.push("/companies");
      } catch (e) {
        // alert("error");
        this.isLoading = false;
      }
    },
  },
};
</script>
