<template>
  <div class="page-content">
    <div class="row">
      <div class="col-lg-8 col-md-8 col-sm-6 col-xs-12">
        <h5 class="txt-dark">
          <back-button link="/customers"></back-button>
          Add Customer
        </h5>
      </div>
      <!-- Breadcrumb -->
      <div class="col-lg-4 col-sm-6 col-md-4 col-xs-12">
        <ol class="breadcrumb breadcrumb-line" style="float: right">
          <li class="breadcrumb-item">
            <router-link :to="`/`">Dashboard</router-link>
          </li>
          <li class="breadcrumb-item">
            <router-link :to="`customers`">Customers</router-link>
          </li>
          <li class="breadcrumb-item active" aria-current="page">Add</li>
        </ol>
      </div>
    </div>
    <div class="row">
      <div class="col-md-10 grid-margin stretch-card">
        <div class="card">
          <div class="card-body">
            <div class="row">
              <div class="col-md-4">
                <label class="col-form-label">Name</label>
                <div class="col-sm-12">
                  <v-text-field v-model="form.name" prepend-inner-icon="mdi mdi-account" outlined clearable
                    :error-messages="errors.name"></v-text-field>
                </div>
              </div>
              <div class="col-md-4">
                <label class="col-form-label">Email</label>
                <div class="col-sm-12">
                  <v-text-field v-model="form.email" prepend-inner-icon="mdi-email" outlined clearable
                    :error-messages="errors.email"></v-text-field>
                </div>
              </div>
              <div class="col-md-4">
                <label class="col-form-label">Phone Number</label>
                <div class="col-sm-12">
                  <v-text-field v-model="form.phone" prepend-inner-icon="mdi-phone" outlined clearable
                    :error-messages="errors.phone"></v-text-field>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-4">
                <label class="col-form-label">Address</label>
                <div class="col-sm-12">
                  <v-text-field v-model="form.address" prepend-inner-icon="mdi-map-marker" outlined
                    clearable></v-text-field>
                </div>
              </div>

              <div class="col-md-4">
                <label class="col-form-label">Select Customer type</label>

                <v-autocomplete ref="customer_type" v-model="form.customer_type" @change="getMasters(form.customer_type)"
                  :items="customer_types" label="Customer Type" prepend-icon="mdi-account"
                  :error-messages="errors.customer_type"></v-autocomplete>
              </div>
              <div class="col-md-4" v-if="Commercial">
                <label class="col-form-label">Select Customer Company</label>

                <v-autocomplete ref="customer_type" v-model="form.customer_company_id" :items="customer_companies"
                  label="Customer Companies" prepend-icon="mdi-account"
                  :error-messages="errors.customer_company_id"></v-autocomplete>
              </div>
            </div>
            <div class="row">
              <v-col sm="4">
                <v-switch v-model="form.is_active" inset :label="`Is Active: ${form.is_active.toString() == 'true' ? 'YES' : 'NO'
                  }`"></v-switch>
              </v-col>
            </div>
            <br />
            <div class="form-group mb-0">
              <submit-button :isLoading="isLoading" @submit="save" text="Save" style="float: right"></submit-button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script type="text/javascript">
import axios from "axios";

export default {
  name: "UserCreate",
  data() {
    return {
      form: {
        is_active: true,
      },

      isLoading: false,
      Commercial: false,
      customer_companies: [],
      customer_types: [
        { text: "Private", value: "Private" },
        { text: "Commercial", value: "Commercial" },
      ],
    };
  },
  methods: {
    async getMasters(value) {
      console.log(value)
      this.customer_companies = [];
      if (value == "Commercial") {
        this.Commercial = true;
        console.log('recieved')
        let masters = await axios.get('customer_companies');
        masters = masters.data;
        masters.data.forEach((customer_company) => {
          this.customer_companies.push({
            id: customer_company.id,
            text: customer_company.company_name,
            value: customer_company.id,
          });
        });
      } else {
        this.Commercial = false;
      }
      // console.log(customer_companies);
    },
    async save() {
      try {
        //  this.form.company = this.company.id;
        this.isLoading = true;
        let customers = await axios.post("/customers", this.form);
        this.customers = customers.data.data;
        this.isLoading = false;
        this.$router.push("/customers");
      } catch (e) {
        this.isLoading = false;
      }
    },
  },
};
</script>
<style scoped></style>
