<template>
  <div class="page-content">
    <div class="row">
      <div class="col-lg-8 col-md-8 col-sm-6 col-xs-12">
        <h5 class="txt-dark">
          <back-button link="/products"></back-button>
          Add Product
        </h5>
      </div>
      <!-- Breadcrumb -->
      <div class="col-lg-4 col-sm-6 col-md-4 col-xs-12">
        <ol class="breadcrumb breadcrumb-line" style="float: right">
          <li class="breadcrumb-item">
            <router-link :to="`/`">Dashboard</router-link>
          </li>
          <li class="breadcrumb-item">
            <router-link :to="`products`">Products</router-link>
          </li>
          <li class="breadcrumb-item active" aria-current="page">Add</li>
        </ol>
      </div>
    </div>
    <div class="row">
      <div class="col-md-10 grid-margin stretch-card">
        <div class="card">
          <div class="card-body">
            <div class="row mb-3">
              <label class="col-sm-2 col-form-label">Name</label>
              <div class="col-sm-10">
                <v-text-field v-model="form.name" prepend-inner-icon="mdi-more" outlined clearable></v-text-field>
              </div>
            </div>
            <div class="row mb-3">
              <label class="col-sm-2 col-form-label">Description</label>
              <div class="col-sm-10">
                <v-textarea placeholder="Enter your description" prepend-inner-icon="mdi-chemical-weapon"
                  v-model="form.description" outlined></v-textarea>
              </div>
            </div>
            <div class="row mb-3">
              <label class="col-sm-2 col-form-label">Product Company</label>
              <div class="col-sm-10">
                <v-text-field placeholder="Enter your description" prepend-inner-icon="mdi-chemical-weapon"
                  v-model="form.product_company" outlined></v-text-field>
              </div>
            </div>
            <div class="row mb-3">
              <label class="col-sm-2 col-form-label">Serial no</label>
              <div class="col-sm-10">
                <v-text-field placeholder="Enter your description" prepend-inner-icon="mdi-chemical-weapon"
                  v-model="form.serial_no" outlined></v-text-field>
              </div>
            </div>

            <div class="row mb-3">
              <label class="col-sm-2 col-form-label">Product Image 1</label>
              <div class="col-sm-4">
                <input type="file" id="file_1" name="file_1" ref="file_1" @change="onImage1Change" accept="image/*" />
                <br />
                <v-btn v-if="form.image1_url != null" x-small class="reset_image my-1" color="red" dark
                  @click="($refs.file_1.value = null), (image1_url = null)">Remove</v-btn>
              </div>
              <div class="col-sm-6">
                <!-- Image Preview -->
                <div id="preview" v-if="image1_url">
                  <label for="">Image 1 Preview</label>
                  <br />
                  <img :src="image1_url" style="height: 20%; width: 20% !important" />
                </div>
              </div>
            </div>
            <div class="row mb-3">
              <label class="col-sm-2 col-form-label">Product Image 2</label>
              <div class="col-sm-4">
                <input type="file" id="file_2" name="file_2" ref="file_2" @change="onImage2Change" accept="image/*" />
                <br />
                <v-btn v-if="form.image2_url != null" x-small class="reset_image my-1" color="red" dark
                  @click="($refs.file_2.value = null), (image2_url = null)">Remove</v-btn>
              </div>
              <div class="col-sm-6">
                <div id="preview" v-if="image2_url">
                  <label for="">Image 2 Preview</label>
                  <br />
                  <img :src="image2_url" style="height: 20%; width: 20% !important" />
                </div>
              </div>
              <div class="form-group mb-0">
                <!-- <submit-button
                :isLoading="isLoading"
                @submit="save"
                text="Save"
                style="float: right"
              ></submit-button> -->
                <v-btn size="large" style="float: right" color="primary" x-large dark @click="save">Save Product</v-btn>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script type="text/javascript">
import axios from "axios";

export default {
  name: "ProductCreate",
  data() {
    return {
      form: {
        role_id: "",
        active: true,
        created_by_id: "",
      },
      image1_url: "",
      image2_url: "",
      isLoading: false,
    };
  },
  mounted() {
    this.form.created_by_id = this.user.id;
  },
  methods: {
    onImage1Change(e) {
      const file = e.target.files[0];
      this.image1_url = URL.createObjectURL(file);
    },
    onImage2Change(e) {
      const file = e.target.files[0];
      this.image2_url = URL.createObjectURL(file);
    },
    async save() {
      try {
        //  this.form.company = this.company.id;
        this.isLoading = true;
        let Product = await axios.post("/products", this.form);
        this.Product = Product.data.data;
        await this.handleFileUpload();
        this.isLoading = false;
        this.$router.push("/products");
      } catch (e) {
        this.isLoading = false;
      }
    },
    async handleFileUpload() {
      this.isLoading = true;
      const productid = this.Product.id;
      // console.log(this.product.id)
      let formData = new FormData();

      formData.append("id", productid);
      let imagepath_1 = this.$refs.file_1?.files[0];
      formData.append("imagepath_1", imagepath_1);
      let imagepath_2 = this.$refs.file_2?.files[0];
      formData.append("imagepath_2", imagepath_2);

      await axios
        .post("upload_product_image", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .catch(function () {
          console.log("FAILURE!!");
        });
      this.isLoading = false;
    },
  },
};
</script>
