<template>
    <!-- partial -->
    <div class="page-content">
        <div class="row">
            <div class="col-lg-7 col-md-7 col-sm-6 col-xs-12">
                <h5 class="txt-dark">
                    Customers Companies &nbsp;
                    <add-button link="/customers/companies/create"></add-button>
                </h5>
            </div>
            <!-- Breadcrumb -->
            <div class="col-lg-5 col-sm-6 col-md-5 col-xs-12">
                <ol class="breadcrumb breadcrumb-line" style="float: right">
                    <li class="breadcrumb-item">
                        <router-link :to="`/`">Dashboard</router-link>
                    </li>
                    <li class="breadcrumb-item">Customer Companies</li>

                    <li class="breadcrumb-item active" aria-current="page">List</li>
                </ol>
            </div>
        </div>
        <!-- /Breadcrumb -->
        <!-- Search & Filtering  -->
        <div class="row">
            <div class="col-md-12 grid-margin stretch-card">
                <div class="card">
                    <div class="card-body row">
                        <div class="col-lg-8 col-md-8 col-sm-6 col-xs-12">
                            <input type="text" class="form-control" placeholder="Search..." v-model="searchKeyword"
                                @keydown.enter="getData" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- Search & Filtering  -->
        <!-- Table -->
        <div class="row">
            <div class="col-md-12 grid-margin stretch-card">
                <div class="card">
                    <div class="card-body">
                        <div>
                            <div class="wrapper1" ref="wrapper1" @scroll="handleScroll1">
                                <div class="div1"></div>
                            </div>
                            <div class="wrapper2">
                                <div class="div2">
                                    <div class="table-responsive" ref="wrapper2" @scroll="handleScroll2">
                                        <v-simple-table>
                                            <template v-slot:default>
                                                <thead>
                                                    <tr>
                                                        <th>Sr No</th>
                                                        <th class="text-left">Company name</th>
                                                        <th class="text-left">Company Address</th>
                                                        <th class="text-left">Company Pincode</th>
                                                        <th class="text-left">Company Email</th>
                                                        <th class="text-left">Company Phone</th>
                                                        <th class="text-left">Action</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr v-for="(item, at) in CustomerCompany" :key="item.id">
                                                        <td>{{ serialNoStarting + at + 1 }}</td>
                                                        <td>{{ item.company_name }}</td>
                                                        <td>{{ item.company_address }}</td>
                                                        <td>{{ item.company_pincode }}</td>
                                                        <td>{{ item.company_email }}</td>
                                                        <td>{{ item.company_phone }}</td>
                                                        <td>
                                                            <v-btn class="mx-2" color="primary" fab x-small
                                                                :to="`/customers/companies/${item.id}`">
                                                                <v-icon dark> mdi-pencil </v-icon>
                                                            </v-btn>

                                                        </td>
                                                    </tr>
                                                </tbody>
                                                <loading-table :isLoading="isLoading" cols="8
                        "></loading-table>
                                            </template>
                                        </v-simple-table>
                                        <div style=" display: flex;justify-content: end;">
                                            <v-pagination pagination v-model="page" :count="count"
                                                :length="paginationLength" prev-icon="mdi-menu-left"
                                                next-icon="mdi-menu-right" :total-visible="totalVisible">
                                                <template v-slot:prev="{ on }">
                                                    <v-btn @click="goToPage(page - 1)" text icon v-if="page > 1"
                                                        v-on="on">Previous</v-btn>
                                                </template>
                                                <template v-slot:next="{ on }">
                                                    <v-btn @click="goToPage(page + 1)" text icon
                                                        v-if="page < paginationLength" v-on="on">Next</v-btn>
                                                </template>
                                            </v-pagination>
                                        </div>
                                    </div>
                                    <!-- <pagination :count="count" @submit="getData"></pagination> -->
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <!-- partial -->
</template>
  
<script type="text/javascript">
import axios from "axios";

export default {
    name: 'CustomerCompany',
    watch: {
        page(newVal) {
            this.getData(newVal);
        },
    },
    data() {
        return {
            totalVisible: 5,
            form: {
                company_name: '',
                company_address: '',
                company_pincode: '',
                company_email: '',
                company_phone: ''
            },
            page: 1,
            count: 0,
            CustomerCompany: [],
            paginationLength: 0,
            isLoading: false,
            dialog: false,
            searchKeyword: "",
            serialNoStarting: 1,
        };
    },
    mounted() {
        this.getData();
    },
    methods: {
        async getData() {
            try {
                this.isLoading = true;
                this.searchKeyword = this.searchKeyword ? this.searchKeyword : "";
                let query =
                    "page=" +
                    this.page +
                    '&search_keyword=' +
                    this.searchKeyword +
                    "&rowsPerPage=" + this.rowsPerPage;

                const response = await axios.get(`customer_companies?${query}`);
                this.CustomerCompany = response.data.data;
                this.count = response.data.count;
                console.log(this.count)
                this.serialNoStarting = (this.page - 1) * this.rowsPerPage;
                console.log(this.serialNoStarting)
                console.log(this.rowsPerPage)

                this.paginationLength = Math.ceil(this.count / this.rowsPerPage);
                console.log(this.paginationLength)

                this.isLoading = false;
            } catch (error) {
                console.error("Error fetching customer_companies data:", error);
                this.isLoading = false;
            }
        },
        goToPage(newPage) {
            // Handle navigation to a new page
            this.page = newPage;
            console.log('forward');
            console.log(this.page);
            console.log('prev');
            this.getData();
        },
        async save() {
            try {


                this.isLoading = true;
                let CustomerCompany = await axios.post("/customer_companies", this.form);
                this.CustomerCompany = CustomerCompany.data.data;

                this.isLoading = false;
                this.dialog = true;
            } catch (error) {
                this.isLoading = false;
            }

        },
        reloadPage() {
            location.reload(); // Reload the page
        },

    }
};
</script>
  