<template>
  <span>
    <router-link :to="link">
      <i class="link-arrow" data-feather="plus-circle"></i>
      <!-- <i class="fa fa-plus-circle"></i> -->
    </router-link>
  </span>
</template>

<script type="text/javascript">
export default {
  name: "AddButtonWidget",
  props: ["link"],
};
</script>
