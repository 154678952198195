<template>
  <div class="page-content">
    <div class="row">
      <div class="col-lg-8 col-md-8 col-sm-6 col-xs-12">
        <h5 class="txt-dark">
          <back-button link="/tickets"></back-button>
          Ticket Summary
        </h5>
      </div>
      <div class="col-lg-4 col-sm-6 col-md-4 col-xs-12">
        <ol class="breadcrumb breadcrumb-line" style="float: right">
          <li class="breadcrumb-item">
            <router-link :to="`/`">Dashboard</router-link>
          </li>
          <li class="breadcrumb-item">
            <router-link :to="`tickets`">Tickets</router-link>
          </li>
          <li class="breadcrumb-item active" aria-current="page">Summary</li>
        </ol>
      </div>
    </div>
    <div class="row">
      <div class="col-md-10 grid-margin stretch-card">
        <div class="card">
          <div class="card-body">
            <div class="row">
              <h1>Ticket Details</h1>
              <div class="row">
                <div class="col-md-4">
                  <p style="margin-top: 10px; font-size: 21px">
                    <span style="font-weight: bold">Ticket ID :</span> #{{
                      form.id
                    }}
                  </p>
                </div>
                <div class="col-md-4">
                  <p style="margin-top: 10px; font-size: 21px">
                    <span style="font-weight: bold">Ticket Title :</span>
                    {{ form.title }}
                  </p>
                </div>
                <div class="col-md-4">
                  <p style="margin-top: 10px; font-size: 21px">
                    <span style="font-weight: bold">Ticket Description :</span>
                    {{ form.description }}
                  </p>
                </div>

                <div class="col-md-4">
                  <p style="font-size: 21px">
                    <span style="font-weight: bold">Ticket Created Date :</span>
                    {{ form.created_at | ddmmyyyy }}
                  </p>
                </div>
                <div class="col-md-4">
                  <p style="font-size: 21px">
                    <span style="font-weight: bold">Ticket Assign Date :</span>
                    {{ form.assigned_date | ddmmyyyy }}
                  </p>
                </div>
                <div class="col-md-4">
                  <p style="font-size: 21px">
                    <span style="font-weight: bold">Ticket Status :</span>
                    {{ form.status_type }}
                  </p>
                </div>
              </div>
              <hr />
              <h1 style="margin-top: 10px">Ticket Created By</h1>
              <div class="row">
                <div class="col-md-4">
                  <p style="margin-top: 10px; font-size: 21px">
                    <span style="font-weight: bold">Name :</span>
                    {{ form.created_by ? form.created_by.name.name : "" }}
                  </p>
                </div>
                <div class="col-md-4">
                  <p style="margin-top: 10px; font-size: 21px">
                    <span style="font-weight: bold">Email :</span>
                    {{ form.created_by ? form.created_by.email : "" }}
                  </p>
                </div>
                <div class="col-md-4">
                  <p style="margin-top: 10px; font-size: 21px">
                    <span style="font-weight: bold">Status :</span>
                    {{
                      form.created_by
                      ? form.created_by.active == 1
                        ? "ACTIVE"
                        : "INACTIVE"
                      : ""
                    }}
                  </p>
                </div>
              </div>
              <hr />
              <h1 style="margin-top: 10px">Customer Details</h1>
              <div class="row">
                <div class="col-md-4">
                  <p style="margin-top: 10px; font-size: 21px">
                    <span style="font-weight: bold">Customer Name :</span>
                    {{ form.customer ? form.customer.name : "" }}
                  </p>
                </div>
                <div class="col-md-4">
                  <p style="margin-top: 10px; font-size: 21px">
                    <span style="font-weight: bold">Customer Email :</span>
                    {{ form.customer ? form.customer.email : "" }}
                  </p>
                </div>
                <div class="col-md-4">
                  <p style="margin-top: 10px; font-size: 21px">
                    <span style="font-weight: bold">Customer Phone No :</span>
                    {{ form.customer ? form.customer.phone : "" }}
                  </p>
                </div>

                <div class="col-md-4">
                  <p style="font-size: 21px">
                    <span style="font-weight: bold">Customer Type :</span>
                    {{ form.customer ? form.customer.customer_type : "" }}
                  </p>
                </div>
                <div class="col-md-4">
                  <p style="font-size: 21px">
                    <span style="font-weight: bold">Customer Address :</span>
                    {{ form.customer ? form.customer.address : "" }}
                  </p>
                </div>
                <div class="col-md-4">
                  <p style="font-size: 21px">
                    <span style="font-weight: bold">Customer Status :</span>
                    {{
                      form.customer
                      ? form.customer.is_active == 1
                        ? "ACTIVE"
                        : "INACTIVE"
                      : ""
                    }}
                  </p>
                </div>
              </div>
              <hr />
              <h1 style="margin-top: 10px">Product Details</h1>
              <div class="row">
                <div class="col-md-4">
                  <p style="margin-top: 10px; font-size: 21px">
                    <span style="font-weight: bold">Product Name :</span>
                    {{ form.product ? form.product.name : "" }}
                  </p>
                </div>
                <div class="col-md-4">
                  <p style="margin-top: 10px; font-size: 21px">
                    <span style="font-weight: bold">Product Description :</span>
                    {{ form.product ? form.product.description : "" }}
                  </p>
                </div>
                <div class="col-md-4">
                  <p style="margin-top: 10px; font-size: 21px">
                    <span style="font-weight: bold">Product Status :</span>
                    {{ form.product?.is_active == 1 ? "ACTIVE" : "INACTIVE" }}
                  </p>
                </div>
              </div>
              <!-- <hr /> -->
              <!-- <h1 style="margin-top: 10px">Product Issue Details</h1>
              <div class="row">
                <div class="col-md-4">
                  <p style="margin-top: 10px; font-size: 21px">
                    <span style="font-weight: bold">Product Issue Title :</span>
                    {{ form.product_issue_list.title }}
                  </p>
                </div>
                <div class="col-md-4">
                  <p style="margin-top: 10px; font-size: 21px">
                    <span style="font-weight: bold"
                      >Product Issue Description :</span
                    >
                    {{ form.product_issue_list.description }}
                  </p>
                </div>
                <div class="col-md-4">
                  <p style="margin-top: 10px; font-size: 21px">
                    <span style="font-weight: bold"
                      >Product Issue Status :</span
                    >
                    {{
                      form.product_issue_list.is_active == 1
                        ? "ACTIVE"
                        : "INACTIVE"
                    }}
                  </p>
                </div>
              </div> -->
              <hr />
              <h1 style="margin-top: 10px">Assign To</h1>
              <div class="row">
                <div class="col-md-4">
                  <p style="margin-top: 10px; font-size: 21px">
                    <span style="font-weight: bold">Assign To Name :</span>
                    {{ form.assigned_to ? form.assigned_to.name : "" }}
                  </p>
                </div>
                <div class="col-md-4">
                  <p style="margin-top: 10px; font-size: 21px">
                    <span style="font-weight: bold">Assign To Phone No :</span>
                    {{ form.assigned_to ? form.assigned_to.phone : "" }}
                  </p>
                </div>
                <div class="col-md-4">
                  <p style="margin-top: 10px; font-size: 21px">
                    <span style="font-weight: bold">Assign To Status :</span>
                    {{ form.assigned_to?.active == 1 ? "ACTIVE" : "INACTIVE" }}
                  </p>
                </div>
              </div>
              <hr />
              <h1 style="margin-top: 10px">Before Service</h1>
              <div class="row">
                <div class="col-md-4">
                  <p style="margin-top: 10px; font-size: 21px">
                    <span style="font-weight: bold">Before Service Description :</span>
                    {{ form.before_service_description }}
                  </p>
                </div>
                <!-- <div class="col-md-4">
                  <p style="margin-top: 10px; font-size: 21px">
                    <span style="font-weight: bold"
                      >Before Service Issue :</span
                    >
                    {{ form.before_service_issue.description }}
                  </p>
                </div> -->
                <div class="col-md-4 text-center">
                  <p style="margin-top: 10px; font-size: 21px">
                    <img v-if="form.image_path_1 != null" :src="path + '/' + form.image_path_1" alt="Image 8"
                      style="height: 50%; width: 50% !important" /><br />
                    <span style="font-weight: bold">Image 1 :</span><br>
                    <a v-if="form.image_path_1 != null" target="_blank" :href="`${mediaUrl}/${form.image_path_1}`">View
                      Image</a>
                    <span v-else> No Image</span>
                  </p>
                </div>
                <div class="col-md-4 text-center">
                  <p style="font-size: 21px">
                    <img v-if="form.image_path_2 != null" :src="path + '/' + form.image_path_2" alt="Image 8"
                      style="height: 50%; width: 50% !important" /><br />
                    <span style="font-weight: bold">Image 2 :</span><br>
                    <a v-if="form.image_path_2 != null" target="_blank" :href="`${mediaUrl}/${form.image_path_2}`">View
                      Image</a>
                    <span v-else> No Image</span>
                  </p>
                </div>
                <div class="col-md-4 text-center">
                  <p style="font-size: 21px">

                    <img v-if="form.image_path_3 != null" :src="path + '/' + form.image_path_3" alt="Image 8"
                      style="height: 50%; width: 50% !important" /><br />
                    <span style="font-weight: bold">Image 3 :</span><br>
                    <a v-if="form.image_path_3 != null" target="_blank" :href="`${mediaUrl}/${form.image_path_3}`">View
                      Image</a>
                    <span v-else> No Image</span>
                  </p>
                </div>
                <div class="col-md-4 text-center">
                  <p style="font-size: 21px">
                    <img v-if="form.image_path_4 != null" :src="path + '/' + form.image_path_4" alt="Image 8"
                      style="height: 50%; width: 50% !important" /><br />
                    <span style="font-weight: bold">Image 4 :</span><br>
                    <a v-if="form.image_path_4" target="_blank" :href="`${mediaUrl}/${form.image_path_4}`">View Image</a>
                    <span v-else> No Image</span>
                  </p>
                </div>
              </div>
              <hr />
              <h1 style="margin-top: 10px">After Service</h1>
              <div class="row">
                <div class="col-md-4 text-center">
                  <p style="margin-top: 10px; font-size: 21px">
                    <span style="font-weight: bold">After Service Description :</span>
                    {{ form.after_service_description }}
                  </p>
                </div>
                <!-- <div class="col-md-4 text-center">
                  <p style="margin-top: 10px; font-size: 21px">
                    <span style="font-weight: bold"
                      >After Service Part Used :</span
                    >
                    {{ form.part.name }}
                  </p>
                </div> -->
                <div class="col-md-4 text-center">
                  <p style="margin-top: 10px; font-size: 21px">
                    <img v-if="form.image_path_5 != null" :src="path + '/' + form.image_path_5" alt="Image 8"
                      style="height: 50%; width: 50% !important" /><br />
                    <span style="font-weight: bold">Image 1 :</span><br>
                    <a v-if="form.image_path_5 != null" target="_blank" :href="`${mediaUrl}/${form.image_path_5}`">View
                      Image</a>
                    <span v-else> No Image</span>
                  </p>
                </div>
                <div class="col-md-4 text-center">
                  <p style="font-size: 21px">
                    <img v-if="form.image_path_6 != null" :src="path + '/' + form.image_path_6" alt="Image 8"
                      style="height: 50%; width: 50% !important" /><br />
                    <span style="font-weight: bold">Image 2 :</span><br>
                    <a v-if="form.image_path_6 != null" target="_blank" :href="`${mediaUrl}/${form.image_path_6}`">View
                      Image</a>
                    <span v-else> No Image</span>
                  </p>
                </div>
                <div class="col-md-4 text-center">
                  <p style="font-size: 21px">
                    <img v-if="form.image_path_7 != null" :src="path + '/' + form.image_path_7" alt="Image 8"
                      style="height: 50%; width: 50% !important" /><br />
                    <span style="font-weight: bold">Image 3 :</span><br>
                    <a v-if="form.image_path_7 != null" target="_blank" :href="`${mediaUrl}/${form.image_path_7}`">View
                      Image</a>
                    <span v-else> No Image</span>
                  </p>
                </div>
                <div class="col-md-4 text-center">
                  <p style="font-size: 21px">
                    <img v-if="form.image_path_8 != null" :src="path + '/' + form.image_path_8" alt="Image 8"
                      style="height: 50%; width: 50% !important" /><br />
                    <span style="font-weight: bold">Image 4 :</span><br>
                    <a v-if="form.image_path_8 != null" target="_blank" :href="`${mediaUrl}/${form.image_path_8}`">View
                      Image</a>
                    <span v-else> No Image</span>
                  </p>
                </div>
              </div>
              <hr />
              <div class="row">
                <div class="col-md-4">
                  <h1 style="margin-top: 10px">Challan</h1>
                  <p style="margin-top: 10px; font-size: 21px ; text-align: center;">
                    <img v-if="form.challan_image != null" :src="path + '/' + form.challan_image" alt="Image 8"
                      style="height: 50%; width: 50% !important" /><br />
                    <span style="font-weight: bold">Challan Image :</span><br>
                    <a v-if="form.challan_image != null" target="_blank" :href="`${mediaUrl}/${form.challan_image}`">View
                      Image</a>
                    <span v-else> No Image</span>
                  </p>
                </div>
                <div class="col-md-4">
                  <h1 style="margin-top: 10px">outlet</h1>
                  <p style="margin-top: 10px; font-size: 21px ; text-align: center;">
                    <img class="text-center" v-if="form.outlet_image_path != null"
                      :src="path + '/' + form.outlet_image_path" alt="Image 8"
                      style="height: 50%; width: 50% !important" /><br />
                    <span style="font-weight: bold ">Outlet Image :</span><br>
                    <a class=" text-center" v-if="form.outlet_image_path != null" target="_blank"
                      :href="`${mediaUrl}/${form.outlet_image_path}`">View
                      Image</a>
                    <span v-else> No Image</span>
                  </p>
                </div>
                <div class="col-sm-12 mb-6 mt-6">
                  <h1 style="margin-top: 10px">Video</h1><br>
                  <video :src="form.video_path ? `${path}/${form.video_path}` : null" controls width="600"
                    height="400"></video> <br />

                </div>
              </div>


              <h1>Map</h1>

              <div v-if="form.assigned_to && form.assigned_to.user_attendances !== null">
                <GmapMap :center="{
                  lat:
                    attendanceMarker.length > 0
                      ? parseInt(attendanceMarker[0].position.lat)
                      : 19.601194,
                  lng:
                    attendanceMarker.length > 0
                      ? parseInt(attendanceMarker[0].position.lng)
                      : 75.552979,
                }" :zoom="8" map-type-id="terrain" style="width: 100%; height: 500px">
                  <GmapMarker v-for="(m, index) in attendanceMarker" :key="`insJobMarker${index}`" :position="m.position"
                    :clickable="true" :draggable="false">
                  </GmapMarker>
                </GmapMap>
              </div>
              <div class="row mt-2">
                <h1>Time Difference</h1>
                <div class="col-md-4">
                  <p style="margin-top: 10px; font-size: 18px">
                    <span style="font-weight: bold">Time Difference :</span>
                    {{ timeDifference }}
                  </p>
                </div>


                <div class="table-responsive col-md-6"
                  v-if="form.assigned_to && form.assigned_to.user_attendances !== null">
                  <table id="dataTableExample" class="table table-hover">
                    <tr>
                      <th>Latitude</th>
                      <th>Longitude</th>
                      <th>Module</th>
                      <th>Visit Time</th>
                    </tr>
                    <tr v-for="( attendance, index ) in  form.assigned_to.user_attendances " :key="index">
                      <td>{{ attendance.latitude }}</td>
                      <td>{{ attendance.longitude }}</td>
                      <td>{{ attendance.module }}</td>
                      <td>{{ attendance.created_at }}</td>
                    </tr>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script type="text/javascript">
import axios from "axios";
import moment from "moment";

export default {
  name: "TicketId",
  data() {
    return {
      form: {
        role_id: "",
        created_by_id: "",
      },
      path: "",
      isLoading: false,
      attendanceMarker: [],
      timeDifference: "",
    };
  },
  mounted() {
    this.getData();
    this.path = this.mediaUrl;
    // this.form.created_by_id = this.user.id;
  },
  methods: {
    async getData() {
      this.isLoading = true;
      let form = await axios.get(`/tickets/${this.$route.params.ticketId}`);
      this.form = form.data.data;
      if (this.form.assigned_to && this.form.assigned_to.user_attendances !== null) {
        const createdDates = this.form.assigned_to.user_attendances.map(
          (attendance) => attendance.created_at
        );

        const moments = createdDates.map((date) => moment(date));
        const validMoments = moments.filter((m) => m.isValid());

        if (validMoments.length > 0) {
          // Calculating the time difference

          const firstDate = moment.min(validMoments);
          const lastDate = moment.max(validMoments);

          const timeDiff = moment.duration(lastDate.diff(firstDate));
          const days = Math.floor(timeDiff.asDays());
          const hours = Math.floor(timeDiff.asHours()) % 24;
          const minutes = Math.floor(timeDiff.asMinutes()) % 60;
          this.timeDifference = `${days} days ${hours} hours ${minutes} minutes`;
        }

      } else {
        this.timeDifference = "no duration";
      }
      // this.attendanceMarker;
      if (this.form.assigned_to && this.form.assigned_to.user_attendances !== null) {
        this.form.assigned_to.user_attendances.forEach((ua) => {
          if (ua.ticket_id == this.$route.params.ticketId) {
            this.attendanceMarker.push({
              position: {
                lat: parseFloat(ua.latitude),
                lng: parseFloat(ua.longitude),
              },
            });
          }
        });
      }
      console.log(this.attendanceMarker);
      // }

      this.isLoading = false;
    },
  },
};
</script>
