<template>
  <div class="page-content">
    <div class="row">
      <div class="col-lg-8 col-md-8 col-sm-6 col-xs-12">
        <h5 class="txt-dark">
          <back-button link="/users"></back-button>
          Edit User
        </h5>
      </div>
      <div class="col-lg-4 col-sm-6 col-md-4 col-xs-12">
        <ol class="breadcrumb breadcrumb-line" style="float: right">
          <li class="breadcrumb-item">
            <router-link :to="`/`">Dashboard</router-link>
          </li>
          <li class="breadcrumb-item">
            <router-link :to="`users`">Users</router-link>
          </li>
          <li class="breadcrumb-item active" aria-current="page">Add</li>
        </ol>
      </div>
    </div>
    <div class="row">
      <div class="col-md-10 grid-margin stretch-card">
        <div class="card">
          <div class="card-body">
            <div class="row">
              <div class="col-md-4">
                <label class="col-form-label">Name</label>
                <div class="col-sm-12">
                  <v-text-field v-model="form.name" prepend-inner-icon="mdi mdi-account" outlined
                    clearable></v-text-field>
                </div>
              </div>
              <div class="col-md-4">
                <label class="col-form-label">Email</label>
                <div class="col-sm-12">
                  <v-text-field v-model="form.email" prepend-inner-icon="mdi-email" outlined clearable></v-text-field>
                </div>
              </div>
              <div class="col-md-4">
                <label class="col-form-label">Phone Number</label>
                <div class="col-sm-12">
                  <v-text-field v-model="form.phone" prepend-inner-icon="mdi-phone" outlined></v-text-field>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-4">
                <label class="col-form-label">Address</label>
                <div class="col-sm-12">
                  <v-text-field v-model="form.address" prepend-inner-icon="mdi-map-marker" outlined
                    clearable></v-text-field>
                </div>
              </div>
              <div class="col-md-4">
                <label class="col-form-label">Employee Code</label>
                <div class="col-sm-12">
                  <v-text-field v-model="form.employee_code" prepend-inner-icon="mdi-account-star"
                    outlined></v-text-field>
                </div>
              </div>
              <div class="col-md-4">
                <label class="col-form-label">Date Of Joining</label>

                <v-menu v-model="doj_menu" :close-on-content-click="false" :nudge-right="40" transition="scale-transition"
                  offset-y min-width="auto">
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field v-model="form.doj" label="Date Of Joining" prepend-icon="mdi-calendar" v-bind="attrs"
                      v-on="on" clearable @click:clear="doj = null"></v-text-field>
                  </template>
                  <v-date-picker :id="`doj`" v-model="form.doj" @change="doj_menu = false"></v-date-picker>
                </v-menu>
              </div>
            </div>
            <div class="row">
              <div class="col-md-4">
                <label class="col-form-label">Date Of Birth</label>

                <v-menu v-model="dob_menu" :close-on-content-click="false" :nudge-right="40" transition="scale-transition"
                  offset-y min-width="auto">
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field v-model="form.dob" label="Date Of Birth" prepend-icon="mdi-calendar-range"
                      v-bind="attrs" v-on="on" clearable @click:clear="dob = null"></v-text-field>
                  </template>
                  <v-date-picker :id="`dob`" v-model="form.dob" @change="dob_menu = false"></v-date-picker>
                </v-menu>
              </div>
              <div class="col-md-4">
                <label class="col-form-label">Role</label>
                <v-autocomplete ref="role" v-model="form.role_id" :items="roles" placeholder="Select Role"
                  clearable></v-autocomplete>
              </div>
              <div class="col-md-4" v-if="form.role_id != '4'">
                <label class="col-form-label">Password</label>
                <div class="col-sm-12">
                  <v-text-field v-model="form.soft_password" prepend-inner-icon="mdi-account-star"
                    outlined></v-text-field>
                </div>
              </div>
              <div class="col-md-4" v-if="form.role_id == '5'">
                <label class="col-form-label">Select Product</label>
                <v-autocomplete v-model="form.mim_id" :items="products" placeholder="Select Product"
                  prepend-icon="mdi-account-multiple-plus" clearable></v-autocomplete>
              </div>
              <div class="col-md-4" v-if="form.role_id == '5'">
                <label class="col-form-label">Select Customer type</label>

                <v-autocomplete ref="customer_type" v-model="form.customer_type" :items="customer_types"
                  label="Customer Type" prepend-icon="mdi-account" :error-messages="errors.year"></v-autocomplete>
              </div>
              <div class="col-md-4" v-if="form.role_id == '4'">
                <label class="col-form-label">Imei No</label>
                <div class="col-sm-12">
                  <v-text-field v-model="form.imei_no" prepend-inner-icon="mdi-cellphone-settings" outlined clearable
                    prepend-icon="mdi-account"></v-text-field>
                </div>
              </div>
              <div class="col-md-4">
                <label class="col-form-label">Profile Image</label>
                <div class="col-sm-12">
                  <input type="file" id="file" name="file" ref="file" accept="image/*" />
                  <br />
                  <img v-if="form.image_path != null" :src="mediaUrl + '/' + form.image_path" alt="image 1"
                    style="height: 50%; width: 50% !important" /><br />
                  <v-btn v-if="form.image_path != null" x-small class="reset_image my-1" color="red" dark
                    @click="($refs.file.value = null), (form.image_path = null)">Remove</v-btn>
                </div>
              </div>
              <v-col sm="4">
                <v-switch v-model="form.active" inset :label="`Is Active: ${form.active.toString() == 'true' ? 'YES' : 'NO'
                  }`"></v-switch>
              </v-col>
            </div>
            <br />
            <div class="form-group mb-0">
              <submit-button :isLoading="isLoading" @submit="update" text="Update" style="float: right"></submit-button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script type="text/javascript">
import axios from "axios";

export default {
  name: "UserId",
  data() {
    return {
      form: {
        active: true,
      },
      isLoading: false,
      products: [],
      roles: [],

      customer_types: [
        { text: "Private", value: "Private" },
        { text: "Commercial", value: "Commercial" },
      ],
      doj_menu: false,
      dob_menu: false,
    };
  },
  mounted() {
    this.getData();
  },
  methods: {
    async getData() {
      this.isLoading = true;
      let form = await axios.get(`/users/${this.$route.params.userId}`);
      console.log(form.data);
      this.form = form.data.data;
      this.form.roles.length > 0
        ? (this.form.role_id = this.form.roles[0].id)
        : (this.form.role_id = "");
      //   console.log(this.form);
      this.getMasters();

      this.isLoading = false;
    },
    async getMasters() {
      let masters = await axios.get("users/masters");
      masters = masters.data;
      //roles
      masters.roles.forEach((role) => {
        this.roles.push({
          id: role.id,
          text: role.name,
          value: role.id,
        });
      });
      masters.products.forEach((product) => {
        this.products.push({
          id: product.id,
          text: product.name,
          value: product.id,
        });
      });
      // console.log(masters);
      //user
      masters.users.forEach((user) => {
        console.log(user);
        const roleWithId3 = user.roles.find((role) => role.id === 3);
        if (roleWithId3) {
          this.mims.push({
            id: user.id,
            text: user.name,
            value: user.id,
          });
        }
      });
    },

    async update() {
      try {
        this.isLoading = true;

        //  let form = await axios.post(`/users`, this.form);
        //  console.log(form);
        await axios.patch(`/users/${this.$route.params.userId}`, this.form);
        // this.form = form.data.data;
        await this.handleFileUpload();

        this.isLoading = false;
        this.$router.push("/users");
      } catch (e) {
        console.log(e);
        this.isLoading = false;
      }
    },
    async handleFileUpload() {
      this.isLoading = true;

      const userid = this.form.id;
      console.log(userid);
      this.isLoading = true;

      let formData = new FormData();
      formData.append("id", userid);
      console.log(formData);
      let image_path = this.$refs.file?.files[0];
      formData.append("image_path", image_path);
      await axios
        .post("upload_user_image", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .catch(function () {
          console.log("FAILURE!!");
        });
      this.isLoading = false;
    },
  },
};
</script>
 