<template>
  <v-row>
    <v-col sm="3">
      <v-card color="#85C1E9" dark>
        <div class="d-flex flex-no-wrap justify-space-between">
          <div>
            <v-card-title
              class="text-h5"
              v-text="counts.total_ticket_count"
            ></v-card-title>

            <v-card-subtitle v-text="'TOTAL TICKET COUNT'"></v-card-subtitle>
            <v-card-actions>
              <v-btn
                class="ml-2 mt-5"
                outlined
                rounded
                small
                target="_blank"
                to="/tickets"
              >
                View List
              </v-btn>
            </v-card-actions>
          </div>
          <v-avatar class="ma-90" size="140">
            <v-icon class="imgStyle" x-large dark>mdi-ticket</v-icon>
          </v-avatar>
        </div>
      </v-card>
    </v-col>
    <v-col sm="3">
      <v-card color="#85C1E9" dark>
        <div class="d-flex flex-no-wrap justify-space-between">
          <div>
            <v-card-title
              class="text-h5"
              v-text="counts.completeCount"
            ></v-card-title>

            <v-card-subtitle v-text="'COMPLETED TICKET'"></v-card-subtitle>

            <v-card-actions>
              <v-btn
                class="ml-2 mt-5"
                outlined
                rounded
                small
                target="_blank"
                  :to="{ path: '/tickets', query: { status_type: 'Completed' } }"
              >
                View List
              </v-btn>
            </v-card-actions>
          </div>

          <v-avatar class="ma-90" size="140" tile>
            <v-icon class="imgStyle" x-large dark
              >mdi-ticket-confirmation</v-icon
            >
          </v-avatar>
        </div>
      </v-card>
    </v-col>
    <v-col sm="3">
      <v-card color="#85C1E9" dark>
        <div class="d-flex flex-no-wrap justify-space-between">
          <div>
            <v-card-title
              class="text-h5"
              v-text="counts.ongoingCount"
            ></v-card-title>

            <v-card-subtitle v-text="'ONGOING TICKET'"></v-card-subtitle>

            <v-card-actions>
              <v-btn
                class="ml-2 mt-5"
                outlined
                rounded
                small
                target="_blank"
                :to="{ path: '/tickets', query: { status_type: 'Ongoing' } }"
              >
                View List
              </v-btn>
            </v-card-actions>
          </div>

          <v-avatar class="ma-3" size="125" tile>
            <v-icon class="imgStyle" x-large dark
              >mdi-circle-edit-outline</v-icon
            >
          </v-avatar>
        </div>
      </v-card>
    </v-col>
    <v-col sm="3">
      <v-card color="#85C1E9" dark>
        <div class="d-flex flex-no-wrap justify-space-between">
          <div>
            <v-card-title
              class="text-h5"
              v-text="counts.pendingCount"
            ></v-card-title>
            <v-card-subtitle v-text="'PENDING TICKET'"></v-card-subtitle>
            <v-card-actions>
              <v-btn
                class="ml-2 mt-5"
                outlined
                rounded
                small
                target="_blank"
                 :to="{ path: '/tickets', query: { status_type: 'Pending' } }"
              >
                View List
              </v-btn>
            </v-card-actions>
          </div>

          <v-avatar class="ma-3" size="125" tile>
            <v-icon class="imgStyle" x-large dark
              >mdi-clock-alert-outline</v-icon
            >
          </v-avatar>
        </div>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import axios from "axios";

export default {
  name: "SACounts",
  data() {
    return {
      counts: {
      },
    };
  },
  mounted() {
    this.getData();
  },
  methods: {
    async getData() {
     try {
  let response = await axios.get("/admin_dashboard");
   console.log(response);
  this.counts = response.data;
  console.log(this.counts);
} catch (error) {
  console.error("Error fetching data:", error);
}
      
    },
  },
};
</script>
