<template>
  <!-- partial -->
  <div class="page-content">
    <div class="row">
      <div class="col-lg-8 col-md-8 col-sm-6 col-xs-12">
        <h5 class="txt-dark">
          Values &nbsp;
          <add-button link="/values/create"></add-button>
        </h5>
      </div>
      <!-- Breadcrumb -->
      <div class="col-lg-4 col-sm-6 col-md-4 col-xs-12">
        <ol class="breadcrumb breadcrumb-line" style="float: right">
          <li class="breadcrumb-item">
            <router-link :to="`/`">Dashboard</router-link>
          </li>
          <li class="breadcrumb-item"><a href="#">Values</a></li>
          <li class="breadcrumb-item active" aria-current="page">List</li>
        </ol>
      </div>
    </div>
    <!-- /Breadcrumb -->
    <!-- Add Button -->
    <!-- <div class="col-md-12 grid-margin stretch-card">
      <div class="card">
        <div class="card-body">
          <div class="col-lg-12 col-md-12 col-sm-6 col-xs-12">
            <router-link :to="`values/create`">
              <button class="btn btn-primary btn-xs" style="float: right">
                Add Value
              </button>
            </router-link>
          </div>
        </div>
      </div>
    </div> -->
    <!-- /Add Button -->
    <!-- Table -->
    <div class="row">
      <div class="col-md-12 grid-margin stretch-card">
        <div class="card">
          <div class="card-body">
            <div class="table-responsive">
              <table id="dataTableExample" class="table table-hover">
                <thead>
                  <tr>
                    <th>Sr No</th>
                    <th>Title</th>
                    <th>Description</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  <!-- Table Loader -->
                  <loading-table
                    :isLoading="isLoading"
                    cols="4
                        "
                  ></loading-table>
                  <!-- If No Data Received-->
                  <tr v-if="values.length == 0">
                    <td colspan="4" class="text-center" style="color: grey">
                      No Data Found
                    </td>
                  </tr>
                  <!-- If data Received -->
                  <tr v-for="(value, at) in values" :key="`value${at}`">
                    <td>{{ serialNoStarting + at + 1 }}</td>
                    <td>{{ value.title }}</td>
                    <td>{{ value.description }}</td>
                    <td>
                     
                       <v-btn
                        class="mx-2"
                        fab
                        x-small
                        color="primary"
                        :to="`/values/${value.id}`"
                      >
                        <v-icon dark> mdi-pencil </v-icon>
                      </v-btn>
                       <v-btn
                        fab
                        x-small
                        class="my-auto"
                        color="error"
                        @click="Delete_this(value.id)"
                      >
                        <v-icon dark> mdi-delete </v-icon>
                      </v-btn>
                     
                    </td>
                  </tr>
                </tbody>
              </table>
              <!-- /Table -->
            </div>
            <pagination :count="count" @submit="getData"></pagination>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- partial -->
</template>

<script type="text/javascript">
import axios from "axios";

export default {
  name: "value",
  data() {
    return {
      values: [],
      count: 0,
      serialNoStarting: 1,
      isLoading: false,
      //   isExcelloading: false,
      //   searchData: "",
      //   values_excel: [],
      //   excel_fields: {
      //     "Sr No": "sr_no",
      //     "Category": "category",
      //     Name: "name",
      //   },
      //   Exportvalues: [],
    };
  },
  mounted() {
    this.getData();
  },
  methods: {
    // async getData() {
    async getData(page = 1) {
      this.isLoading = true;
      //   let values = await axios.get(`/values`);
      let values = await axios.get(
        `/values?page=${page}&rowsPerPage=${this.rowsPerPage}`
      );
      this.values = values.data.data;
      this.count = values.data.count;
      this.serialNoStarting = (page - 1) * this.rowsPerPage;
      this.isLoading = false;
    },
    // async search() {
    //   this.isLoading = true;
    //   let values = await axios.get(`/values?search=${this.searchData}`);
    //   this.values = values.data.data;
    //   this.count = values.data.count;
    //   this.isLoading = false;
    // },
    // async exportToExcel() {
    //   this.isExcelloading = true;
    //   this.title = "value Master";
    //   this.values_excel = [];
    //   let Exportvalues = [];
    //   Exportvalues = await axios.get(`values`);
    //   this.Exportvalues = Exportvalues.data.data;
    //   let sr_no = 1;
    //   this.Exportvalues.forEach((value) => {
    //     this.values_excel.push({
    //       sr_no: sr_no,
    //       item_code: value.item_code ? value.item_code : "",
    //       description: value.description ? value.description : "",
    //       hsn_code: value.hsn_code ? value.hsn_code : "",
    //       gst: value.gst ? value.gst : "",
    //       rate: value.rate ? value.rate : "",
    //     });
    //     sr_no++;
    //   });
    //   this.isExcelloading = false;
    // },
     async Delete_this(id) {
      // console.log(id);
      console.log(this.user);
      let check = confirm("Are you sure you want to delete the data");
      if (check) {
        try {
          this.isLoading = true;
          await axios.delete(`values/${id}`);
          this.isLoading = false;
          this.getData();
        } catch (e) {
          this.isLoading = false;
        }
      }
    },
  },
};
</script>
