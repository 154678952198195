<template>
  <!-- partial -->
  <div class="page-content">
    <div class="row">
      <div class="col-lg-7 col-md-7 col-sm-6 col-xs-12">
        <h5 class="txt-dark">
          {{ roleName == "SUPER ADMIN" ? "Users" : "TECHNICIAN" }} &nbsp;
          <add-button link="/users/create"></add-button>
        </h5>
      </div>
      <!-- Breadcrumb -->
      <div class="col-lg-5 col-sm-6 col-md-5 col-xs-12">
        <ol class="breadcrumb breadcrumb-line" style="float: right">
          <li class="breadcrumb-item">
            <router-link :to="`/dashboards`">Dashboard</router-link>
          </li>
          <li class="breadcrumb-item">Users</li>
          <li class="breadcrumb-item active" aria-current="page">List</li>
        </ol>
      </div>
    </div>
    <!-- /Breadcrumb -->

    <!-- /Breadcrumb -->
    <!-- Search & Filtering  -->
    <div class="row">
      <div class="col-md-12 grid-margin stretch-card">
        <div class="card">
          <div class="card-body row">
            <div class="col-lg-8 col-md-8 col-sm-6 col-xs-12">
              <v-text-field v-model="search_keyword" label="Search ... " prepend-inner-icon="mdi-magnify"
                placeholder="Search By Name , Email , Phone........" @keydown.enter="getData" clearable></v-text-field>
            </div>
            <div class="col-md-4">
              <div style="float: right"></div>
            </div>
            <v-col sm="4">
              <v-autocomplete ref="role" v-model="form.role_id" :items="roles" label="Roles" prepend-icon="mdi-account"
                @input="getData" clearable></v-autocomplete>
            </v-col>
            <v-col sm="4">
              <v-autocomplete ref="status" v-model="form.active" :items="statuses" label="Status" @input="getData"
                prepend-icon="mdi-toggle-switch" clearable></v-autocomplete>
            </v-col>
            <v-col sm="4">
              <v-autocomplete v-model="form.technicianStatus" :items="technicianStatuses" label="Select Technician Status"
                item-text="text" item-value="value" prepend-icon="mdi-account-check" @input="getData"
                clearable></v-autocomplete>
            </v-col>
          </div>
        </div>
      </div>
    </div>
    <!-- Search & Filtering  -->
    <!-- Table -->
    <div class="row">
      <div class="col-md-12">
        <div class="card">
          <div class="card-body">
            <div>
              <div class="wrapper1" ref="wrapper1" @scroll="handleScroll1">
                <div class="div1"></div>
              </div>
              <div class="table-responsive">
                <div class="col-md-12">
                  <div style="float: right">
                    <v-card-title v-if="users.length != 0">
                      <v-spacer></v-spacer>
                      <v-btn class="primary mx-4" @click="generateExcel()">
                        <span v-if="isExcelloading"> Generating Excel... </span>
                        <span v-else>Generate Excel</span>
                      </v-btn>
                    </v-card-title>
                  </div>
                </div>
                <table id="dataTableExample" class="table table-hover">
                  <thead>
                    <tr>
                      <th>Sr No</th>
                      <th>Role</th>
                      <th>Name</th>
                      <th>Email</th>
                      <th>Phone</th>
                      <th>Profile Image</th>
                      <th>download</th>
                      <th v-if="roleName == 'SUPER ADMIN'">Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    <!-- Table Loader -->
                    <loading-table :isLoading="isLoading" cols="8
                        "></loading-table>
                    <!-- If No Data Received-->
                    <tr v-if="users.length == 0">
                      <td colspan="8" class="text-center" style="color: grey">
                        No Data Found
                      </td>
                    </tr>
                    <!-- If data Received -->
                    <tr v-for="(user, index) in users" :key="`user${index}`">
                      <template>
                        <td>{{ index + 1 }}</td>
                        <td>{{ user.roles[0] ? user.roles[0].name : "" }}</td>
                        <td>{{ user.name }}</td>
                        <td>{{ user.email }}</td>
                        <td>{{ user.phone }}</td>
                        <td>
                          <img v-if="user.image_path" :src="path + '/' + user.image_path" alt="Profile Image"
                            style="width: 100px; height: 100px" />
                          <span v-else>No Image Available</span>
                        </td>
                        <td v-if="user.roles[0].name == 'TECHNICIAN'">
                          <v-btn fab x-small class="my-auto" color="primary" @click="generateTicketExcel(user.id)">
                            <v-icon dark>mdi-download</v-icon>
                          </v-btn>
                        </td>
                        <td v-else></td>
                        <td v-if="roleName == 'SUPER ADMIN' || roleName == 'ADMIN'">
                          <v-btn class="mx-2" color="primary" fab x-small :to="`/users/${user.id}`">
                            <v-icon dark>mdi-pencil</v-icon>
                          </v-btn>
                        </td>
                      </template>
                    </tr>
                  </tbody>
                </table>
                <!-- /Table -->
                <div class="col-md-12">
                  <div style="float: right">
                    <v-pagination pagination v-model="page" :count="count" :length="paginationLength" @submit="getData"
                      prev-icon="mdi-menu-left" next-icon="mdi-menu-right"></v-pagination>
                  </div>
                </div>
              </div>
            </div>
            <!-- <pagination :count="count" @submit="getData"></pagination> -->
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- partial -->
</template>

<script type="text/javascript">
import axios from "axios";

export default {
  name: "user",

  watch: {
    page(newVal) {
      this.getData(newVal);
    },
  },
  data() {
    return {
      dialog: false,
      roleName: "",
      form: {
        role_id: "",
        active: "",
      },
      users: [],
      filteredRoles: [],
      roles: [
        {
          id: 0,
          text: "Select Role",
          value: "",
        },
      ],
      roleId: "",
      page: 1,

      count: 0,
      paginationLength: 0,
      serialNoStarting: 1,
      search_keyword: "",
      selectedTechnician: null,
      technicianStatuses: [
        { text: "Select Technician Status", value: "" },
        { text: "Engaged", value: "engaged" },
        { text: "Not Engaged", value: "not-engaged" },
      ],
      statuses: [
        {
          id: 0,
          text: "Select Active Status",
          value: "",
        },
        { id: "ACTIVE", text: "ACTIVE", value: "1" },
        { id: "INACTIVE", text: "INACTIVE", value: "0" },
      ],
      isLoading: false,
      isExcelloading: false,
    };
  },
  // created() {
  //   const status = this.$route.query.status;
  //   if (!status || (status && status !== "total")) {
  //     this.form.technicianStatus = status;
  //   } else {
  //     this.form.role_id = 4;
  //   }
  //   this.getData();
  // },

  mounted() {
    this.form.role_id = 4;
    this.getData();
    this.getMasters();
    if (this.roleName == "SUPER ADMIN") {
      this.filteredRoles = this.roles; // Show all roles
    }
    this.path = this.mediaUrl;
    this.roleName = this.user.roles[0].name;
    this.roleId = this.user.roles[0].id;
  },
  methods: {
    async getMasters() {
      let masters = await axios.get("users/masters");
      masters = masters.data;
      //roles
      masters.roles.forEach((role) => {
        if (this.roleName == "ADMIN") {
          // let roles = [];
          let filteredRoles = masters.roles.filter((role) => {
            return role.name == "TECHNICIAN" || role.name == "MIM";
          });
          this.roles = filteredRoles.map((role) => {
            return {
              id: role.id,
              text: role.name,
              value: role.id,
            };
          });
          this.form.role_id = 4;
        } else if (this.roleName == "MIM") {
          // let roles = [];
          let filteredRoles = masters.roles.filter((role) => {
            return role.name == "TECHNICIAN";
          });
          this.roles = filteredRoles.map((role) => {
            return {
              id: role.id,
              text: role.name,
              value: role.id,
            };
          });
          this.form.role_id = 4;
        } else {
          this.roles.push({
            id: role.id,
            text: role.name,
            value: role.id,
          });
        }
      });
      if (this.roleName == "SUPER ADMIN") {
        this.filteredRoles = this.roles; // Show all roles
      } else {
        this.filteredRoles = this.roles.filter(
          (role) => role.value == "technician"
        ); // Show only 'technician'
      }
    },
    async generateExcel() {
      this.isExcelloading = true;
      this.search_keyword = this.search_keyword ? this.search_keyword : "";
      this.form.role_id = this.form.role_id ? this.form.role_id : "";
      this.form.active = this.form.active ? this.form.active : "";
      this.form.technicianStatus = this.form.technicianStatus
        ? this.form.technicianStatus
        : "";
      let query =
        "&search_keyword=" +
        this.search_keyword +
        "&roleId=" +
        this.form.role_id +
        "&active=" +
        this.form.active +
        "&technicianStatus=" +
        this.form.technicianStatus;
      try {
        let response = await axios.get(`usersExports?${query}`);
        if (response && response.data && response.data.data) {
          window.open(`${response.data.data}`, "_blank");
        } else {
          console.error("Invalid response data:", response.data);
        }
      } catch (error) {
        console.error("Error fetching Excel data:", error);
      }
      this.isExcelloading = false;
    },
    async generateTicketExcel(id) {
      // this.search_keyword = this.search_keyword ? this.search_keyword : "";

      // this.form.role_id = this.form.role_id ? this.form.role_id : "";
      // let UserId = this.form.id ? this.form.id : "";
      // this.form.technicianStatus = this.form.technicianStatus
      //   ? this.form.technicianStatus
      //   : "";
      console.log(id);
      let query = `assigned_to_id=${id}`;
      try {
        let response = await axios.get(`ticket_exports?${query}`);
        if (response && response.data && response.data.data) {
          window.open(`${response.data.data}`, "_blank");
        } else {
          console.error("Invalid response data:", response.data);
        }
      } catch (error) {
        console.error("Error fetching Excel data:", error);
      }
      this.isExcelloading = false;
    },

    async getData() {
      this.isLoading = true;
      this.search_keyword = this.search_keyword ? this.search_keyword : "";

      this.form.role_id = this.form.role_id ? this.form.role_id : "";
      this.form.active = this.form.active ? this.form.active : "";
      this.form.technicianStatus = this.form.technicianStatus
        ? this.form.technicianStatus
        : "";

      let query =
        "page=" +
        this.page +
        "&rowsPerPage=" +
        this.rowsPerPage +
        "&search_keyword=" +
        this.search_keyword +
        "&roleId=" +
        this.form.role_id +
        "&active=" +
        this.form.active +
        "&technicianStatus=" +
        this.form.technicianStatus;
      let users = await axios.get(`/users?${query}`);
      this.users = users.data.data;
      this.count = users.data.count;

      // this.serialNoStarting = (this.page - 1) * this.rowsPerPage;
      this.serialNoStarting = (this.page - 1) * this.rowsPerPage;
      this.paginationLength = Math.ceil(this.count / this.rowsPerPage);
      this.isLoading = false;
    },
    async Delete_this(id) {
      let check = confirm("Are you sure you want to delete the data");
      if (check) {
        try {
          this.isLoading = true;
          await axios.delete(`users/${id}`);
          this.isLoading = false;
          this.getData();
        } catch (e) {
          this.isLoading = false;
        }
      }
    },
  },
};
</script>
