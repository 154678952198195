<template>
  <!-- partial -->
  <div class="page-content">
    <div class="row">
      <div class="col-lg-8 col-md-8 col-sm-6 col-xs-12">
        <h5 class="txt-dark">
          <back-button link="/products"></back-button>
          Edit Product
        </h5>
      </div>
    </div>
    <div class="row">
      <div class="col-md-10 grid-margin stretch-card">
        <div class="card">
          <div class="card-body">
            <div class="row mb-3">
              <label class="col-sm-2 col-form-label">Name</label>
              <div class="col-sm-10">
                <v-text-field v-model="form.name" prepend-inner-icon="mdi-more" outlined clearable></v-text-field>
              </div>
            </div>
            <div class="row mb-3">
              <label class="col-sm-2 col-form-label">Description</label>
              <div class="col-sm-10">
                <v-textarea placeholder="Enter your description" prepend-inner-icon="mdi-chemical-weapon"
                  v-model="form.description" outlined></v-textarea>
              </div>
            </div>
            <div class="row mb-3">
              <label class="col-sm-2 col-form-label">Product Company</label>
              <div class="col-sm-10">
                <v-text-field placeholder="Enter your description" prepend-inner-icon="mdi-chemical-weapon"
                  v-model="form.product_company" outlined></v-text-field>
              </div>
            </div>
            <div class="row mb-3">
              <label class="col-sm-2 col-form-label">Serial no</label>
              <div class="col-sm-10">
                <v-text-field placeholder="Enter your description" prepend-inner-icon="mdi-chemical-weapon"
                  v-model="form.serial_no" outlined></v-text-field>
              </div>
            </div>

            <div class="row mb-3">
              <label class="col-sm-2 col-form-label">Product Image 1</label>
              <div class="col-sm-4">
                <input type="file" id="file_1" name="file_1" ref="file_1" @change="onImage1Change" accept="image/*" />

                <div id="preview" v-if="image1_url || form.imagepath_1">

                  <img v-if="form.imagepath_1 != null" :src="mediaUrl + '/' + form.imagepath_1" alt="image 1"
                    style="height: 50%; width: 50% !important" /><br />
                  <v-btn x-small v-if="form.imagepath_1 != null" class="reset_image my-1" color="red" dark @click="
                    ($refs.file_1.value = null), (form.imagepath_1 = null)
                    ">Remove</v-btn><br />
                  <a v-if="form.imagepath_1" :href="`${mediaUrl}/${form.imagepath_1}`" target="_blank">Open Product
                    Image</a>
                </div>
              </div>
            </div>
            <div class="row mb-3">
              <label class="col-sm-2 col-form-label">Product Image 2</label>
              <div class="col-sm-4">
                <input type="file" id="file_2" name="file_2" ref="file_2" @change="onImage2Change" accept="image/*" />
                <div id="preview" v-if="image2_url || form.imagepath_2">
                  <img v-if="form.imagepath_2 != null" :src="mediaUrl + '/' + form.imagepath_2" alt="image 1"
                    style="height: 50%; width: 50% !important" /><br />
                  <a v-if="form.imagepath_2" :href="`${mediaUrl}/${form.imagepath_2}`" target="_blank">Open Product
                    Image</a>
                </div>
                <v-btn x-small v-if="form.imagepath_2 != null" class="reset_image my-1" color="red" dark @click="
                  ($refs.file_2.value = null), (form.imagepath_2 = null)
                  ">Remove</v-btn><br />
              </div>

              <div class="form-group mb-0">
                <v-card-text>
                  <v-divider class="mt-5 mb-5"></v-divider>
                  <div class="d-flex justify-end">
                    <v-progress-circular v-if="isLoading" indeterminate color="primary"></v-progress-circular>
                    <v-btn size="large" v-else color="primary" x-large dark @click="update">Update Product</v-btn>
                  </div>
                </v-card-text>

              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- partial -->
</template>

<script type="text/javascript">
import axios from "axios";

export default {
  name: "UserId",
  data() {
    return {
      form: {},
      isLoading: false,
      image1_url: "",
      image2_url: "",
    };
  },
  mounted() {
    this.getData();
  },
  methods: {
    onImage1Change(e) {
      const file = e.target.files[0];

      this.image1_url = URL.createObjectURL(file);
    },

    onImage2Change(e) {
      const file = e.target.files[0];
      this.image2_url = URL.createObjectURL(file);
    },
    async getData() {
      this.isLoading = true;
      let form = await axios.get(`/products/${this.$route.params.productId}`);
      console.log(form.data);
      this.form = form.data.data;

      this.isLoading = false;
    },

    async update() {
      try {
        this.isLoading = true;

        //  let form = await axios.post(`/users`, this.form);
        //  console.log(form);
        await axios.patch(
          `/products/${this.$route.params.productId}`,
          this.form
        );
        // this.form = form.data.data;
        await this.handleFileUpload();

        this.isLoading = false;
        this.$router.push("/products");
      } catch (e) {
        console.log(e);
        this.isLoading = false;
      }
    },
    async handleFileUpload() {
      this.isLoading = true;
      const productid = this.form.id;
      // console.log(this.product.id)
      let formData = new FormData();

      formData.append("id", productid);
      let imagepath_1 = this.$refs.file_1?.files[0];
      formData.append("imagepath_1", imagepath_1);
      let imagepath_2 = this.$refs.file_2?.files[0];
      formData.append("imagepath_2", imagepath_2);

      await axios
        .post("upload_product_image", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .catch(function () {
          console.log("FAILURE!!");
        });
      this.isLoading = false;
    },
  },
};
</script>


