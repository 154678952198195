<template>
  <!-- partial -->
  <div class="page-content">
    <div class="row">
      <div class="col-lg-7 col-md-7 col-sm-6 col-xs-12">
        <h5 class="txt-dark">Generate Product QR Codes &nbsp;</h5>
      </div>
      <!-- Breadcrumb -->
      <div class="col-lg-5 col-sm-6 col-md-5 col-xs-12">
        <ol class="breadcrumb breadcrumb-line" style="float: right">
          <li class="breadcrumb-item">
            <router-link :to="`/`">Dashboard</router-link>
          </li>
          <li class="breadcrumb-item">Generate Product QR Codes</li>
          <li class="breadcrumb-item active" aria-current="page">List</li>
        </ol>
      </div>
    </div>
    <!-- /Breadcrumb -->

    <!-- /Breadcrumb -->
    <!-- Search & Filtering  -->
    <div class="row">
      <div class="col-md-12 grid-margin stretch-card">
        <div class="card">
          <div class="card-body row">
            <div class="col-lg-8 col-md-8 col-sm-6 col-xs-12">
              <v-text-field
                v-model="search_keyword"
                label="Number of product qr codes "
                prepend-inner-icon="mdi-qrcode-plus"
                placeholder="Example: 5 & hit enter button."
                @keydown.enter="getData"
              ></v-text-field>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Search & Filtering  -->
    <!-- Table -->
    <div class="row">
      <div class="col-md-12">
        <div class="card">
          <div class="card-body">
            <!-- End Export to Excel -->
            <div class="table-responsive">
              <table id="dataTableExample" class="table table-hover">
                <thead>
                  <tr>
                    <th>Sr No</th>
                    <th>Date</th>
                    <th>Active</th>
                    <!-- <th>Description</th>
                    <th>Product Image</th>
                    <th>Issues</th>
                    <th>Spares</th> -->
                    <th>QR Code</th>
                    <!-- <th>Action</th> -->
                  </tr>
                </thead>
                <tbody>
                  <!-- Table Loader -->
                  <loading-table
                    :isLoading="isLoading"
                    cols="4
                          "
                  ></loading-table>
                  <!-- If No Data Received-->
                  <tr v-if="products.length == 0">
                    <td colspan="4" class="text-center" style="color: grey">
                      No Data Found
                    </td>
                  </tr>
                  <!-- If data Received -->
                  <tr v-for="(product, at) in products" :key="`product${at}`">
                    <td>{{ serialNoStarting + at + 1 }}</td>
                    <td>{{ product.created_at | ddmmyyyy }}</td>

                    <td>
                      {{ product.is_active == 1 ? "Active" : "Inactive" }}
                    </td>

                    <!-- <td>{{ product.description }}</td>
                    <td>
                      <img
                        v-if="product.imagepath_1"
                        :src="path + product.imagepath_1"
                        alt="Featured Image"
                        style="width: 100px; height: 100px"
                      />
                      <span v-else>No Image Available</span>
                    </td> -->
                    <!-- <td>
                      <ul>
                        <li
                          v-for="issue in product.product_issue_lists"
                          :key="issue.id"
                        >
                          {{ issue.title }}
                        </li>
                      </ul>
                    </td> -->
                    <!-- <td>
                      <ul>
                        <li
                          v-for="spare in product.product_spare_lists"
                          :key="spare.id"
                        >
                          {{ spare.name }}
                        </li>
                      </ul>
                    </td> -->
                    <td>
                      <qr-code :text="generateQRText(product)" :size="600">
                      </qr-code>
                      <!-- :text="product.id"  -->
                    </td>
                    <!-- <td>
                      <v-btn
                        class="mx-2"
                        color="primary"
                        fab
                        x-small
                        :to="`/products/${product.id}`"
                      >
                        <v-icon dark> mdi-pencil </v-icon>
                      </v-btn>

                      <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            v-bind="attrs"
                            v-on="on"
                            class="mx-2"
                            color="sucess"
                            fab
                            x-small
                            :to="`/products/${product.id}/product-issues`"
                          >
                            <v-icon dark>mdi-plus</v-icon>
                          </v-btn>
                        </template>
                        <span>Add Product Issues</span>
                      </v-tooltip>
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            v-bind="attrs"
                            v-on="on"
                            fab
                            x-small
                            class="mx-2"
                            :to="`/products/${product.id}/product-spares`"
                          >
                            <v-icon dark>mdi-plus</v-icon>
                          </v-btn>
                        </template>
                        <span>Add Product Spares</span>
                      </v-tooltip>
                    </td> -->
                  </tr>
                </tbody>
              </table>
              <!-- /Table -->
              <div class="col-md-12">
                <div style="float: right">
                  <v-pagination
                    pagination
                    v-model="page"
                    :count="count"
                    :length="paginationLength"
                    prev-icon="mdi-menu-left"
                    next-icon="mdi-menu-right"
                  ></v-pagination>
                </div>
              </div>
            </div>

            <!-- <v-pagination v-model="page" :length="paginationLength">
            </v-pagination> -->

            <!-- <pagination :count="count" @submit="getData"></pagination> -->
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- partial -->
</template>

<script type="text/javascript">
import axios from "axios";

export default {
  name: "user",
  watch: {
    page(newVal) {
      this.getData(newVal);
    },
  },
  data() {
    return {
      form: {},

      count: 0,
      paginationLength: 0,
      serialNoStarting: 1,
      isLoading: false,
      isExcelloading: false,
      search_keyword: "",
      page: 1,
    };
  },
  mounted() {
    this.roleName = this.user.roles[0].name;
    this.getData();
    this.path = this.mediaUrl;
  },
  methods: {
    // async getData() {
    generateQRText(product) {
      // const formattedDate = this.formatDate(product.created_at);
      // let ticketId = "";
      // console.log(product);
      // product.tickets.forEach((ticket) => {
      //   ticketId += `${ticket.ticket_id}`;
      // });
      return `ProductId=${product.id}`;
      // return `ProductId=${product.id}\nProduct Name=${product.name}\nProduct Description=${product.description}\nCreated At=${formattedDate}\nTicket id=${ticketId}`;
    },
    formatDate(dateString) {
      const date = new Date(dateString);
      const day = date.getDate().toString().padStart(2, "0");
      const month = (date.getMonth() + 1).toString().padStart(2, "0"); // Note: January is 0
      const year = date.getFullYear();
      return `${day}/${month}/${year}`;
    },
    async getData() {
      this.isLoading = true;
      this.search_keyword = this.search_keyword ? this.search_keyword : "";
      let query = "page=" + this.page + "&rowsPerPage=" + this.rowsPerPage;
      let generateQrCode = await axios.get(
        `/generate_product_qr?qr_no=${this.search_keyword}`
      );

      this.generateQrCode = generateQrCode.data.data;
      this.search_keyword = "";
      let products = await axios.get(`/product_qrcode_list?${query}`);

      this.products = products.data.data;
      this.count = products.data.count;
      this.serialNoStarting = (this.page - 1) * this.rowsPerPage;
      this.paginationLength = Math.ceil(this.count / this.rowsPerPage);

      this.isLoading = false;
    },
    async generateExcel() {
      this.isExcelloading = true;
      try {
        let response = await axios.get(`productsExports?`);
        console.log(response.data.data);
        if (response && response.data && response.data.data) {
          window.open(`${response.data.data}`, "_blank");
        } else {
          console.error("Invalid response data:", response.data);
        }
      } catch (error) {
        console.error("Error fetching Excel data:", error);
      }
      this.isExcelloading = false;
    },
  },
};
</script>

<style>
.table td img {
  border-radius: 0 !important;
  height: 100px;
  width: 100px;
}
</style>
