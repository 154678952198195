<template>
  <div class="page-wrapper full-page">
    <div class="page-content d-flex align-items-center justify-content-center">
      <div class="row w-100 mx-0 auth-page">
        <div class="col-md-8 col-xl-6 mx-auto">
          <div class="card">
            <div class="row">
              <div class="col-md-6 pe-md-0">
                <div class="auth-side-wrapper"></div>
              </div>
              <div class="col-md-6 ps-md-0">
                <div class="auth-form-wrapper px-4 py-5">
                  <a href="#" class="noble-ui-logo d-block mb-2"><span>FSS</span></a>
                  <h5 class="text-muted fw-normal mb-4">
                    Welcome back! Log in to your account.
                  </h5>
                  <div class="mb-3">
                    <label for="userEmail" class="form-label">Email address</label>
                    <input type="email" class="form-control" placeholder="Email" v-model="form.email" />
                    <span style="color: red;font-size: 17px;" class="error-block">{{ errors.email }}</span>
                  </div>
                  <div class="mb-3">
                    <div class="row">
                      <div class="col-md-10">
                        <label for="userPassword" class="form-label">Password</label>
                        <input :type="`${isVisiblePassword == true ? 'text' : 'password'}`" class="form-control"
                          placeholder="Password" v-model="form.password" />
                        <span style="color: red;font-size: 17px;" class="error-block">{{ errors.password }}</span>
                      </div>
                      <div class="col-md-2 mt-10">
                        <button @click="isVisiblePasswordFunction()" v-show="isVisiblePassword == true"><i
                            class="fa fa-eye" style="font-size: 20px;"></i></button>
                        <button @click="isVisiblePasswordFunction()" v-show="isVisiblePassword == false"><i
                            class="fa fa-eye-slash" style="font-size: 20px;"></i></button>

                      </div>
                    </div>
                    <router-link to="/forgotpassword">
                      <span class="link-title">Forget password?</span>
                    </router-link>
                  </div>
                  <button class="btn btn-info btn-rounded" v-if="isLoading">
                    Loading...
                  </button>
                  <button @click="login" v-else class="btn btn-primary me-2 mb-2 mb-md-0 text-white">
                    Login
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";

export default {
  name: "Login",
  data() {
    return {
      form: {
        email: "",
        password: "",
        portal: true,
      },
      isLoading: false,
      isVisiblePassword: false,
    };
  },
  computed: {
    iconClass() {
      return this.isVisiblePassword ? 'fa fa-eye' : 'fa fa-eye-slash';
    },
  },
  created() {
    if (this.$route.query.user) {
      this.form = {
        email: this.$route.query.user,
        password: "123456",
      };
      this.login();
    }
  },


  methods: {
    ...mapActions({
      logIn: "auth/logIn",
    }),
    isVisiblePasswordFunction() {
      this.isVisiblePassword = !this.isVisiblePassword;

    },
    async login() {
      console.log(1243);
      this.isLoading = true;
      await this.logIn(this.form)
        .then(() => {
          location.reload("/pan-dashboard");
          // this.$router.push('/pan-dashboard')
          this.isLoading = false;
        })
        .catch(() => {
          this.isLoading = false;
        });
      this.$router.replace({
        name: "Home",
      });
    },
  },
};
</script>
