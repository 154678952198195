<template>
  <v-row>
    <section>
      <v-card>
        <v-card-title>Tickets</v-card-title>
        <v-simple-table fixed-header>
          <template v-slot:default>
            <thead>
              <tr>
                <th class="text-left" style="min-width: 50px">Sr No</th>
                <th class="text-center" style="min-width: 82px">
                  TECHNICIAN NAME
                </th>
                <th class="text-center" style="min-width: 105px">TITLE</th>
                <th class="text-center" style="min-width: 105px">
                  DESCRIPTION
                </th>
                <th class="text-center" style="min-width: 82px">
                  CUSTOMER NAME
                </th>
                <th class="text-center" style="min-width: 82px">PRODUCT</th>
                <th class="text-center" style="min-width: 82px">ISSUE LIST</th>
                <th class="text-center" style="min-width: 82px">Ticket Id</th>
                <th class="text-center" style="min-width: 82px">STATUS TYPE</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(ticket, s) in tickets" :key="`school${s}`">
                <td>{{ serialNoStarting + s + 1 }}</td>

                <td class="text-center">
                  {{ ticket.assigned_to ? ticket.assigned_to.name : "" }}
                </td>

                <td class="text-center">{{ ticket.title }}</td>
                <td class="text-center">{{ ticket.description }}</td>
                <td class="text-center">
                  {{ ticket.customer ? ticket.customer.name : "" }}
                </td>
                <td class="text-center">
                  {{ ticket.product ? ticket.product.name : "" }}
                </td>

                <td class="text-center">
                  {{
                    ticket.product_issue_list
                    ? ticket.product_issue_list.title
                    : ""
                  }}
                </td>
                <td class="text-center">
                  {{
                    ticket.ticket_id
                    ? ticket.ticket_id
                    : ""
                  }}
                </td>

                <td class="text-center">
                  {{ ticket.status_type }}
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-card>
      <div style="float: right">
        <v-pagination pagination v-model="page" :count="count" :length="paginationLength" prev-icon="mdi-menu-left"
          next-icon="mdi-menu-right" :total-visible="totalVisible">
          <template v-slot:prev="{ on }">
            <v-btn @click="goToPage(page - 1)" text icon v-if="page > 1" v-on="on">Previous</v-btn>
          </template>
          <template v-slot:next="{ on }">
            <v-btn @click="goToPage(page + 1)" text icon v-if="page < paginationLength" v-on="on">Next</v-btn>
          </template>
        </v-pagination>
      </div>
    </section>
  </v-row>
</template>

<script>
import axios from "axios";

export default {
  name: "SACounts",
  watch: {
    page(newVal) {
      console.log(newVal);
      this.getData(newVal);
    },
  },
  data() {
    return {
      tickets: [],
      page: 1,
      serialNoStarting: 1,
      count: 0,
      totalVisible: 5,
      paginationLength: 0,
    };
  },
  created() {
    this.getData();
  },
  methods: {
    async getData() {
      try {
        const response = await axios.get(
          `/tickets?page=${this.page}&rowsPerPage=${this.rowsPerPage}`
        );
        this.tickets = response.data.data;
        this.count = response.data.count;
        this.serialNoStarting = (this.page - 1) * this.rowsPerPage;
        this.paginationLength = Math.ceil(this.count / this.rowsPerPage);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    },
    goToPage(newPage) {
      // Handle navigation to a new page
      this.page = newPage;
      this.getData();
    },
  },
};
</script>
