<template>
  <!-- partial -->
  <div class="page-content">
    <div class="container-fluid">
      <div class="row heading-bg">
        <div class="col-lg-3 col-md-4 col-sm-4 col-xs-12">
          <h5 class="txt-dark">Ticket Report</h5>
        </div>
        <div class="col-md-12 grid-margin stretch-card">
          <SaCounts></SaCounts>
        </div>  
        <div class="col-lg-3 col-md-4 col-sm-4 col-xs-12" v-if="roleId == 2">
          <h5 class="txt-dark">Technician Count (Current day data)</h5>
        </div>   
         <div class="col-md-12 grid-margin stretch-card" v-if="roleId == 2">
          <TaCounts></TaCounts>
        </div>   
         <div class="col-lg-3 col-md-4 col-sm-4 col-xs-12">
          <h5 class="txt-dark">Tickets</h5>
        </div>
         <div class="col-md-12 grid-margin stretch-card">
          <TdCounts></TdCounts>
        </div>      
      </div>
    </div>
  </div>

  <!-- partial -->
</template>

 <script>
import SaCounts from "./ticketcount.vue";
import TaCounts from "./techniciancount.vue";
import TdCounts from "./ticketdetailed.vue";


//   import SaStatus from "./contain.vue";

export default {
  name: "SuperAdminDashboard",
  data() {
    return {
      isLoading: false,   
       roleId: "",
    };
  },
  

  components: {
    SaCounts,
    TaCounts,
    TdCounts
  },
   mounted() {
    this.roleId = this.user.roles[0].id;
   
  },

  methods: {
    async getData() {
      // let response = await axios.get(`/admin_dashboard`);
      // let data = response.data.data;
    
    },
  },
};
</script>
